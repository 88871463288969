import { AxiosInstanceCDS } from "../constant/httpsHandler";
import AppConstants from "../constant";

export async function getUserRole(userName) {
  const payload = {
    category: "ClientPortal",
  };
  return await AxiosInstanceCDS.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.USER_ROLE}?source=test&username=${userName}`,
    payload
  );
}
