import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import "../../Admin/ClientPortalList/ClientPortalList.css";
import Box from "@mui/material/Box";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Tabs, Tab, Paper } from '@mui/material';
// import { GroupBySelection, categoryOption } from "../api/AdminForm";

import DataGrid, {
    Column,
    FilterRow,
    Paging,
    // Scrolling,
    RowDragging,
    Pager,
    ColumnChooser,
    Sorting,
    Lookup,
    Item,
    Toolbar,
    Export,
    ColumnFixing,
    Editing,
    Scrolling,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";

function HtmlControlMappingDataGrid({ drawerOpener }) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [groupByDropdown, setGroupByDropdown] = useState([]);
    const [categoryOptionData, setCategoryOptionData] = useState([]);

    const allowedPageSizes = [50, 100, 250];

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
       
    };

    const drawerWidth = 600;


    const options = [
        'Apple',
        'Banana',
        'Cherry',
        'Date',
        'Elderberry',
        'Fig',
        'Grape',
        'Honeydew',
        'Kiwi',
        'Lemon',
        'Mango',
        'Nectarine',
        'Orange',
        'Papaya',
        'Quince',
        'Raspberry',
        'Strawberry',
        'Tangerine',
        'Ugli fruit',
        'Watermelon',
    ];

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return <>
        <div className={drawerOpener ? "add-tabs" : "close-tabs"}>
            <Paper square>



                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered={false}
                >

                    <Tab sx={{ fontSize: "small", fontWeight: "600", marginLeft: drawerOpener ? "13%" : "3%" }} label="HTML Control Mapping" />
                </Tabs>

            </Paper>
            {selectedTab === 0 ? (


                <>

                    <div className={drawerOpener ? "add-btn" : "close-btn"}>

                        <Stack spacing={2} direction="row">
                            <Button size="large" variant="contained" onClick={toggleSidebar}>Add New</Button>
                        </Stack>

                    </div>

                    <div
                        className="main-div"
                        style={{
                            height: "90vh",
                            // width: "100%",
                            marginTop: "1%",

                            marginLeft: drawerOpener ? "255px" : "65px",
                            width: drawerOpener ? "86%" : "95%",
                        }}
                    >
                        {/* <div className="pull-right">
                        <div className="legendparent">
                            <span className="legendLable">
                                <span className="redspan1"></span>
                                Active Client
                            </span>
                        </div>
                        <div className="legendparent">
                            <span className="legendLable">
                                <span className="redspan2"></span>
                                Inactive Client
                            </span>
                        </div>
                        <div className="legendparent">
                            <span className="legendLable">
                                <span className="redspan3"></span>
                                Client Redirect Links
                            </span>
                        </div>
                    </div>
                    <hr /> */}
                        <DataGrid
                            // dataSource={sortedClientResponse}
                            keyExpr="id"
                            key="index"
                            // onSelectionChanged={onSelectionChanged}

                            // selectedRowKeys={viewArchiveList()}
                            // ref={dataGridRef}
                            height="90%"
                            width="100%"
                            // showBorders='2'
                            // defaultValue="#f05b41"
                            showRowLines={true}
                            showColumnLines={true}
                            // ref={dataGridRef}
                            hoverStateEnabled
                            showBorders={true}
                            // rowAlternationEnabled={true}
                            allowColumnResizing={true}
                            allowColumnReordering={true}
                            // loadPanel={{ enabled: true }}
                            // filterSyncEnabled={true}
                            columnMinWidth={50}
                            loadPanel={{ enabled: true }}
                        // customizeColumns={customizeColumns}
                        // columnWidth={100}
                        // columnAutoWidth={true}
                        // noDataText={tableLoaderErrInfo}
                        // onEditorPreparing={onEditorPreparing}
                        >
                            {/* <RowDragging
                            allowReordering={true}
                            onReorder={onReorder}
                        /> */}
                            {/* <Editing mode="row" allowUpdating={true} allowAdding={true}></Editing> */}
                            {/* <Selection mode="multiple" showCheckBoxesMode={"always"} /> */}
                            {/* <StateStoring
    enabled={true}
    type="custom"
    // customLoad={loadState}
    // customSave={saveState}
  /> */}
                            <Scrolling columnRenderingMode="virtual" />
                            {/* <Paging defaultPageSize={20} enabled={false} />
   */}
                            {/* <Lookup
    dataSource={sortedClientResponse}
    valueExpr="ID"
    displayExpr="Name"
  /> */}
                            <ColumnChooser enabled={true} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <ColumnFixing enabled={true} />
                            {/* <Toolbar>
    <Item location="after">
      <Button hint="Refresh" icon="refresh" onClick={refresh} />
    </Item>
  </Toolbar> */}
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={allowedPageSizes}
                                showInfo={true}
                                visible={true}
                            />
                            {/* <Scrolling useNative={true} /> */}
                            <Paging defaultPageSize={50} enabled={true} />
                            {/* <LoadPanel visible={true} /> */}
                            <FilterRow visible={true} />
                            <Column
                                allowSorting={false}
                                width="50px"
                                allowFiltering={false}
                                alignment="left"
                                caption="S.No"
                                dataField="id"
                                allowReordering={false}
                            />
                            <Column
                                // cellRender={(cellData) => (
                                //     <>
                                //         <a
                                //             title={
                                //                 cellData.data.isactive === 1
                                //                     ? "Active Client"
                                //                     : "In-Active Client"
                                //             }
                                //             style={{
                                //                 color:
                                //                     cellData.data.isactive === 1
                                //                         ? "rgb(58, 161, 240);"
                                //                         : "grey",

                                //                 // cursor: cellData.data.isactive === 1 ? "pointer" : "none",
                                //             }}
                                //             href={
                                //                 cellData.data.isactive === 1 ? cellData.data.loginurl : "#"
                                //             }
                                //             target={cellData.data.isactive === 1 ? "_blank" : ""}
                                //             rel="noopener noreferrer"
                                //         >
                                //             {/* {loaderCircular ? ( */}
                                //             <img
                                //                 src={cellData.data.portaClientImg}
                                //                 width="40px"
                                //                 height="40px"
                                //                 style={{ marginLeft: "-5px" }}
                                //                 onError={(e) => {
                                //                     e.target.setAttribute("src", noImage);
                                //                 }}
                                //             />


                                //             {cellData.data.clientName}
                                //         </a>
                                //     </>
                                // )}
                                allowReordering={true}
                                width="300px"
                                caption="Portal Name"
                                dataField="clientName"
                                editorOptions={{
                                    showClearButton: true,
                                }}
                            />
                            <Column
                                allowReordering={true}
                                width="400px"
                                caption="Portal Name"
                                dataField="accountname"
                                editorOptions={{
                                    showClearButton: true,
                                }}
                            />
                            <Column width="210px" caption="Category" dataField="category" />

                            <Column
                                allowReordering={true}
                                width="300px"
                                caption="Short Name"
                                dataField="taliogroup"
                                // cellRender={setStatus}
                                editorOptions={{
                                    showClearButton: true,
                                }}
                            />
                            {/* <Column width="250px" dataField="lll" caption="Layout List">
                            <Lookup dataSource={values1} displayExpr="kaish" valueExpr="lll" />
                        </Column> */}
                            <Column
                                allowReordering={true}
                                width="250px"
                                caption="Category ID Name"
                                dataField="lastlogin"
                                editorOptions={{
                                    showClearButton: true,
                                }}
                            // format={filterDateFormat}
                            />

                        </DataGrid>
                    </div></>



            ) : (
                ""
            )}





            {selectedTab === 1 && <div>Back Office</div>}
        </div>

        <Drawer anchor="right" open={isSidebarOpen} onClose={toggleSidebar} PaperProps={{ style: { width: drawerWidth } }}>
            <DialogTitle style={{ background: "rgb(0 140 227)", color: "white", fontSize: "20px" }} id="responsive-dialog-title">
                Add
            </DialogTitle>

            <form onSubmit={(e) => e.preventDefault()}>
                <Box sx={{ width: '95%', marginLeft: '20px', display: 'flex', paddingTop: '30px' }}>
                    <Grid container>



                        {/* <Grid item md={6} sx={{m:1}}>
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="designation"
                                                value={create_Local_Job.designation}
                                                onChange={handleInputChange}
                                                
                                            />
                                        </Grid> */}

                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', marginBottom: "25px", }}>
                            <Box className='abce' sx={{ flexDirection: 'column', width: '100%' }}>
                                <div className='fieldName'>
                                    Portal Name
                                </div>
                                <Autocomplete
                                    sx={{ margin: '5px', width: '95%' }}
                                    required
                                    id="outlined-required"
                                    label="Account Name"
                                    // value={create_Local_Job.location}
                                    // onChange={handleInputChange}

                                    name="Account Name"
                                    options={options}
                                    renderInput={(params) => <TextField {...params} label="Account Name" variant="outlined" />}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', marginBottom: "25px" }}>
                            <Box sx={{ flexDirection: 'column', width: '100%' }}>
                                <div className='fieldName'>
                                    HTML Control ID
                                </div>
                                <TextField
                                    sx={{ margin: '5px', width: '95%' }}
                                    required
                                    id="outlined-required"
                                    label="Portal Name"
                                    // value={create_Local_Job.location}
                                    // onChange={handleInputChange}
                                    name="Portal Name"
                                // onInput={(e) => {
                                //     e.target.value = e.target.value.slice(0, 200)
                                // }}
                                />
                            </Box>
                        </Grid>

                        {/* <Grid item xs={12} md={6}>
                                        <Grid item md={6} sx={{m:1}}>
                                            <FormControl className='formfieldandtextfield'>
                                                <InputLabel id="demo-simple-select-disabled-label">Experience From</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-disabled-label"
                                                    id="demo-simple-select-disabled"
                                                    label="Experience From"
                                                    value={create_Local_Job.experience_from}
                                                    onChange={handleInputChange}
                                                    name="experience_from"
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={10}>Ten</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid> */}

                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', marginBottom: "25px" }}>
                            <Box sx={{ flexDirection: 'column', width: '100%' }}>
                                <div className='fieldName'>
                                    DB Field
                                </div>
                                <Autocomplete
                                    sx={{ margin: '5px', width: '95%' }}
                                    required
                                    id="outlined-required"
                                    label="Group By"
                                    // value={create_Local_Job.location}
                                    // onChange={handleInputChange}
                                    name="Group By"
                                    options={groupByDropdown}
                                    renderInput={(params) => <TextField  {...params} label="Group By" variant="outlined" />
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', marginBottom: '25px' }}>
                            <Box sx={{ flexDirection: 'column', width: '100%' }}>
                                <div className='fieldName'>
                                    HTML Control Type
                                </div>
                                <Autocomplete
                                    sx={{ margin: '5px', width: '95%' }}
                                    required
                                    id="outlined-required"
                                    label="Category"
                                    size='large'
                                    options={categoryOptionData}
                                    // getOptionLabel={(option) => option.label} 
                                    renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
                                // renderOption={(props, option) => (
                                //     <li {...props} style={{ fontSize: '18px' }}>
                                //         {option.label}
                                //     </li>
                                // )}
                                />
                            </Box>
                        </Grid>

                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', marginBottom: "25px" }}>
                            <Box sx={{ flexDirection: 'column', width: '100%' }}>
                                <div className='fieldName'>
                                    Find Control By
                                </div>
                                <TextField
                                    sx={{ margin: '5px', width: '95%' }}
                                    required
                                    id="outlined-required"
                                    label="URL"
                                    // value={create_Local_Job.location}
                                    // onChange={handleInputChange}
                                    name="URL"
                                // onInput={(e) => {
                                //     e.target.value = e.target.value.slice(0, 200)
                                // }}
                                />
                            </Box>
                        </Grid>

                        {/* <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            sx={{ margin: '5px', width: '95%' }}
                                            required
                                            id="outlined-required"
                                            label="Client Id"
                                            value={create_Local_Job.client_id}
                                            onChange={handleInputChange}
                                            name="client_id"

                                        />
                                    </Grid> */}
                        <Grid item md={12} xs={10.2} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                            <Button variant="contained" size='large' sx={{paddingLeft: '35px', paddingRight: '35px'}}
                                //  onClick={() => saveData(false, create_Local_Job)}
                                type='submit'
                            >Add</Button>
                        </Grid>
                    </Grid>

                </Box>
            </form>
        </Drawer>
    </>
}
export default HtmlControlMappingDataGrid

