// PrivateRoute.js
// import React from "react";
// import { Route, Navigate } from "react-router-dom";

// const PrivateRoute = ({
//   isAuthenticated,
//   routeValue,
//   fallbackPath,
//   ...rest
// }) => {
//   console.log(isAuthenticated, routeValue,fallbackPath, "isAuthenticated"); // Logging for debugging
//   return isAuthenticated ? (
//     <Route path="/routeValue" element={<routeValue />}></Route>
//   ) : (
//     <Navigate to={fallbackPath} replace />
//   );
// };

// export default PrivateRoute;

import React, { useEffect, useState } from "react";
import { Navigate, useLocation, Route } from "react-router-dom";
import { useAuthContext } from "../Context/AuthProvider";

const ProtectedRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(true);
  const { userRole } = useAuthContext();
  const location = useLocation();

  useEffect(() => {
    if (userRole) {
      if (
        userRole === "Global Administrator" ||
        userRole === "ClientPortal Administrator 90"
      ) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
  }, [userRole]);

  if (!isAdmin) {
    return <Navigate to="/NoAccess" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
