import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import "./Dashboard.js";
import settingIcon from "../src/assets/settingIcon.png";
import Footer from "./Footer";
import { userDailyTime } from "./api/userDailyTime";
import { styled } from "@mui/material";
import Sidebar from "./Sidebar.js";
import { useAuthContext } from "../src/Context/AuthProvider.jsx";
import {
  SIGNIN_DEV,
  SIGNIN_DEV_RESPONSE_CODE,
  CLIENT_ID,
  REDIRECT_URI,
  APP_DOMAIN,
  CRM_REDIRECT_URL,
} from "./constant/serverConstant.js";
const headerBG = {
  background: "white",
};

function Header({
  getView,
  setDrawerOpener,
  drawerOpener,

  searchKey,
  setSearchKey,
}) {
  const [info, setInfo] = useState(false);
  const [view, setView] = useState("grid");
  const [addMenuDropDown, setAddMenuDropDown] = useState(false);
  const [profileUserDropDown, setProfileUserDropDown] = useState(false);
  const [activTimeMangement, setActivTimeMangement] = useState([]);
  const { accessToken, userInfo, userRole } = useAuthContext();

  const navigator = () => {
    setView(!view);
  };

  let userName = "";
  let userProfileName = "";

  if (userInfo !== null || undefined) {
    if (Object.keys(userInfo).length) {
      userName = userInfo.email.substring(0, userInfo.email.indexOf("@"));
      const userData = userInfo.email.substring(0, userInfo.email.indexOf("@"));
      const data = userData.split(".");

      for (let i = 0; i < data.length; i++) {
        userProfileName +=
          data[i].substring(0, 1).toUpperCase() + data[i].substring(1) + " ";
      }
    }
  }

  useEffect(() => {
    getView(view);
  }, [view]);

  function handleDrawerOpen() {
    setDrawerOpener(!drawerOpener);
  }
  function handleAddDropDown() {
    setAddMenuDropDown(!addMenuDropDown);
  }
  function handleProfileUserDropDown() {
    setProfileUserDropDown(!profileUserDropDown);
  }

  useEffect(() => {
    if (accessToken != "null" && userInfo.userName) {
      fetchData(userInfo.userName);
    }
  }, [accessToken, userInfo]);

  async function fetchData(username) {
    try {
      const userData = await userDailyTime(username);
      if (userData.data) {
        if (userData.data.status === "succeed") {
          if (userData.data != null) {
            setActivTimeMangement(userData.data.response[0].active_time);
          }
        }
      }
    } catch (error) {}
  }

  function logout() {
    removeCookie("unicode", "", APP_DOMAIN, "/");
    removeCookie("_at", "", APP_DOMAIN, "/");
    window.location.href = `${SIGNIN_DEV}/logout?response_type=${SIGNIN_DEV_RESPONSE_CODE}&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}/`;
  }
  //`${SIGNIN_DEV}/logout?response_type=${SIGNIN_DEV_RESPONSE_CODE}&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}/`

  function removeCookie(name, value, domain, path) {
    document.cookie = `${name}=${value}; domain=${domain}; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  }

  return (
    <>
      <Sidebar drawerOpener={drawerOpener} />

      <Footer />
      <header className="topbar">
        <nav className="navbar top-navbar navbar-toggleable-sm navbar-light navbar-fixed-top">
          <div className="navbar-collapse no-padding">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="p-l-0 mobile-toggler">
                  <button
                    // onClick={handleDrawerOpen}
                    type="button"
                    className="toggle-button"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <span className="pull-left company-name">HITECH</span>

                  <ul className="navbar-nav list-unstyled create-parent">
                    <li className="nav-item dropdown">
                      <a
                        // onClick={handleAddDropDown}
                        className="nav-link text-muted waves-effect waves-dark create"
                        id="create-plus"
                        title="Create"
                        href="#"
                      >
                        <i className="fa fa-plus"></i>
                        <span className="p-l-5">Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="header-right-data">
                  <div className="marquee-container">
                    <marquee
                      className="pull-left"
                      id="div_header"
                      scrollamount="3"
                    >
                      <span>
                        <b>Active Time :</b>
                        {activTimeMangement}
                      </span>
                      <span>
                        <b>Productivity-</b> <b>IC</b>-
                        <a
                          target="_blank"
                          href="/User/ProductivityReport?par=m"
                        >
                          0.00 %
                        </a>{" "}
                        <b>Team</b>-
                        <a
                          target="_blank"
                          href="/User/ProductivityReport?par=m"
                        >
                          {" "}
                          %{" "}
                        </a>
                      </span>{" "}
                      <span>
                        <b>Current Month Performance-</b> <b>IC</b>-
                        <a
                          target="_blank"
                          href="/Incentive/ViewPerformanceAnalysisSheet?par=m"
                        >
                          {" "}
                          %{" "}
                        </a>{" "}
                        <b>Team</b>-
                        <a
                          target="_blank"
                          href="/Incentive/ViewPerformanceAnalysisSheet?par=m"
                        >
                          {" "}
                          %{" "}
                        </a>
                      </span>{" "}
                      <span>
                        <b>YTD-</b> <b>IC</b>-
                        <a
                          target="_blank"
                          href="/Incentive/ViewPerformanceAnalysisSheet?par=YTD"
                        >
                          {" "}
                          %{" "}
                        </a>{" "}
                        <b>Team</b>-
                        <a
                          target="_blank"
                          href="/Incentive/ViewPerformanceAnalysisSheet?par=YTD"
                        >
                          {" "}
                          %
                        </a>
                      </span>
                    </marquee>
                  </div>

                  <ul
                    className="navbar-nav my-lg-0 list-unstyled remainging-parent pull-right"
                    style={{ marginRightight: "15px", marginTop: "0.5rem" }}
                  >
                    {/* <li className="nav-item hidden-sm-down">
                      <div className="app-search globalsearch">
                        <input
                          type="text"
                          id="txtSearchHitech"
                          className="form-control"
                          placeholder="Hitech Search "
                        // value={searchKey}
                        // onChange={(e) => setSearchKey(e.target.value)}
                        />
                      </div>
                    </li> */}
                    <li
                      onClick={handleProfileUserDropDown}
                      className="nav-item dropdown profile-dropdown"
                    >
                      <a
                        className="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                        style={{ paddingTop: "5px" }}
                        href="#"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        title={
                          userRole ===
                          ("Global Administrator" ||
                            "ClientPortal Administrator 90")
                            ? "Admin"
                            : "User"
                        }
                      >
                        <img
                          src="https://hitech.globalhuntindia.com/Content/images/user-profile-pic.png"
                          alt="user"
                          className="profile-pic"
                        />

                        {userInfo.userName ? userProfileName : "User"}

                        <i className="fa fa-angle-down"></i>
                      </a>

                      {profileUserDropDown && (
                        <div
                          className="dropdown-menu dropdown-menu-right animated flipInY useraction-dropdown"
                          style={{ display: "block" }}
                        >
                          <ul className="default-list">
                            <li>
                              <a
                                href={`${CRM_REDIRECT_URL}/User/View?userId=GHIPL`}
                                target="_blank"
                              >
                                <i className="fa fa-user"></i> View Profile
                              </a>
                            </li>

                            <li>
                              <a href={"#"} onClick={logout}>
                                <i className="fa fa-power-off"></i> Log Out
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                    </li>
                    <li className="nav-item hidden-sm-down">
                      <div className="work-flow" style={{ width: "4rem" }}>
                        <a
                          // href="/Shared/HitechFlow"
                          target="_blank"
                          // title="Work Flow"
                        >
                          <i
                            style={{ fontSize: "2rem", color: "black" }}
                            className="fa fa-cog work-icon"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </li>

                    <li className="nav-item hidden-sm-down">
                      <div className="work-flow" style={{ width: "4rem" }}>
                        <a
                          // href="/Shared/HitechFlow"
                          target="_blank"
                          // title="Work Flow"
                        >
                          <img
                            src={settingIcon}
                            style={{ fontSize: "2rem", marginTop: "0.5rem" }}
                            aria-hidden="true"
                          />
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
