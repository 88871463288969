import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import actionIcon from "../../assets/actionIcon.png";

import {
  Tabs,
  Tab,
  Paper,
  MenuItem,
  Menu,
  DialogContentText,
} from "@mui/material";

import "devextreme/dist/css/dx.light.css";
import { Modal } from "@mui/base";
import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  RecieveTootlTipData,
  create_tooltip,
  getFieldName,
  getFunctionName,
  updateToolTip,
} from "../../api/ToolTipApi/TooltipAPI";
import { useAuthContext } from "../../Context/AuthProvider";
import { trigger } from "devextreme/events";
import TooltipAuditLogs from "../../Admin/Tooltip/TooltipAudit";
import "../../Admin/Tooltip/Tooltip.css";
const menuItem = ["Edit", "Delete"];
function TooltipEdit({
  view,
  cellData,
  setStatus,
  ViewOtherDataGrid,
  refresh,
  _refresh,
  seViewOtherDataGrid,
}) {
  const [anchorEl, setAnchorEl] = useState({ element: null, index: -1 });
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [formData, setFormData] = useState({});
  const [open, setOpen] = useState(false);
  const [optionsValueField, setOptionsValueField] = useState([]);
  const [optionsValue, setOptionsValue] = useState([]);
  const prevFunctionName = useRef(formData.functionName);
  const [isEdit, setIsEdit] = useState(false);
  const drawerWidth = 600;
  const handleOpen = () => setOpen(true);

  const { accessToken, userInfo } = useAuthContext();
  const [deleteData, setDeleteData] = useState(false);
  const [isDynamicOpen, setisDynamicOpen] = useState(false);
  const [isOpenAttribute, setisOpenAttribute] = useState(false);

  const handleMenuItemClick = (option) => {
    if (option === "Edit") {
      setIsEdit(true);
      setIsSidebarOpen(true);
      setFormData({ ...formData });
    } else if (option === "Delete") {
      setDeleteData(true);
      setFormData({ ...formData });
    }

    handleCloseMenu();
  };

  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
    setFormData({
      functionName: "",
      fieldName: "",
      toolTipText: "",
    });
  }

  const handleCloseMenu = () => {
    setAnchorEl({ element: null, index: -1 });
  };

  const handleClick = (event, index, cellData) => {
    const { pageX, pageY } = event;
    const _pageY = pageY - document.documentElement.scrollTop;
    setMenuPosition({ x: pageX, y: _pageY });
    setAnchorEl({ element: event.currentTarget, index });
    setFormData({
      functionName: cellData.functionName,
      fieldName: cellData.fieldName,
      toolTipText: cellData.toolTipText,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    const minToolValue = formData.toolTipText.trim().length > 2;
    const maxToolValue = formData.toolTipText.trim().length <= 250;

    if (!minToolValue) {
      newErrors.minToolValue =
        "Minimum Tooltip value must be greater then 3 characters";
      isValid = false;
    }
    if (!maxToolValue) {
      newErrors.maxToolValue =
        "Maximum Tooltip value must not exceed 250 characters";
      isValid = false;
    }
    if (!isValid) {
      setStatus({
        display: true,
        text: newErrors.maxToolValue || newErrors.minToolValue,
        statusType: "warning",
      });
    }
    return isValid;
  };

  async function saveData() {
    let isValid = validateForm();

    try {
      if (userInfo) {
        if (isValid) {
          let apiResponse;
          if (isEdit) {
            let payload = { ...formData, id: cellData.data.toolTipId };
            apiResponse = await updateToolTip(
              userInfo.userName,
              payload,
              false,
              false
            );
          } else {
            apiResponse = await create_tooltip(userInfo.userName, formData);
          }

          if (
            apiResponse.data.data.message === "Tool Tip created Successfully!!"
          ) {
            setStatus({
              display: true,
              text: "Tool Tip created Successfully",
              statusType: "success",
            });
            setFormData({
              functionName: "",
              fieldName: "",
              toolTipText: "",
            });
          } else if (
            apiResponse.data.data.message === "Tool Tip Field Already Present!!"
          ) {
            setStatus({
              display: true,
              text: "Tool Tip Already Exists",
              statusType: "info",
            });
          } else if (
            apiResponse.data.data.message === "Tool Tip Updated Successfully!!"
          ) {
            setStatus({
              display: true,
              text: "Tool Tip updated successfully",
              statusType: "success",
            });
            setFormData({
              functionName: "",
              fieldName: "",
              toolTipText: "",
            });
          }
          setIsSidebarOpen(false);
          if (ViewOtherDataGrid.functionName !== undefined) {
            _refresh();
          } else {
            refresh();
          }
        }
      }
    } catch (error) {
      setStatus({
        display: true,
        text: `Something went wrong`,
        statusType: "warning",
      });
      setIsSidebarOpen(false);
      if (ViewOtherDataGrid.functionName !== undefined) {
        _refresh();
      } else {
        refresh();
      }
    }
  }

  async function getFunctionNameDropDown(value) {
    const res = await getFunctionName();
    setOptionsValue(res.data.data.response);
  }

  useEffect(() => {
    if (isSidebarOpen) {
      getFunctionNameDropDown();
    }
  }, [isSidebarOpen]);

  function handleInputChange(value) {
    setFormData({ ...formData, functionName: value, fieldName: "" });
  }

  async function getFiledNameDropDown(functionName) {
    const res = await getFieldName(functionName);
    setOptionsValueField(res.data.data.response);
  }

  useEffect(() => {
    if (formData.functionName) {
      if (formData.functionName !== prevFunctionName.current) {
        getFiledNameDropDown(formData.functionName);
        prevFunctionName.current = formData.functionName;
      }
    }
  }, [formData.functionName]);

  function handleClose() {
    setDeleteData(false);
  }
  async function handleDelete() {
    try {
      if (userInfo && cellData) {
        const payload = {
          id: cellData.data.toolTipId,
          deleted: 1,
          updatedBy: userInfo.userName,
        };
        const apiResponse = await updateToolTip(
          userInfo.userName,
          payload,
          deleteData,
          false
        );
        if (
          apiResponse.data.data.message === " Tool Tip Updated Successfully!!"
        ) {
        }
        setStatus({
          display: true,
          text: "Deleted successfully",
          statusType: "success",
        });
        setDeleteData(false);
        _refresh();
      }
    } catch (error) {
      setStatus({
        display: true,
        text: `Something went wrong`,
        statusType: "warning",
      });
      setDeleteData(false);
    }
  }

  function handleClose() {
    setOpen(false);
  }

  const handleOpenDynamic = () => {
    setisDynamicOpen(true);
  };
  const handleClosedDynamic = () => {
    setisDynamicOpen(false);
  };

  const handleOpenAttribute = () => {
    setisOpenAttribute(true);
  };
  const handleCloseAttribute = () => {
    setisOpenAttribute(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    saveData();
  };

  return (
    <>
      <Box>
        <>
          <TooltipAuditLogs handleClose={handleClose} open={open} />
          {view === "top" && (
            <div
              className={"close-btn"}
              style={{
                display: "flex",
                margin: "1% 2% 0 64px",
                width: "96%",
                // width: drawerOpener ? "80%" : "90%",
                justifyContent: "space-between",
              }}
            >
              <Stack spacing={2} direction="row">
                <Button
                  size="large"
                  variant="contained"
                  onClick={toggleSidebar}
                >
                  Add New
                </Button>
              </Stack>
              <Stack spacing={2} direction="row">
                <Button size="large" variant="contained" onClick={handleOpen}>
                  Audit Logs
                </Button>
              </Stack>
            </div>
          )}
          {cellData &&
            (view = "grid" && (
              <>
                <div
                  className="cellIcon"
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {cellData.data.fieldName}
                  </span>
                  <IconButton
                    title="action"
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                    onClick={(e) => {
                      handleClick(e, cellData.rowIndex, cellData.data);
                    }}
                  >
                    <img
                      alt=""
                      src={actionIcon}
                      style={{
                        cursor: "pointer",
                        height: "20px",
                        width: "21px",
                      }}
                      // onClick={(e) => {
                      //   handleClick(e, cellData.rowIndex, cellData.data);
                      // }}
                    />
                  </IconButton>
                  {
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl.element}
                      open={anchorEl.index === cellData.rowIndex}
                      onClose={handleCloseMenu}
                      PaperProps={{
                        style: {
                          width: "18ch",
                          fontSize: "10px",
                          marginLeft: "15px",
                        },
                      }}
                      sx={{
                        "& .MuiMenu-paper": {
                          left: menuPosition.x + "px!important",
                          top: menuPosition.y + "px!important",
                        },
                      }}
                    >
                      {menuItem.map((option) => (
                        <MenuItem
                          key={option}
                          selected={option === "selectedOption"}
                          onClick={() => {
                            handleMenuItemClick(option);
                          }}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                  }
                </div>
              </>
            ))}
        </>
      </Box>
      <Drawer
        anchor="right"
        open={isSidebarOpen}
        onClose={toggleSidebar}
        PaperProps={{ style: { width: drawerWidth } }}
      >
        <DialogTitle
          className="drawer-style"
          id="responsive-dialog-title"
          sx={{
            backgroundColor: "#0099f9",
            color: "#fff",
            marginBottom: "10px",
          }}
        >
          {isEdit === true ? "Edit" : "Add"}
        </DialogTitle>
        <IconButton
          size="large"
          aria-label="close"
          onClick={() => setIsSidebarOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          {" "}
          <CloseIcon fontSize="large" />
        </IconButton>
        <form onSubmit={handleSubmit}>
          <Box sx={{ width: "95%", marginLeft: "20px", display: "flex" }}>
            <Grid container>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <div className="fieldName">
                    Function Name <span className="asterisk">*</span>
                  </div>
                  <Autocomplete
                    onOpen={handleOpenDynamic}
                    onClose={handleClosedDynamic}
                    sx={{ margin: "5px", width: "95%" }}
                    required
                    id="outlined-required"
                    // label="Function Name"
                    onChange={(e, value) => {
                      handleInputChange(value);
                    }}
                    options={optionsValue}
                    value={
                      optionsValue.find(
                        (option) => option === formData.functionName
                      ) || null
                    }
                    name="Function Name"
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label={
                          isDynamicOpen || formData.functionName
                            ? ""
                            : "Select ..."
                        }
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <div className="fieldName">
                    Field Name <span className="asterisk">*</span>
                  </div>
                  <Autocomplete
                    sx={{ margin: "5px", width: "95%" }}
                    required
                    onOpen={handleOpenAttribute}
                    onClose={handleCloseAttribute}
                    id="outlined-required"
                    name="Field Name"
                    options={optionsValueField}
                    value={
                      optionsValueField.find(
                        (option) => option === formData.fieldName
                      ) || null
                    }
                    getOptionLabel={(option) =>
                      option || "No Matching Options Found"
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label={
                          isOpenAttribute || formData.fieldName
                            ? ""
                            : "Select ..."
                        }
                        variant="outlined"
                        helperText={
                          optionsValueField.length === 0
                            ? "Please select function name first"
                            : ""
                        }
                      />
                    )}
                    // value={formData}
                    onChange={(e, value) => {
                      setFormData({ ...formData, fieldName: value });
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <div className="fieldName">Tooltip</div>
                  <TextField
                    sx={{ margin: "5px", width: "95%", minHeight: "80px" }}
                    required
                    id="outlined-required"
                    // label="Tooltip"
                    multiline
                    rows={4}
                    value={formData.toolTipText}
                    onChange={(e) => {
                      setFormData({ ...formData, toolTipText: e.target.value });
                    }}
                    name="Tooltip"
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={12}
                xs={10.2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  // onClick={saveData}
                  // type="submit"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Drawer>
      <Dialog
        open={deleteData}
        onClose={() => {
          setDeleteData(false);
        }}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{
          backdropFilter: "blur(4px)",
          backgroundColor: "rgba(111, 126, 140, 0.2)",
          transition: "opacity 100ms ease 0s",
          zIndex: 1600,
        }}
      >
        <DialogTitle
          style={{
            background: "#3a86ff",
            marginBottom: "3rem",
            color: "white",
          }}
        >
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ color: "#14213d" }}
            id="alert-dialog-slide-description"
          >
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDelete();
            }}
            style={{ background: "#38b000" }}
            variant="contained"
          >
            Yes
            {/* {Yesloading && (
              <Box sx={{ display: "flex", m: 1.5, position: "relative" }}>
                <CircularProgress
                  size={24}
                  sx={{
                    color: "wheat",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              </Box>
            )} */}
          </Button>
          <Button
            style={{ background: "#c9184a" }}
            variant="contained"
            onClick={() => {
              setDeleteData(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default TooltipEdit;
