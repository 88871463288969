import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  const notFoundStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f8f8f8",
  };

  const contentStyle = {
    textAlign: "center",
  };

  const headingStyle = {
    fontSize: "6em",
    margin: 0,
    color: "#e74c3c",
  };

  const paragraphStyle = {
    fontSize: "1.5em",
    color: "#555",
  };

  const linkStyle = {
    marginTop: "20px",
    display: "inline-block",
    padding: "10px 20px",
    fontSize: "1.2em",
    color: "#fff",
    backgroundColor: "#3498db",
    textDecoration: "none",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
  };

  return (
    <div style={notFoundStyle}>
      <div style={contentStyle}>
        <h1 style={headingStyle}>404</h1>
        <p style={paragraphStyle}>
          Oops! The page you're looking for could not be found.
        </p>
        <Link to="/" style={linkStyle}>
          Go to Dashboard
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
