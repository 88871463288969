import axios from "axios";

import * as serverContstant from "../constant/serverConstant";
import { AxiosInstance, AxiosInstanceCDS } from "../constant/httpsHandler";
import AppConstants from "../constant";

export async function GroupBySelection() {
  return AxiosInstance.get(
    `${AppConstants.serverConstants.API_ENDPOINTS.GROUP_BY}?pageNo=1&pageSize=1000&source=chs`
  );
}

export async function getPermission() {
  return AxiosInstance.get(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_PERMISSION}?pageNo=1&pageSize=1000&source=chs`
  );
}
export async function categoryOption() {
  return AxiosInstance.get(
    `${AppConstants.serverConstants.API_ENDPOINTS.CATEGORY_BY}?pageNo=1&pageSize=100&source=chs`
  );
}

export async function get_ats_autologin(take, skip, sort, tempArr) {
  const payload = {
    sort: sort,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "elementconfiguration",
    filter: tempArr,
  };
  return AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_ATS_AUTOLOGIN_TAG}?source=chssource=chs`,
    payload
  );
}
export async function accountNameStatus(filter, take, skip, sort) {
  let filterValue = [];
  if (filter) {
    if (filter.includes("and")) {
      const tempArray = [["isaccount", "=", "1"], "and"];
      filter.forEach((element) => {
        tempArray.push(element);
      });
      filterValue = tempArray;
    } else {
      filterValue = [filter, "and", ["isaccount", "=", "1"]]; 
    }
  }
  const resquestBody = {
    sort: sort,
    requireTotalCount: true,
    columnInclude: ["name,parentaccountname,accounttype,id"],
    skip: skip,
    take: take,
    TableName: "accounts",
    filter: filterValue,
  };
  return await AxiosInstanceCDS.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.ACOUNT_NAME}?source=test`,
    resquestBody
  );
}

export async function AddGroupByName(body) {
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.ADDGROUP_BY}?source=ClientPortal`,
    body
  );
}

export async function AddManifest(body) {
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.ADD_MANIFEST}?source=ClientPortal`,
    body
  );
}

export async function getManifest() {
  return await AxiosInstance.get(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_MANIFEST}?pageNo=1&pageSize=1000&source=chs`
  );
}

export async function addPermission(body) {
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.ADD_PERMISSION}?source=ClientPortal`,
    body
  );
}

export async function postClientData(accessToken, userName, formData) {
  const payload = {
    ...formData,
    createdBy: userName,
    updatedBy: userName,
    deleted: 0,
    logoUrl: `${AppConstants.serverConstants.API_ENDPOINTS.LOGO}/${formData.companyId}`,
    name: formData.clientName,
  };
  delete payload.atsName;
  delete payload.groupName;
  delete formData.clientName;
  return await axios.post(
    serverContstant.API_PATH +
      "/" +
      serverContstant.API_ENDPOINTS.ADD_CLIENT +
      "?source=ClientHub",
    payload,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export async function updateClientData(accessToken, userName, formData) {
  let isAutoProcessingEnabled = "";
  let isCvSent = "";
  if (formData.isAutoProcessingEnabled) {
    isAutoProcessingEnabled = 1;
  } else {
    isAutoProcessingEnabled = 0;
  }
  if (formData.isCvSent) {
    isCvSent = 1;
  } else {
    isCvSent = 0;
  }

  const payload = {
    ...formData,
    updatedBy: userName,
    logoUrl: `${AppConstants.serverConstants.API_ENDPOINTS.LOGO}/${formData.companyId}`,
    id: formData.clientId,
    name: formData.clientName,
    isAutoProcessingEnabled: isAutoProcessingEnabled,
    isCvSent: isCvSent,
  };
  delete payload.clientId;
  delete payload.atsName;
  delete payload.clientName;
  delete payload.accountName;

  if (payload.isScreenshotEnabled === true) {
    delete payload.isScreenshotEnabled;
  }

  return await axios.put(
    serverContstant.API_PATH +
      "/" +
      serverContstant.API_ENDPOINTS.UPDATE_CLIENT_DATA +
      "?source=ClientHub",
    payload,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export async function addCreatecategories(formData) {
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.ADD_CREATECATEGORIES}?source=ClientPortal`,
    formData
  );
}
export async function get_Post_Client_Data(filter, groupId, take, skip, sort) {
  const resquestBody = {
    sort: sort,
    groupId: parseInt(groupId),
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "clients",
    filter: filter,
  };
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_ADMIN_CLIENT_DATA}?source=chs`,
    resquestBody
  );
}

export async function deleteDataCandidate(accessToken, payload) {
  return axios.delete(
    serverContstant.API_PATH +
      "/" +
      serverContstant.API_ENDPOINTS.DELETE_DATA_CANDIDATE +
      "?source=chs",
    {
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}
export async function deleteAtsAutoLoginData(payload) {
  return await AxiosInstance.put(
    `${AppConstants.serverConstants.API_ENDPOINTS.UPDATE_ATS_TAG}?source=ClientPortal`,
    payload
  );
}

export async function postAtsLogingTag(userInfo, AtsFormData) {
  const payloadBody = {
    ...AtsFormData,
    createdBy: userInfo.userName,
    updatedBy: userInfo.userName,
  };
  if (AtsFormData.dbField == null) {
    delete payloadBody.dbField;
  } else if (AtsFormData.buttonIntent == null) {
    delete payloadBody.buttonIntent;
  }

  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.INSERT_ATS_TAGS}?source=ClientPortal`,
    payloadBody
  );
}

export async function EditAtsLogingTag(userInfo, AtsFormData, cellData) {
  const payloadBody = {
    ...AtsFormData,
    updatedBy: userInfo.userName,
    id: cellData.data.elementId,
    clientId: cellData.data.clientId,
  };
  if (AtsFormData.dbField == null) {
    delete payloadBody.dbField;
  } else if (AtsFormData.buttonIntent == null) {
    delete payloadBody.buttonIntent;
  }

  if (AtsFormData.inputElementType === "Button") {
    delete payloadBody.dbField;
  } else {
    delete payloadBody.buttonIntent;
  }
  delete payloadBody.atsName;
  return await AxiosInstance.put(
    `${AppConstants.serverConstants.API_ENDPOINTS.UPDATE_ATS_TAG}?source=ClientPortal`,
    payloadBody
  );
}
