// import * as React from "react";
// import Alert from "@mui/material/Alert";
// import Stack from "@mui/material/Stack";

// export default function Alerts({ status }) {
//   console.log(status.statusType, 'testtttt');
//   // [warning, error , info, success]
//   return (
//     <>
//       {status.statusType  && (
//         <Stack sx={{ width: "90%" }} spacing={2}>
//           <Alert severity={status.statusType}>{status.text}</Alert>
//         </Stack>
//       )}
//     </>
//   );
// }

import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Alerts({ status }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  
  React.useEffect(() => {
    if (status.display) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [status]);

  return (
    <Stack spacing={2} sx={{ width: "100%", zIndex:1231321132 }}>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert
          onClose={handleClose}
          severity={status.statusType}
          sx={{ width: "100%" }}
        >
          {status.text}
        </Alert>
      </Snackbar>
      {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
    </Stack>
  );
}
