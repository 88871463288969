import Box from "@mui/material/Box";

import DialogTitle from "@mui/material/DialogTitle";
import { Button as Button2 } from "devextreme-react/button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "devextreme/dist/css/dx.light.css";
import { useCallback, useEffect, useRef, useState } from "react";
import "../ClientPortalList/ClientPortalList.css";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";

import Dialog from "@mui/material/Dialog";
import DataGrid from "devextreme-react/data-grid";
import {
  Item,
  Toolbar,
  Column,
  ColumnChooser,
  ColumnFixing,
  Export,
  FilterRow,
  Pager,
  Paging,
  RemoteOperations,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import {
  getChsCustomization,
  postChsCustomization,
} from "../../api/Customization";
import { CustomizableFieldsAuditLogs } from "../../api/Audit Logs/AuditLogs";
import clean from "../../assets/clean.png";
import ClearFiltericon from "../../assets/ClearFiltericon.png";
import { Button } from "devextreme-react/button";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const allowedPageSizes = [50, 100, 250];
const customStore = (initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const skip = options.skip;
      const take = options.take || 50;
      const sort = options.sort || [
        {
          selector: "lastUpdatedDate",
          desc: true,
        },
      ];
      let res;
      try {
        res = await CustomizableFieldsAuditLogs(filter, take, skip, sort);
        if (res.data.data && res.data.data.response) {
          if (res.data.data.response && res.data.data.response.length) {
            const dataRes = res.data.data.response.map((item, index) => {
              const inputDateString = item.lastUpdatedDate;
              // Parse the input date string
              const inputDate = new Date(inputDateString);
              const formattedDate = `${inputDate
                .getUTCDate()
                .toString()
                .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                  month: "short",
                }).format(inputDate)}-${inputDate.getUTCFullYear()} ${inputDate
                  .getUTCHours()
                  .toString()
                  .padStart(2, "0")}:${inputDate
                    .getUTCMinutes()
                    .toString()
                    .padStart(2, "0")}:${inputDate
                      .getUTCSeconds()
                      .toString()
                      .padStart(2, "0")}`;
              const startIndex = (options.skip || 0) + 1;
              return {
                id: index + startIndex,
                changedBy: item.changedBy,
                fieldName: item.fieldName,
                lastUpdatedDate: formattedDate,
                newValue: item.newValue,
                oldValue: item.oldValue,
              };
            });

            return {
              data: dataRes,
              totalCount: res.data.data.count,
            };
          }
        }
      } catch (error) {
        // Handle other errors here
        // setStatus({
        //     display: true,
        //     text: `${error.code} , Please contact the administrator`,
        //     statusType: "warning",
        // })
      }

      // If the response is null or doesn't contain the expected properties
      return {
        data: [],
        totalCount: 0,
      };
    },
  });

function CustomizableFieldsAudit({ open, setOpen }) {
  const [AuditCusto, setAuditCusto] = useState([]);
  const dataGridRef = useRef(null);
  const [clearCustomizationPopUp, setClearCustomizationPopUp] = useState(false);
  const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";

  useEffect(() => {
    setAuditCusto(customStore());
  }, []);

  function handleCloseAudit() {
    setOpen(false);
  }

  // const saveState = useCallback(
  //     async (state) => {

  //         if (userInfo.userName) {
  //             const res = await postChsCustomization(
  //                 userInfo.userName,
  //                 "ATSAutoLoginAudit",
  //                 state
  //             );
  //         }
  //     },
  //     [userInfo]
  // );

  // const loadState = useCallback(async () => {
  //     if (userInfo.userName) {
  //         const res = await getChsCustomization(
  //             userInfo.userName,
  //             "ATSAutoLoginAudit"
  //         );
  //         return res.data.data;
  //     }
  // }, [userInfo]);

  const refresh = () => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh();
    }
  };
  const clearFilters = (e) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.clearFilter();
    }
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={handleCloseAudit}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="dialog-box"
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "80%", // Set your desired width here
            },
          }}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, background: "#3a9efe", color: "white" }}
            id="customized-dialog-title"
            className="dialog-text"
          >
            Audit Logs
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseAudit}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            className="main-div"
            style={{
              height: "85vh",
              width: "100%",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "20px",
            }}
          >
            <DataGrid
              dataSource={AuditCusto}
              keyExpr="id"
              key="index"
              height="95%"
              width="100%"
              showRowLines={true}
              showColumnLines={true}
              hoverStateEnabled
              showBorders={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              columnMinWidth={50}
              loadPanel={{ enabled: true }}
              filterSyncEnabled={true}
              remoteOperations={true}
              columnResizingMode="widget"
              ref={dataGridRef}
            >
              <Scrolling columnRenderingMode="virtual" />
              {/* <Toolbar>
                <Item>
                  <DevExtremToolbarClearfilter
                    clearFilters={clearFilters}
                    dataGridRef={dataGridRef}
                  />
                </Item>
                <Item location="after">
                  <Button2 hint="Refresh" icon="refresh" onClick={refresh} />
                </Item>
                <Item name="columnChooserButton" />
                <Item name="exportButton" />
                <Item>
                  <img
                    title="Clear Customization"
                    className="clean"
                    src={clean}
                    onClick={() => {
                      setClearCustomizationPopUp(true);
                    }}
                  ></img>
                </Item>
              </Toolbar>
              <ColumnChooser enabled={true} />
              <Export enabled={true} allowExportSelectedData={false} /> */}
              <ColumnFixing enabled={true} />
              <Pager
                showPageSizeSelector={true}
                showInfo={true}
                visible={true}
              />
              <Paging defaultPageSize={50} enabled={true} />
              <FilterRow visible={true} />
              <Column
                allowSorting={false}
                width="50px"
                allowFiltering={false}
                alignment="left"
                caption="S.No"
                dataField="id"
                allowReordering={false}
              />
              <Column
                allowReordering={true}
                width="300px"
                caption="Field Name"
                dataField="fieldName"
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column
                allowReordering={true}
                width="400px"
                caption="Old Value"
                dataField="oldValue"
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column width="210px" caption="New Value" dataField="newValue" />
              <Column
                allowReordering={true}
                width="400px"
                dataField="changedBy"
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column
                allowReordering={true}
                width="300px"
                caption="Last Updated Date"
                dataField="lastUpdatedDate"
                dataType="date"
                format={filterDateFormat}
                // cellRender={setStatus}
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Paging defaultPageSize={50} enabled={true} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
                showInfo={true}
                visible={true}
              />
              <RemoteOperations groupPaging={true} />
            </DataGrid>
          </Box>
        </BootstrapDialog>
      </div>
    </>
  );
}
export default CustomizableFieldsAudit;

function DevExtremToolbarClearfilter({ clearFilters, dataGridRef }) {
  return (
    <>
      <img
        title="Clear Filter"
        className="clean"
        src={ClearFiltericon}
        onClick={() => {
          const isFilterApplied =
            dataGridRef.current.instance.getCombinedFilter();
          if (isFilterApplied != undefined) {
            clearFilters();
          }
        }}
      ></img>
    </>
  );
}
