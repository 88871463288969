import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import "../../Admin/ClientPortalList/ClientPortalList.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Tabs, Tab, Paper } from "@mui/material";
import { Button as Button2 } from "devextreme-react/button";
import "../../Admin/Acces Control/AccessControl.css";
import { DataGrid as MUIDatagrid } from "@mui/x-data-grid";
import EditATSTags from "./EditATSTag";
import {
  getChsCustomization,
  postChsCustomization,
} from "../../api/Customization";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import ExcelJS from "exceljs";
import arowDown from "../../assets/arowDown.png";

import DataGrid, {
  Selection,
  // Box,
  Column,
  FilterRow,
  Paging,
  // Scrolling,
  RowDragging,
  Pager,
  ColumnChooser,
  Sorting,
  Lookup,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Editing,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useAuthContext } from "../../Context/AuthProvider";
import {
  GetAccessControlDetails,
  findClientsAccessData,
  getUserClientData,
  postedClientsAccess,
} from "../../api/AccessControlApi/AccessControlApi";
import { accessControlAudit } from "../../api/Audit Logs/AuditLogs";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomStore from "devextreme/data/custom_store";
import { get_ats_autologin } from "../../api/AdminForm";
import clean from "../../assets/clean.png";
import ClearFiltericon from "../../assets/ClearFiltericon.png";

import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import Alerts from "../../Alert/Alert";

function subtractHoursMins(date, hours, minutes) {
  const _date = new Date(date);
  _date.setHours(_date.getHours() - hours);
  _date.setMinutes(_date.getMinutes() - minutes);

  return _date;
}

function formatDateToCustomFormat(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

const formatDateTo24Hour = (date) => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const customStore = (setStatus, initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const take = options.take || 25;
      const skip = options.skip || null;
      const sort = options.sort || [
        {
          selector: "updatedAt",
          desc: true,
        },
      ];

      let tempArr = [];
      if (filter !== null) {
        if (filter[0] === "dbField") {
          let array = ["buttonIntent", filter[1], filter[2]];
          tempArr = [filter, "OR", array];
        } else {
          tempArr = filter;
        }
      }

      try {
        const res = await get_ats_autologin(take, skip, sort, tempArr);
        if (!res.data.data || res.data.data.response.length === 0) {
          return {
            data: [],
            totalCount: 0,
          };
        }

        let dataRes = [];
        let totalCount = 0;

        if (res.data.data) {
          const startIndex = (options.skip || 0) + 1;
          totalCount = res.data.data.response.length;
          dataRes = res.data.data.response.map((item, index) => {
            // Given date string createdAt
            const dateString = item.createdAt;
            const inputDateCreated = new Date(dateString);

            // Format the date in the desired format without any time zone adjustments
            const formattedCreated = `${inputDateCreated
              .getUTCDate()
              .toString()
              .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
              month: "short",
            }).format(
              inputDateCreated
            )}-${inputDateCreated.getUTCFullYear()} ${inputDateCreated
              .getUTCHours()
              .toString()
              .padStart(2, "0")}:${inputDateCreated
              .getUTCMinutes()
              .toString()
              .padStart(2, "0")}:${inputDateCreated
              .getUTCSeconds()
              .toString()
              .padStart(2, "0")}`;

            ////////// updatedAt

            const dateStringUpdated = item.updatedAt; // Replace this with item.updatedAt in your code

            // Parse the input date string
            const inputDate = new Date(dateStringUpdated);

            // Format the date in the desired format without any time zone adjustments
            const formattedDateUpdat = `${inputDate
              .getUTCDate()
              .toString()
              .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
              month: "short",
            }).format(inputDate)}-${inputDate.getUTCFullYear()} ${inputDate
              .getUTCHours()
              .toString()
              .padStart(2, "0")}:${inputDate
              .getUTCMinutes()
              .toString()
              .padStart(2, "0")}:${inputDate
              .getUTCSeconds()
              .toString()
              .padStart(2, "0")}`;

            return {
              loadMode: "raw",
              key: "id",
              id: startIndex + index,
              atsId: item.atsId,
              atsName: item.atsName,
              attribute: item.attribute,
              attributeValue: item.attributeValue,
              buttonIntent: item.buttonIntent,
              clientId: item.clientId,
              clientName: item.clientName,
              createdAt: formattedCreated,
              createdBy: item.createdBy,
              dbField: item.dbField ? item.dbField : item.buttonIntent,
              dbFieldActual: item.dbField,
              elementId: item.elementId,
              inputElementType: item.inputElementType,
              updatedAt: formattedDateUpdat,
              updatedBy: item.updatedBy,
            };
          });
        } else {
          setStatus({
            display: true,
            text: `No Record Found`,
            statusType: "warning",
          });
        }
        return {
          data: dataRes,
          totalCount: res.data.data.count,
        };
      } catch (error) {
        setStatus({
          display: true,
          text: `Something went wrong`,
          statusType: "warning",
        });
        return {
          data: [],
          totalCount: 0,
        };
      }
    },
  });

function AtsConfigration({ drawerOpener }) {
  const [selectedTab, setSelectedTab] = useState("1");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [ClientOrUser, setClientOrUser] = useState("");
  const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";
  const [openAudit, setOpenAudit] = useState(false);
  const [postClient, setPostClient] = useState("");
  const [ViewClientUserDetails, setViewClientUserDetails] = useState([]);
  const [cellData, setCellData] = useState({});
  const [clientListData, setClientListData] = useState([]);

  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  const [clearCustomizationPopUp, setClearCustomizationPopUp] = useState(false);

  const dataGridRef = useRef(null);

  const { accessToken, userInfo } = useAuthContext();

  useEffect(() => {
    // clientDatRef.current = customStore(selectedTab, setStatus);
    setClientListData(customStore(setStatus));
  }, []);
  const toggleSidebars = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleOpen = () => setOpenAudit(true);
  const handleCloseAudit = () => {
    setOpenAudit(false);
  };

  const rowStyles = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    // gap: "20%"
  };
  const drawerWidth = 600;

  const options = ["User Name", "Client Name"];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (accessToken != "null") {
      // getAccessControlData(accessToken);
      // getAccessData(accessToken);
      get_User_ClientData(accessToken, ClientOrUser, postClient);
    }
  }, [accessToken, ClientOrUser, postClient]);

  async function get_User_ClientData(accessToken, ClientOrUser, postClient) {
    if (postClient) {
      try {
        const userClient_Data = await getUserClientData(
          accessToken,
          ClientOrUser,
          postClient
        );
        const selectUsernameOrClient = userClient_Data.data.data.response.map(
          (info, index) => {
            let accessGrant = "";
            if (info.isAccessGranted === 1) {
              accessGrant = "Allow";
            } else if (info.isAccessGranted === 0) {
              accessGrant = "Deny";
            } else {
              accessGrant = "";
            }
            return {
              id: index + 1,
              clientId: info.clientId,
              clientName: info.clientName,
              accessGrant: accessGrant,
              username: info.username,
            };
          }
        );
        if (userClient_Data.status === 200) {
          if (userClient_Data.data != null) {
            setViewClientUserDetails(selectUsernameOrClient);
          }
        }
      } catch (error) {}
    }
  }

  const clearFilters = (e) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.clearFilter();
      dataGridRef.current.instance.clearSelection();
    }
  };

  const refresh = () => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh();
    }
  };

  const customChanges = {
    icon: "undo",
    text: "Clear",
    type: "default",
    onClick: () => {
      clearCustomizationDataGrid();
      setClearCustomizationPopUp(false);
    },
  };
  const cancelCustomchanges = {
    text: "Close",
    icon: "remove",
    type: "danger",
    onClick: () => {
      setClearCustomizationPopUp(false);
    },
  };
  function clearCustomizationDataGrid() {
    // Get the DataGrid instance
    const dataGridInstance = dataGridRef.current.instance;

    // Get the list of columns
    const columns = dataGridInstance.getVisibleColumns();

    // Reset the visibility and order of columns
    columns.forEach((column) => {
      column.visible = true;
      column.showInColumnChooser = true;
    });
    dataGridInstance.clearSelection();
    // Apply the changes
    dataGridInstance.option("columns", columns);
  }

  const saveState = useCallback(
    async (state) => {
      if (userInfo.userName) {
        const res = await postChsCustomization(
          userInfo.userName,
          "ATSAutoLogin",
          state
        );
      }
    },
    [userInfo]
  );

  const loadState = useCallback(async () => {
    if (userInfo.userName) {
      const res = await getChsCustomization(userInfo.userName, "ATSAutoLogin");
      return res.data.data;
    }
  }, [userInfo]);

  const onExporting = (e) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      customizeExportData: (columns, rows) => {
        // Return all rows directly from the rows parameter
        return {
          columns,
          rows,
        };
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "ATSAutoLogin.xlsx"
        );
      });
    });
  };

  return (
    <>
      {status.display && <Alerts status={status} sx={{ margin: "10px" }} />}
      <Popup
        visible={clearCustomizationPopUp}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        container="body"
        width={500}
        height={200}
        title="Alert"
      >
        <Position my="center" of={"main_dataGridClass"} collision="fit" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={customChanges}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelCustomchanges}
        />
        <p>Your custom changes made by you on grid will be removed..</p>
      </Popup>

      <TabContext value={selectedTab}>
        <div className={drawerOpener ? "add-tabs" : "close-tabs"}>
          <Box sx={{ width: "100%", marginTop: "50px", height: "15px" }}>
            <Paper square>
              <TabList
                onChange={handleTabChange}
                sx={{ marginLeft: drawerOpener ? "240px" : "60px" }}
              >
                <Tab
                  sx={{
                    textTransform: "none",
                  }}
                  className="tab-text-style"
                  label="ATS Auto Login Tag"
                  value="1"
                />
              </TabList>
            </Paper>
          </Box>
          <TabPanel value="1">
            <>
              <EditATSTags
                cellData={cellData}
                drawerOpener={drawerOpener}
                isSidebarOpens={isSidebarOpen}
                toggleSidebars={toggleSidebars}
                view="top"
                refresh={refresh}
              />
              <div
                className="main-div"
                style={{
                  height: "80vh",
                  marginTop: "0.75vh",
                  marginBottom: "1.75vh",
                  marginLeft: drawerOpener ? "255px" : "32px",
                  width: drawerOpener ? "86%" : "99%",
                }}
              >
                <DataGrid
                  dataSource={clientListData}
                  keyExpr="id"
                  key="id"
                  height="90%"
                  width="100%"
                  showRowLines={true}
                  showColumnLines={true}
                  hoverStateEnabled
                  showBorders={true}
                  allowColumnResizing={true}
                  allowColumnReordering={true}
                  columnMinWidth={50}
                  loadPanel={{ enabled: true }}
                  filterSyncEnabled={true}
                  remoteOperations={true}
                  columnResizingMode="widget"
                  onExporting={onExporting}
                  ref={dataGridRef}
                  noDataText={"No record found"}
                >
                  <Scrolling columnRenderingMode="virtual" />
                  <Toolbar>
                    <Item location="after">
                      <Button2
                        hint="Refresh"
                        icon="refresh"
                        onClick={refresh}
                      />
                    </Item>
                    <Item>
                      <DevExtremToolbarClearfilter
                        clearFilters={clearFilters}
                        dataGridRef={dataGridRef}
                      />
                    </Item>
                    <Item name="columnChooserButton" />
                    <Item name="exportButton" />
                    <Item>
                      <img
                        title="Clear Customization"
                        className="clean"
                        src={clean}
                        onClick={() => {
                          setClearCustomizationPopUp(true);
                        }}
                      ></img>
                    </Item>
                  </Toolbar>
                  <ColumnChooser enabled={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <ColumnFixing enabled={true} />
                  <Pager
                    showPageSizeSelector={true}
                    showInfo={true}
                    visible={true}
                  />
                  <Paging defaultPageSize={50} enabled={true} />
                  <FilterRow visible={true} />
                  <Selection
                    mode="multiple"
                    showCheckBoxesMode="always"
                    selectAllMode="page"
                    showSelectionControls={true}
                    selectByRowClick={false}
                  />
                  <StateStoring
                    enabled={true}
                    type="custom"
                    customLoad={loadState}
                    customSave={saveState}
                  />
                  <Column
                    allowSorting={false}
                    width="50px"
                    allowFiltering={false}
                    alignment="left"
                    caption="S.No"
                    dataField="id"
                    allowReordering={false}
                  />
                  <Column
                    caption="ATS Name"
                    dataField="atsName"
                    allowSorting={true}
                    width={"400px"}
                    editorOptions={{
                      showClearButton: true,
                    }}
                    cellRender={(cellData) => {
                      return (
                        <>
                          <EditATSTags
                            refresh={refresh}
                            cellData={cellData}
                            drawerOpener={drawerOpener}
                            isSidebarOpens={isSidebarOpen}
                            toggleSidebars={toggleSidebars}
                            view="grid"
                          />
                        </>
                      );
                    }}
                  />
                  {/* < Column
                    dataField="createdBy"
                    allowSorting={false}
                    width={"350px"}
                    caption="Created By"
                  /> */}
                  <Column
                    allowReordering={true}
                    width="300px"
                    caption="Input Element Type"
                    dataField="inputElementType"
                    // cellRender={setStatus}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    width="250px"
                    caption="DB Field / Button Intent"
                    dataField="dbField"
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    width="250px"
                    caption="Attribute"
                    dataField="attribute"
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    width="250px"
                    caption="Attribute Value"
                    dataField="attributeValue"
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    width="250px"
                    caption="Created At"
                    dataField="createdAt"
                    editorOptions={{
                      showClearButton: true,
                    }}
                    dataType="date"
                    format={filterDateFormat}
                  />
                  <Column
                    allowReordering={true}
                    width="250px"
                    caption="Created By"
                    dataField="createdBy"
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    width="250px"
                    caption="Updated At"
                    dataField="updatedAt"
                    dataType="date"
                    format={filterDateFormat}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    width="250px"
                    caption="Updated By"
                    dataField="updatedBy"
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                </DataGrid>
              </div>
            </>
          </TabPanel>
        </div>
      </TabContext>
    </>
  );
}
export default AtsConfigration;

function DevExtremToolbarClearfilter({ clearFilters, dataGridRef }) {
  return (
    <>
      <img
        title="Clear Filter"
        className="clean"
        src={ClearFiltericon}
        onClick={() => {
          const isFilterApplied =
            dataGridRef.current.instance.getCombinedFilter();
          if (isFilterApplied != undefined) {
          }
          clearFilters();
        }}
      ></img>
    </>
  );
}
