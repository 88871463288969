import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useCallback, useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Tabs, Tab, Paper } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DataGrid, {
  Column,
  FilterRow,
  Paging,
  // Scrolling,
  RowDragging,
  Pager,
  ColumnChooser,
  Sorting,
  Lookup,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Editing,
  Scrolling,
  RemoteOperations,
  StateStoring,
  Selection,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { Button as Button2 } from "devextreme-react/button";
import clean from "../../assets/clean.png";
import ClearFiltericon from "../../assets/ClearFiltericon.png";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { wait } from "@testing-library/user-event/dist/utils";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { useAuthContext } from "../../Context/AuthProvider";
import { ClientPortalAuditLog } from "../../api/Audit Logs/AuditLogs";
import CustomStore from "devextreme/data/custom_store";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  getChsCustomization,
  postChsCustomization,
} from "../../api/Customization";
// import Alerts from "../../Alert/Alert";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function formatDateToCustomFormat(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

const allowedPageSizes = [50, 100, 250];
const customStore = (cellData, initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const take = options.take || 50;
      const skip = options.skip;
      const sort = options.sort || [
        {
          selector: "lastUpdatedDate",
          desc: true,
        },
      ];
      let res;
      try {
        res = await ClientPortalAuditLog(filter, cellData, take, skip, sort);
        let dataRes = [];
        let totalCount = 0;
        if (res.data && res.data.data) {
          if (res.data.data.response && res.data.data.response.length) {
            totalCount = res.data.data.response.length;
            const dataRes = res.data.data.response.map((item, index) => {
              const startIndex = (options.skip || 0) + 1;
              let lastUpdatedDate = "";
              if (item.lastUpdatedDate) {
                const dateString = item.lastUpdatedDate;
                const inputDateCreated = new Date(dateString);
                // Format the date in the desired format without any time zone adjustments
                lastUpdatedDate = `${inputDateCreated
                  .getUTCDate()
                  .toString()
                  .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                  month: "short",
                }).format(
                  inputDateCreated
                )}-${inputDateCreated.getUTCFullYear()} ${inputDateCreated
                  .getUTCHours()
                  .toString()
                  .padStart(2, "0")}:${inputDateCreated
                  .getUTCMinutes()
                  .toString()
                  .padStart(2, "0")}:${inputDateCreated
                  .getUTCSeconds()
                  .toString()
                  .padStart(2, "0")}`;
              }
              return {
                id: index + startIndex,
                changedBy: item.changedBy,
                fieldName: item.fieldName,
                lastUpdatedDate: lastUpdatedDate,
                newValue: item.newValue,
                oldValue: item.oldValue,
              };
            });

            return {
              data: dataRes,
              totalCount: res.data.data.count,
            };
          }
        }
      } catch (error) {
        // Handle other errors here
        // setStatus({
        //     display: true,
        //     text: `${error.code} , Please contact the administrator`,
        //     statusType: "warning",
        // })
      }
      return {
        data: [],
        totalCount: 0,
      };
    },
  });

function ClientPortalRowAudit({ cellData, openAudit, setOpenAudit, clientId }) {
  const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";
  const { userInfo } = useAuthContext();
  const [clearCustomizationPopUp, setClearCustomizationPopUp] = useState(false);
  //   const [cellData, setCellData] = useState({});
  const [updateList, setUpdateList] = useState(true);
  const [clientListData, setClientListData] = useState([]);
  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  const dataGridRef = useRef(null);

  const handleCloseAudit = () => {
    setOpenAudit(false);
  };

  //   const toggleSidebar = () => {
  //     setIsSidebarOpen(!isSidebarOpen);
  //     console.log(!isSidebarOpen);
  //   };

  useEffect(() => {
    // clientDatRef.current = customStore(selectedTab, setStatus);

    if (cellData) {
      setClientListData(customStore(cellData));
    } else {
      setClientListData(customStore(clientId));
    }
  }, []);
  ///////
  const clearCustomizationDataGrid = () => {
    const dataGridInstance = dataGridRef.current.instance;
    const columns = dataGridInstance.getVisibleColumns();
    columns.forEach((column) => {
      column.visible = true;
      column.showInColumnChooser = true;
    });
    dataGridInstance.option("columns", columns);
    dataGridInstance.clearFilter();
    dataGridInstance.clearSelection();
    setClearCustomizationPopUp(false);
  };
  const clearFilters = (e) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.clearFilter();
    }
  };

  const refresh = () => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh();
    }
  };

  const saveState = useCallback(
    async (state) => {
      if (userInfo.userName) {
        const res = await postChsCustomization(
          userInfo.userName,
          "ClientATSAudit",
          state
        );
      }
    },
    [userInfo]
  );

  const loadState = useCallback(async () => {
    if (userInfo.userName) {
      const res = await getChsCustomization(
        userInfo.userName,
        "ClientATSAudit"
      );
      return res.data.data;
    }
  }, [userInfo]);

  return (
    <>
      {/* Side bar here */}

      <div className="audit-div">
        <BootstrapDialog
          onClose={handleCloseAudit}
          aria-labelledby="customized-dialog-title"
          open={openAudit}
          className="dialog-box"
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "80%", // Set your desired width here
            },
          }}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, background: "#008ce3", color: "white" }}
            id="customized-dialog-title"
            className="dialog-text"
          >
            Audit Logs
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseAudit}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <Box
            className="main-div"
            style={{
              height: "82vh",
              width: "100%",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "20px",
            }}
          >
            <DataGrid
              dataSource={clientListData}
              keyExpr="id"
              key="index"
              height="90%"
              width="100%"
              showRowLines={true}
              showColumnLines={true}
              hoverStateEnabled
              showBorders={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              columnMinWidth={50}
              loadPanel={{ enabled: true }}
              remoteOperations={true}
              ref={dataGridRef}
              columnResizingMode="widget"
              noDataText="No Data"
            >
              {/* <StateStoring
                enabled={true}
                type="custom"
                customLoad={loadState}
                customSave={saveState}
              /> */}
              <Scrolling mode="standard" />
              {/* <Lookup dataSource={store} valueExpr="ID" displayExpr="Name" /> */}
              {/* <LoadPanel visible={loaderCircular} /> */}
              <FilterRow visible={true} />
              {/* <FilterPanel visible={true} /> */}
              {/* <Editing
                            // mode="row"
                            useIcons={true}
                            allowUpdating={false}
                            allowDeleting={true}
                        /> */}

              <Scrolling columnRenderingMode="virtual" />

              {/* <ColumnChooser enabled={true} />
              <Export enabled={true} allowExportSelectedData={false} /> */}
              <ColumnFixing enabled={true} />

              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
                showInfo={true}
                visible={true}
              />

              <Paging defaultPageSize={50} enabled={true} />

              <FilterRow visible={true} />
              <Column
                allowSorting={false}
                width="50px"
                allowFiltering={false}
                alignment="left"
                caption="S.No"
                dataField="id"
                allowReordering={false}
              />

              <Column
                allowReordering={true}
                width="200px"
                caption="Field Name"
                dataField="fieldName"
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column
                allowReordering={true}
                width="200px"
                caption="Old Value"
                dataField="oldValue"
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column width="210px" caption="New Value" dataField="newValue" />

              <Column
                allowReordering={true}
                width="200px"
                caption="Changed By"
                dataField="changedBy"
                // cellRender={setStatus}
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column
                allowReordering={true}
                width="200px"
                caption="Last Updated Date "
                dataField="lastUpdatedDate"
                dataType="date"
                format={filterDateFormat}
                // cellRender={setStatus}
                editorOptions={{
                  showClearButton: true,
                }}
              />
            </DataGrid>
          </Box>
        </BootstrapDialog>
      </div>
    </>
  );
}
export default ClientPortalRowAudit;

// function DevExtremToolbarClearfilter({ clearFilters, dataGridRef }) {
//   const [filterAlert, setfilterAlert] = useState(false);

//   return (
//     <>
//       <img
//         title="Clear Filter"
//         className="clean"
//         src={ClearFiltericon}
//         onClick={() => {
//           const isFilterApplied =
//             dataGridRef.current.instance.getCombinedFilter();
//           if (isFilterApplied != undefined) {
//             clearFilters();
//             // setfilterAlert(true);
//             // setTimeout(() => {
//             //   setfilterAlert(false);
//             // }, 3000);
//           }
//         }}
//       ></img>

//       {/* < SnackBar filterAlert={filterAlert} /> */}
//       {/* <SnackBar /> */}
//     </>
//   );
// }
