import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { Button as Button2 } from "devextreme-react/button";
import CloseIcon from "@mui/icons-material/Close";
import DropDownBox from "devextreme-react/drop-down-box";
import "devextreme/dist/css/dx.light.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { Stack } from "@mui/material";
import "../ClientPortalList/ClientPortalList.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import actionIcon from "../../assets/actionIcon.png";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import DataGrid, {
  Column,
  FilterRow,
  Paging,
  // Scrolling,
  RowDragging,
  Pager,
  ColumnChooser,
  Sorting,
  Lookup,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Editing,
  Scrolling,
  StateStoring,
  Selection,
  RemoteOperations,
} from "devextreme-react/data-grid";
import { useAuthContext } from "../../Context/AuthProvider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DeleteAccessControlData } from "../../api/AccessControlApi/AccessControlApi";
import CustomStore from "devextreme/data/custom_store";
import {
  activeUserList,
  activeUserListdata,
  getActiveUserById,
  getClientByName,
  getClientNameDropDown,
  getClientNamedata,
  postActiveUser,
  postActiveClient
} from "../../api/clients";
import AuditLogs from "./AuditLogs";
import Alerts from "../../Alert/Alert";
const menuItemUser = ["Assign User"];
const _menuItemUser = ["Assign Client"];
const ITEM_HEIGHT = 20;

const customStore = (_setCheckData, initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const take = options.take || 250;
      const skip = options.skip || null;
      const sort = options.sort || null;
      let filterValue = filter;
      if (filter) {
        if (filter.includes("and")) {
          filter.forEach((element) => {
            if (element instanceof Array) {
              let pattern = /\b(?:y|ye|yes)\b/i;
              let patternNo = /\b(?:n|no)\b/i;
              if (pattern.test(element[2])) {
                element[2] = 1;
              } else if (patternNo.test(element[2])) {
                element[2] = 0;
              } else {
                element[2] = element[2];
              }
            }
          });
        } else {
          if (filter[0] === "isActivated") {
            let pattern = /\b(?:y|ye|yes)\b/i;
            let patternNo = /\b(?:n|no)\b/i;
            if (pattern.test(filter[2])) {
              filter[2] = 1;
            } else if (patternNo.test(filter[2])) {
              filter[2] = 0;
            } else {
              filter[2] = filter[2];
            }
          } else {
            filterValue = filter;
          }
        }
      }
      if (filterValue) {
        filterValue.forEach((element) => {
          if (element instanceof Array) {
            if (element[0] === "id" || element[0] === "i") {
              if (element[0] !== "id") {
                filterValue.push(element);
              } else {
                filterValue = [];
              }
            } else {
              filterValue = filterValue;
            }
          } else {
            if (element === "id") {
              filterValue = [];
            } else {
              filterValue = filter;
            }
          }
        });
      }
      const res = await getClientNameDropDown(filterValue, take, skip, sort);
      let dataRes = [];
      if (res.data.data) {
        _setCheckData(true);
        dataRes = res.data.data.response.map((dataItem, index) => {
          const startIndex = (options.skip || 0) + 1;
          return {
            loadMode: "raw",
            key: "id",
            id: index + startIndex,
            indexValue: index,
            clientName: dataItem.clientName,
            clientId: dataItem.clientId,
            isActivated: dataItem.isActivated ? "Yes" : "No",
          };
        });
        return {
          data: dataRes,
          totalCount: res.data.data.count,
        };
      } else {
        _setCheckData(false);
        return {
          data: [],
          totalCount: 0,
        };
      }
    },
  });

  const customStoreclient = (_setCheckData) =>
    new CustomStore({
      key: "id",
      load: async function (options) {
        const take = options.take || 500;
        const skip = options.skip || null;
        const sort = options.sort || null;
       
        const res = await getClientNamedata(take, skip, sort);
        let dataRes = [];
        if (res.data.data) {
          _setCheckData(true);
          dataRes = res.data.data.response.map((dataItem, index) => {
            const startIndex = (options.skip || 0) + 1;
            return {
              loadMode: "raw",
              key: "id",
              id: index + startIndex,
              indexValue: index,
              clientName: dataItem.clientName,
              clientId: dataItem.clientId,
              isActivated: dataItem.isActivated ? "Yes" : "No",
            };
          });
          return {
            data: dataRes,
            totalCount: res.data.data.count,
          };
        } else {
          _setCheckData(false);
          return {
            data: [],
            totalCount: 0,
          };
        }
      },
    });

const activeUserCustomStore = (setCheckData, initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const take = options.take || 500;
      const skip = options.skip || null;
      let filterValue = [];
      if (filter) {
        filter.forEach((element) => {
          if (element[0] === "id" || element[0] === "i") {
            if (element[0] !== "id") {
              filterValue.push(element);
            } else {
              filterValue = [];
            }
          } else {
            filterValue = filter;
          }
        });
      }
      const res = await activeUserList(filterValue, take, skip);
      let dataRes = [];
      if (res.data.data) {
        setCheckData(true);
        dataRes = res.data.data.response.map((dataItem, index) => {
          const startIndex = (options.skip || 0) + 1;
          return {
            key: "id",
            id: index + startIndex,
            emailid: dataItem.emailid,
            status: dataItem.status,
            username: dataItem.username,
            indexValue: index,
          };
        });

        // handleActiveUserById();
        return {
          data: dataRes,
          totalCount: res.data.data.count,
        };
      } else {
        setCheckData(false);
        return {
          data: [],
          totalCount: 0,
        };
      }
    },
  });

const activeUserCustomStorecheck = (setCheckData) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const take = options.take || 500;
      const skip = options.skip || null;

      const res = await activeUserListdata(take);
      let dataRes = [];
      if (res.data.data) {
        setCheckData(true);
        dataRes = res.data.data.response.map((dataItem, index) => {
          const startIndex = (options.skip || 0) + 1;
          return {
            key: "id",
            id: index + startIndex,
            emailid: dataItem.emailid,
            status: dataItem.status,
            username: dataItem.username,
            indexValue: index,
          };
        });

        // handleActiveUserById();
        return {
          data: dataRes,
          totalCount: res.data.data.count,
        };
      } else {
        setCheckData(false);
        return {
          data: [],
          totalCount: 0,
        };
      }
    },
  });

const allowedPageSizes = [250];
const onExporting = (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Main sheet");
  // Obtain selected rows data
  const selectedRows = e.component.getSelectedRowsData();
  // Export only selected rows
  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
    customizeExportData: (columns, rows) => {
      return {
        columns,
        rows: selectedRows,
      };
    },
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "AccessControl.xlsx"
      );
    });
  });
};

function EditAccessControl({
  cellData,
  drawerOpener,
  children,
  view,
  selectedValue,
  refreshAccessControl,
  refreshAssignedUser,
}) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [gridBoxValue, setGridBoxValue] = useState([]);
  const [_gridBoxValue, _setGridBoxValue] = useState([]);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [viewDetails, setViewDetails] = useState(false);
  const [cellDetails, setCellDetails] = useState({});
  const [_cellData, setCellData] = useState({});
  const [clientIds, setclientId] = useState("");
  const [setUsername, SetUsername] = useState("");
  const [ViewAccessRowDataDialog, setViewAccessRowDataDialog] = useState(false);
  const [portalDataGrid, setPortalDataGrid] = useState([]);
  const [setallselectduser, Setallselectduser] = useState([]);
  const [_portalDataGrid, _setPortalDataGrid] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [anchorEl, setAnchorEl] = useState({ element: null, index: -1 });
  const [_drawerOpener, setDrawerOpener] = useState(false);
  const [assignedUser, setAssignedUser] = useState(false);
  const [_assignedUser, _setAssignedUser] = useState(false);
  const [clientName, setClientName] = useState("");
  const [userValue, setUserValue] = useState("");
  const { accessToken, userInfo } = useAuthContext();
  const [activeUserSelectedRowKeys, setActiveUserSelectedRowKeys] = useState(
    []
  );
  const [_activeUserSelectedRowKeys, _setActiveUserSelectedRowKeys] = useState(
    []
  );
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
  const [_isGridBoxOpened, _setIsGridBoxOpened] = useState(false);
  const open = Boolean(anchorEl.element);
  const [selectedActiveUser, setSelectedActiveUser] = useState("");
  const [_selectedActiveUser, _setSelectedActiveUser] = useState("");
  const [stealldatas, stealldata] = useState("");
  const activeUserRef = useRef("");
  const newactiveuser = useRef("");
  const clientDatRef = useRef("");
  const ClientDataRef = useRef("");
  const clientDropListRef = useRef("");
  const activeRef = useRef("");
  const clientRef = useRef("");
  const activeUsersRef = useRef("");
  const [checkData, setCheckData] = useState(false);
  const [_checkData, _setCheckData] = useState(false);
  const [auditLogs, setAuditLogs] = useState(false);
  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  const [selectedRowData, setSelectedRowData] = useState({
    newSelectedRows: [],
    newDeselectedSelectedRows: [],
  });
  const [_selectedRowData, _setSelectedRowData] = useState({
    newSelectedRows: [],
    newDeselectedSelectedRows: [],
  });

  const handleClick = (event, index, cellData) => {
    setCellData(cellData);
    const { pageX, pageY } = event;
    const _pageY = pageY - document.documentElement.scrollTop;
    setMenuPosition({ x: pageX, y: _pageY });
    setAnchorEl({ element: event.currentTarget, index });
  };

  const handleCloseMenu = () => {
    setAnchorEl({ element: null, index: -1 });
  };

  const handleMenuItemClick = (option, data) => {
    let gridBoxValues = [{ clientId: data.clientId }];
    setGridBoxValue(gridBoxValues);
    setClientName(data.clientName ? data.clientName : "");
    setAssignedUser(true);
    setDrawerOpener(true);
    setSelectedOption(option);
    setViewDetails(!viewDetails);
    setAnchorEl({ element: null, index: -1 });
  };
  const _handleMenuItemClick = (option, data) => {
    let _gridBoxValues = [{ username: data.username }];
    _setGridBoxValue(_gridBoxValues);
    setUserValue(data.username);
    _setAssignedUser(true);
    setDrawerOpener(true);
    // setSelectedOption(option);
    // setViewDetails(!viewDetails);
    setAnchorEl({ element: null, index: -1 });
  };

  const handleClose = () => {
    setViewDetails(false);
  };
  function handleDrawerOpen() {
    setDrawerOpener(true);
  }

  function closeDrawer() {
    setCheckData(false);
    _setCheckData(false);
    setGridBoxValue([]);
    _setGridBoxValue([]);
    setActiveUserSelectedRowKeys([]);
    _setActiveUserSelectedRowKeys([]);
    clearFilterClient();
    clearFilterUser();
    setTimeout(() => {
      setDrawerOpener(false);
    }, 500);
    // _setAssignedUser(false);
    // setAssignedUser(false);
  }
  // This part of code is used for active client list from here to >>
  useEffect(() => {
    if (_drawerOpener) {
      setTimeout(() => {
        // datas()
        setPortalDataGrid(activeUserCustomStore(setCheckData));
        activeUserRef.current = activeUserCustomStore(setCheckData);
        newactiveuser.current = activeUserCustomStorecheck(setCheckData)
      }, 1000);
    }
  }, [_drawerOpener, assignedUser]);

  useEffect(() => {
    if (_drawerOpener) {
      setTimeout(() => {
        _setPortalDataGrid(customStore(_setCheckData));
        clientDatRef.current = customStore(_setCheckData);
        ClientDataRef.current = customStoreclient(_setCheckData);
      }, 1000);
    }
  }, [clientDatRef, _drawerOpener]);

  useEffect(() => {
    // console.log(_isGridBoxOpened, isGridBoxOpened, "_isGridBoxOpened");
    if (!isGridBoxOpened || !_isGridBoxOpened) {
      clearFilterClient();
      clearFilterUser();
    }
  }, [isGridBoxOpened, _isGridBoxOpened]);

  async function datas() {
    let take = 250;
    const res = await activeUserListdata(take);
    if (res.data.data !== null) {
      const datss = res.data.data.response;
      stealldata(datss)
    }
  }

  async function handleActiveUserById() {
    performLongOperation();
    if (gridBoxValue !== null) {
      if (gridBoxValue.length > 0 && activeRef.current) {
        if (gridBoxValue[0].clientId !== undefined) {
          if (gridBoxValue[0].clientId) {
            setclientId(gridBoxValue[0].clientId)
          }
          const body = {
            sort: null,
            requireTotalCount: true,
            columnInclude: [],
            skip: 0,
            take: 1000,
            TableName: "clientaccess",
            clientId: gridBoxValue[0].clientId,
            filter: [],
          };
          const res = await getActiveUserById(body);
          let username = [];
          if (res.data.data !== null) {
            closeLoader();
            let array = res.data.data.response;
            array.forEach((element) => {
              if (element.username) {
                username.push(element.username);
              }
            });
          } else {
            closeLoader();
            setActiveUserSelectedRowKeys([]);
          }

          const arr = activeRef.current.instance.getDataSource().items();
          const selectedRows = [];
          arr.forEach((element, index) => {
            if (username) {
              username.forEach((ele) => {
                if (element.username === ele) {
                  selectedRows.push(element.indexValue + 1);
                } else {
                  setActiveUserSelectedRowKeys([]);
                }
              });
            }
          });
          if (selectedRows.length > 0) {
            setActiveUserSelectedRowKeys(selectedRows);
          }
        } else {
          closeLoader();
          setActiveUserSelectedRowKeys([]);
        }
      } else {
        closeLoader();
      }
    } else {
      closeLoader();
    }
  }

  useEffect(() => {
    if (_drawerOpener && checkData) {
      handleActiveUserById();
    }
  }, [_drawerOpener, checkData, gridBoxValue, assignedUser]);

  const handleCellPrepared = (e) => {
    if (e.cellElement.ariaLabel === "Column User Name") {
      e.cellElement.style.backgroundColor = "#008ce3";
    }
  };
  const _handleCellPrepared = (e) => {
    if (e.cellElement.ariaLabel === "Column Client Name") {
      e.cellElement.style.backgroundColor = "#008ce3";
    }
  };
  function dataGridOnSelectionChanged(e) {
    if (e.selectedRowsData) {
      if (e.selectedRowsData.length) {
        if (e.selectedRowsData[0].clientName) {
          setGridBoxValue(e.selectedRowsData);
          if (gridBoxValue) {
            setIsGridBoxOpened(false);
          }
        }
      }
    }
  }

  function onSelectionChangedDataActiveUser(e) {
    if (e.selectedRowsData) {
      let arrs = e.selectedRowsData;
      let newarr = []
      arrs.forEach(element => {
        newarr.push({
          username: element.username,
          clientId: clientIds,
          isAccessGranted: 1,
          createdBy: userInfo.userName,
          updatedBy: userInfo.userName,
          deleted: 0,
        });
        Setallselectduser(newarr);
      });
    }

    let arr = [];

    if (activeRef.current !== null) {
      arr = activeRef.current.instance.getDataSource().items();
    }
    if (arr[e.currentSelectedRowKeys - 1]) {
      setSelectedRowData((prevState) => ({
        ...prevState,
        newSelectedRows: [
          ...prevState.newSelectedRows,
          arr[e.currentSelectedRowKeys - 1],
        ],
      }));
    }
    if (arr[e.currentDeselectedRowKeys - 1]) {
      setSelectedRowData((prevState) => ({
        ...prevState,
        newDeselectedSelectedRows: [
          ...prevState.newDeselectedSelectedRows,
          arr[e.currentDeselectedRowKeys - 1],
        ],
      }));
    }
    setActiveUserSelectedRowKeys(e.selectedRowKeys);
    setSelectedActiveUser(e.selectedRowsData);
  }

  async function handleAddUser(addValue) {
    let clientId;
    let username;
    let tempArr = setallselectduser;
    let userDetails = [];
    setTimeout(() => {
      if (addValue === "addUser") {
        if (gridBoxValue[0] !== null && gridBoxValue[0] !== undefined) {
          if (gridBoxValue[0].clientId) {
            clientId = gridBoxValue[0].clientId;
          }
        } else {
          setStatus({
            display: true,
            text: `All field Required`,
            statusType: "warning",
          });
        }
        const processedUsernames = new Set();
        if (selectedActiveUser) {
          selectedActiveUser.forEach((element) => {
            if (!processedUsernames.has(element.username)) {
              processedUsernames.add(element.username);
              setallselectduser.push({
                username: element.username,
                clientId: clientId,
                isAccessGranted: 1,
                createdBy: userInfo.userName,
                updatedBy: userInfo.userName,
                deleted: 0,
              });
            }
          });
        }
      }
      else if (addValue === "addClient") {
        if (_gridBoxValue[0] !== null && _gridBoxValue[0] !== undefined) {
          username = _gridBoxValue[0].username;
        }
        const _processedUsernames = new Set();
        if (_selectedActiveUser) {
          _selectedActiveUser.forEach((element) => {
            if (!_processedUsernames.has(element.username)) {
              _processedUsernames.add(element.username);
              setallselectduser.push({
                username: username,
                clientId: element.clientId,
                isAccessGranted: 1,
                createdBy: userInfo.userName,
                updatedBy: userInfo.userName,
                deleted: 0,
              });
            }
          });
        }
      }
    }, 2000);
 
    try {
      if (setallselectduser.length > 0) {
        if (addValue === "addUser") {
          const res = await postActiveUser(setallselectduser);
          if (
            res.data.data.message ===
            "Access control has been inserted successfully!!"
          ) {
            setStatus({
              display: true,
              text: `Data added successfully`,
              statusType: "success",
            });
            setDrawerOpener(false);
            refreshAccessControl();
            // if (addValue === "addUser") {
            //   refreshAccessControl();
            // }
            //  else if (addValue === "addClient") {
            //   refreshAssignedUser();
            // }
          }
        } else if (addValue === "addClient") {
          const res = await postActiveClient(tempArr);
          if (
            res.data.data.message ===
            "Access control has been inserted successfully!!"
          ) {
            setStatus({
              display: true,
              text: `Data added successfully`,
              statusType: "success",
            });
            setDrawerOpener(false);
            refreshAssignedUser();
            // if (addValue === "addClient") {
            //   refreshAssignedUser();
            // }
          }
        }
      } else {
        setStatus({
          display: true,
          text: `All field Required`,
          statusType: "warning",
        });
      }
    } catch (error) {
      setStatus({
        display: true,
        text: `Something went wrong`,
        statusType: "warning",
      });
    }
  }

  function refresh() {
    handleActiveUserById();
    if (clientDatRef.current) {
      if (clientDatRef.current.instance) {
        clientDatRef.current.instance.refresh();
      }
    }
  }

  function clearFilterClient() {
    if (clientRef.current) {
      if (clientRef.current.instance) {
        clientRef.current.instance.clearFilter();
      }
    }
  }

  function clearFilterUser() {
    if (activeUsersRef.current) {
      if (activeUsersRef.current.instance) {
        activeUsersRef.current.instance.clearFilter();
      }
    }
  }

  function dataGridRender() {
    return (
      <div className="dropdown-job-selector-DG" style={{ height: "25vh" }}>
        <DataGrid
          keyExpr="id"
          // key="index"
          dataSource={clientDatRef.current}
          hoverStateEnabled={true}
          selectedRowKeys={gridBoxValue}
          onSelectionChanged={dataGridOnSelectionChanged}
          height="100%"
          filterSyncEnabled={true}
          remoteOperations={true}
          loadPanel={{ enabled: true }}
          showBorders={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          showColumnHeaders={false}
          refresh={refresh}
          columnResizingMode="widget"
          ref={clientRef}
        >
          <StateStoring enabled={true} />
          <FilterRow visible={true} />
          {/* <Column
            visible={false}
            dataField="id"
            caption="ID"
            allowFiltering={false}
          />
          . */}
          <Column
            // caption="Client Name"
            dataField="clientName"
            allowSorting={false}
            width={"250px"}
            editorOptions={{
              showClearButton: true,
            }}
          />
          {/* <Column
            // caption="Client Name"
            dataField="username"
            allowSorting={false}
            width={"250px"}
            editorOptions={{
              showClearButton: true,
            }}
          /> */}
          <Selection mode="single" />
          <Scrolling mode="virtual" />
          <Paging enabled={true} pageSize={10} />
        </DataGrid>
      </div>
    );
  }

  function syncDataGridSelection(e) {
    setGridBoxValue(e.value);
    if (e.value === null) {
      setActiveUserSelectedRowKeys([]);
    }
  }

  function onGridBoxOpened(e) {
    if (e) {
      if (e.component && e.name === "opened") {
        setIsGridBoxOpened(e.value);
      }
    }
  }

  function _onGridBoxOpened(e) {
    if (e) {
      if (e.name === "opened") {
        _setIsGridBoxOpened(e.value);
      }
    }
  }
  function _dataGridOnSelectionChanged(e) {
    if (e.selectedRowsData) {
      if (e.selectedRowsData.length) {
        if (e.selectedRowsData[0].username) {
          _setGridBoxValue(e.selectedRowsData);
          if (_gridBoxValue) {
            _setIsGridBoxOpened(false);
          }
        }
      }
    }
  }
  function _dataGridRender() {
    return (
      <div className="dropdown-job-selector-DG" style={{ height: "25vh" }}>
        <DataGrid
          keyExpr="id"
          // key="index"
          dataSource={activeUserRef.current}
          hoverStateEnabled={true}
          selectedRowKeys={_gridBoxValue}
          onSelectionChanged={_dataGridOnSelectionChanged}
          height="100%"
          filterSyncEnabled={true}
          remoteOperations={true}
          loadPanel={{ enabled: true }}
          showBorders={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          showColumnHeaders={false}
          ref={activeUsersRef}
        >
          <StateStoring enabled={true} />
          <FilterRow visible={true} />
          {/* <Column
            visible={false}
            key="id"
            dataField="id"
            caption="ID"
            allowFiltering={false}
          /> */}
          <Column
            // caption="User Name"
            dataField="username"
            allowSorting={false}
            width={"250px"}
          />
          <Selection mode="single" />
          <Scrolling mode="virtual" />
          <Paging enabled={true} pageSize={10} />
        </DataGrid>
      </div>
    );
  }

  function _onSelectionChangedDataActiveUser(e) {

    if (e.selectedRowsData) {
      let arrs = e.selectedRowsData;
      let newarr = []
      arrs.forEach(element => {
        newarr.push({
          username: setUsername,
          clientId: element.clientId,
          isAccessGranted: 1,
          createdBy: userInfo.userName,
          updatedBy: userInfo.userName,
          deleted: 0,
        });
        Setallselectduser(newarr);
      });
    }

    let arr = [];
    if (clientRef.current !== null) {
      arr = clientRef.current.instance.getDataSource().items();
    }
    if (arr[e.currentSelectedRowKeys - 1]) {
      _setSelectedRowData((prevState) => ({
        ...prevState,
        newSelectedRows: [
          ...prevState.newSelectedRows,
          arr[e.currentSelectedRowKeys - 1],
        ],
      }));
    }
    if (arr[e.currentDeselectedRowKeys - 1]) {
      _setSelectedRowData((prevState) => ({
        ...prevState,
        newDeselectedSelectedRows: [
          ...prevState.newDeselectedSelectedRows,
          arr[e.currentDeselectedRowKeys - 1],
        ],
      }));
    }
    _setActiveUserSelectedRowKeys(e.selectedRowKeys);
    _setSelectedActiveUser(e.selectedRowsData);
  }
  function _syncDataGridSelection(e) {
    _setGridBoxValue(e.value);
    if (e.value === null) {
      _setActiveUserSelectedRowKeys([]);
    }
  }

  async function _handleActiveUserById() {
    _performLongOperation();
    if (_gridBoxValue !== null) {
      if (_gridBoxValue.length > 0 && clientDatRef.current) {
        if (_gridBoxValue[0].username !== undefined) {
          if (_gridBoxValue[0].username) {
            SetUsername(_gridBoxValue[0].username)
          }
          const body = {
            sort: null,
            requireTotalCount: true,
            columnInclude: [],
            skip: 0,
            take: 1000,
            username: _gridBoxValue[0].username,
            TableName: "clientaccess",
            filter: [],
          };
          const res = await getClientByName(body);
          let username = [];
          if (res.data.data !== null) {
            _closeLoader();
            let array = res.data.data.response;
            array.forEach((element) => {
              if (element.clientName) {
                username.push(element.clientName);
              }
            });
          } else {
            _closeLoader();
            _setActiveUserSelectedRowKeys([]);
          }
          const selectedRows = [];
          let arr = [];
          if (clientRef.current !== null) {
            arr = clientRef.current.instance.getDataSource().items();
          }
          arr.forEach((element, index) => {
            username.forEach((ele) => {
              if (element.clientName === ele) {
                selectedRows.push(element.indexValue + 1);
              } else {
                _closeLoader();
                setActiveUserSelectedRowKeys([]);
              }
            });
          });
          if (selectedRows.length > 0) {
            _closeLoader();
            _setActiveUserSelectedRowKeys(selectedRows);
          }
        } else {
          _closeLoader();
          _setActiveUserSelectedRowKeys([]);
        }
      } else {
        _closeLoader();
      }
    } else {
      _closeLoader();
    }
  }

  useEffect(() => {
    if (_drawerOpener && _checkData) {
      _handleActiveUserById();
    }
  }, [_drawerOpener, _checkData, _gridBoxValue, _assignedUser]);

  function handleAuditLog() {
    setAuditLogs(true);
  }
  const shouldShowClearButton = () => {
    setGridBoxValue([]);
    setClientName("");
    setActiveUserSelectedRowKeys([]);
    refresh();
    _handleActiveUserById();
    return true;
  };

  function handleContentReady(e) {
    handleActiveUserById();
  }

  function _handleContentReady(e) {
    _handleActiveUserById();
  }
  const performLongOperation = useCallback(() => {
    if (activeRef.current) {
      activeRef.current.instance.beginCustomLoading();
    }
    // ...
  }, []);
  const closeLoader = useCallback(() => {
    if (activeRef.current) {
      activeRef.current.instance.endCustomLoading();
    }
    // ...
  }, []);

  const _performLongOperation = useCallback(() => {
    if (clientRef.current) {
      clientRef.current.instance.beginCustomLoading();
    }
    // ...
  }, []);
  const _closeLoader = useCallback(() => {
    if (clientRef.current) {
      clientRef.current.instance.endCustomLoading();
    }
    // ...
  }, []);

  return (
    <>
      {status.display && (
        <Alerts status={status} sx={{ margin: "10px", zIndex: "1211" }} />
      )}
      {<AuditLogs auditLogs={auditLogs} setAuditLogs={setAuditLogs} />}
      {/* <ViewAccessRowData
        ViewAccessRowDataDialog={ViewAccessRowDataDialog}
        setViewAccessRowDataDialog={setViewAccessRowDataDialog}
        cellDetails={cellDetails}
      /> */}
      {selectedOption === "Delete" && (
        <Dialog
          open={viewDetails}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          style={{
            backdropFilter: "blur(4px)",
            backgroundColor: "rgba(111, 126, 140, 0.2)",
            transition: "opacity 100ms ease 0s",
            zIndex: 1600,
          }}
        >
          <DialogTitle
            style={{
              background: "#3a86ff",
              marginBottom: "3rem",
              color: "white",
            }}
          >
            {"Alert"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{ color: "#14213d" }}
              id="alert-dialog-slide-description"
            >
              Are you sure you want to delete this record?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              // onClick={() => {
              // handleDelete();
              // }}
              style={{ background: "#38b000" }}
              variant="contained"
            >
              Yes
            </Button>
            <Button
              style={{ background: "#c9184a" }}
              variant="contained"
              onClick={handleClose}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {view === "top" && (
        <div
          className={drawerOpener ? "add-btn" : "close-btn"}
          style={{
            display: "flex",
            width: drawerOpener ? "80%" : "98%",
            justifyContent: "space-between",
            marginLeft: drawerOpener ? "245px" : "42px",
          }}
        >
          <Stack spacing={2} direction="row">
            <Button
              sx={{ textTransform: "none" }}
              size="large"
              variant="contained"
              onClick={handleDrawerOpen}
            >
              {selectedValue === "Client Name"
                ? "Assign User"
                : "Assign Client"}
            </Button>
          </Stack>
          <Stack spacing={2} direction="row">
            <Button
              size="large"
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={handleAuditLog}
            >
              Audit Logs
            </Button>
          </Stack>
        </div>
      )}
      <Drawer
        anchor="right"
        open={_drawerOpener}
        onClose={closeDrawer}
        PaperProps={{ style: { width: 600 } }}
      >
        <DialogTitle
          style={{
            background: "rgb(0 140 227)",
            color: "white",
            fontSize: "20px",
          }}
          id="responsive-dialog-title"
        >
          Add
        </DialogTitle>
        <IconButton
          size="large"
          aria-label="close"
          onClick={closeDrawer}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <>
          {selectedValue === "Client Name" ? (
            <div
              className="main-div"
              style={{
                height: "80vh",
                marginTop: "1%",
                marginLeft: drawerOpener ? "0px" : "0px",
                width: drawerOpener ? "100%" : "100%",
              }}
            >
              <Grid container>
                <Grid
                  item
                  md={6}
                  sx={{
                    display: "flex",
                    flexDirection: "start",
                    margin: "10px 0px 25px 10px",
                  }}
                >
                  <Box sx={{ flexDirection: "column", width: "100%" }}>
                    <span className="fieldName">
                      Client Name
                      {!assignedUser && <span className="asterisk">*</span>}
                      <span> &nbsp; &nbsp;{clientName ? clientName : ""}</span>
                    </span>
                    {!assignedUser && (
                      <DropDownBox
                        // height="55px"
                        // setGridBoxValue([]);
                        value={gridBoxValue}
                        opened={isGridBoxOpened}
                        valueExpr="id"
                        deferRendering={false}
                        displayExpr={(e) => {
                          if (gridBoxValue && gridBoxValue.length) {
                            return gridBoxValue[0].clientName;
                          } else {
                            return ""; // or some default value
                          }
                        }}
                        placeholder="Please select a Client Name..."
                        showClearButton={() => shouldShowClearButton()}
                        onValueChanged={syncDataGridSelection}
                        onOptionChanged={onGridBoxOpened}
                        contentRender={dataGridRender}
                        dropDownOptions={{ closeOnValueSelect: true }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>

              <DataGrid dataSource={newactiveuser.current}
                showBorders={true}
                keyExpr="username"
                key="index"
                height="90%"
                width="100%"
                allowColumnResizing={true}
                showRowLines={true}
                showColumnLines={true}
                columnResizingMode="widget"
                selectedRowKeys={activeUserSelectedRowKeys}
                onSelectionChanged={onSelectionChangedDataActiveUser}
                ref={activeRef}
              >
                <Toolbar>
                  <Item location="after">
                    <Button2
                      hint="Refresh"
                      icon="refresh"
                      onClick={refresh}
                    />
                  </Item>
                  <Item name="columnChooserButton" />
                  <Item name="exportButton" />
                </Toolbar>
                <Selection
                  mode="multiple"
                />
                <ColumnChooser enabled={true} />
                <Export enabled={true} allowExportSelectedData={false} />
                <FilterRow visible={true} />
                <Scrolling columnRenderingMode="virtual" />
                <Paging defaultPageSize={250} />
                <Pager
                  showPageSizeSelector={true} // hide the page size selector
                  allowedPageSizes={allowedPageSizes}
                  showInfo={true}
                  visible={true}
                />
                <Column dataField="username"
                  caption="User Name"
                  editorOptions={{
                    showClearButton: true,
                  }} width={135} />
                <Column dataField="emailid"
                  caption="Email Id"
                  editorOptions={{
                    showClearButton: true,
                  }} width={150} />
              </DataGrid>


              {/* <DataGrid
                dataSource={activeUserRef.current}
                keyExpr="id"
                key="index"
                height="90%"
                width="100%"
                showRowLines={true}
                showColumnLines={true}
                hoverStateEnabled
                showBorders={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnMinWidth={50}
                loadPanel={{ enabled: true }}
                onSelectionChanged={onSelectionChangedDataActiveUser}
                onCellPrepared={handleCellPrepared}
                onContentReady={handleContentReady}
                onExporting={onExporting}
                columnResizingMode="widget"
                selectedRowKeys={activeUserSelectedRowKeys}
                ref={activeRef}
              >
                <Scrolling mode="standard" />
                <Toolbar>
                  <Item></Item>
                  <Item name="columnChooserButton" />
                  <Item name="exportButton" />
                </Toolbar>
                <ColumnChooser enabled={true} />
                <Export enabled={true} allowExportSelectedData={false} />
                <ColumnFixing enabled={true} />
                <Selection mode="multiple" />
                <Scrolling columnRenderingMode="virtual" />
                <ColumnChooser enabled={true} />
                <Export enabled={true} allowExportSelectedData={false} />
                <ColumnFixing enabled={true} />
                <Paging defaultPageSize={250} enabled={true} />
                <Pager
                  showPageSizeSelector={true} 
                  allowedPageSizes={allowedPageSizes}
                  showInfo={true}
                  visible={true}
                />
                <RemoteOperations groupPaging={true} />
                <FilterRow visible={true} />

                <Column
                  allowReordering={true}
                  width="200px"
                  caption="User Name"
                  dataField="username"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  allowReordering={true}
                  width="100px"
                  caption="Email Id"
                  dataField="emailid"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  allowReordering={true}
                  width="100px"
                  caption="Status"
                  dataField="status"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
              </DataGrid> */}

              <Stack
                spacing={2}
                direction="row-reverse"
                style={{
                  height: "36px",
                  marginRight: "10px",
                  marginTop: "5px",
                }}
              >
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => handleAddUser("addUser")}
                >
                  Add
                </Button>
              </Stack>
            </div>
          ) : (
            <div
              className="main-div"
              style={{
                height: "80vh",
                marginTop: "1%",
                marginLeft: drawerOpener ? "0px" : "0px",
                width: drawerOpener ? "100%" : "100%",
              }}
            >
              <Grid container>
                <Grid
                  item
                  md={6}
                  sx={{
                    display: "flex",
                    flexDirection: "start",
                    margin: "10px 0px 25px 10px",
                  }}
                >
                  <Box sx={{ flexDirection: "column", width: "100%" }}>
                    <span className="fieldName">
                      User Name
                      {!_assignedUser && <span className="asterisk">*</span>}
                      <span> &nbsp; &nbsp;{userValue ? userValue : ""}</span>
                    </span>
                    {!_assignedUser && (
                      <DropDownBox
                        // height="55px"
                        value={_gridBoxValue}
                        opened={_isGridBoxOpened}
                        valueExpr="id"
                        deferRendering={false}
                        displayExpr={(e) => {
                          if (_gridBoxValue && _gridBoxValue.length) {
                            return _gridBoxValue[0].username;
                          } else {
                            return "";
                          }
                        }}
                        placeholder="Please select a User Name..."
                        showClearButton={true}
                        // dataSource={accountOptionData}
                        onValueChanged={_syncDataGridSelection}
                        onOptionChanged={_onGridBoxOpened}
                        contentRender={_dataGridRender}
                        dropDownOptions={{ closeOnValueSelect: true }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>

              <DataGrid
                dataSource={ClientDataRef.current}
                showBorders={true}
                keyExpr="username"
                key="index"
                height="90%"
                width="100%"
                allowColumnResizing={true}
                showRowLines={true}
                showColumnLines={true}
                columnResizingMode="widget"
                selectedRowKeys={_activeUserSelectedRowKeys}
                onSelectionChanged={_onSelectionChangedDataActiveUser}
                ref={clientRef}
              >
                <Toolbar>
                  <Item location="after">
                    <Button2
                      hint="Refresh"
                      icon="refresh"
                      onClick={refresh}
                    />
                  </Item>
                  <Item name="columnChooserButton" />
                  <Item name="exportButton" />
                </Toolbar>
                <Selection
                  mode="multiple"
                />
                <ColumnChooser enabled={true} />
                <Export enabled={true} allowExportSelectedData={false} />
                <FilterRow visible={true} />
                <Scrolling columnRenderingMode="virtual" />
                <Paging defaultPageSize={250} />
                <Pager
                  showPageSizeSelector={true} // hide the page size selector
                  allowedPageSizes={allowedPageSizes}
                  showInfo={true}
                  visible={true}
                />
                <Column dataField="clientName"
                  caption="Client Name"
                  editorOptions={{
                    showClearButton: true,
                  }} width={140} />
                <Column dataField="isActivated"
                  caption="Status"
                  editorOptions={{
                    showClearButton: true,
                  }} width={150} />
              </DataGrid>

              {/* <DataGrid
                dataSource={clientDatRef.current}
                keyExpr="id"
                key="index"
                height="90%"
                width="100%"
                showRowLines={true}
                showColumnLines={true}
                hoverStateEnabled
                showBorders={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnMinWidth={50}
                loadPanel={{ enabled: true }}
                onSelectionChanged={_onSelectionChangedDataActiveUser}
                onCellPrepared={_handleCellPrepared}
                selectedRowKeys={_activeUserSelectedRowKeys}
                onExporting={onExporting}
                columnResizingMode="widget"
                onContentReady={_handleContentReady}
                ref={clientRef}
              >
                <StateStoring
                  enabled={true}
                  type="custom"
                />
                <Scrolling mode="standard" />
                <Toolbar>
                  <Item name="columnChooserButton" />
                  <Item name="exportButton" />
                  <Item></Item>
                </Toolbar>
                <ColumnChooser enabled={true} />
                <Export enabled={true} allowExportSelectedData={false} />
                <ColumnFixing enabled={true} />
                <Selection mode="multiple" />
                <FilterRow visible={true} />
                <Scrolling columnRenderingMode="virtual" />
                <ColumnChooser enabled={true} />
                <Export enabled={true} allowExportSelectedData={false} />
                <ColumnFixing enabled={true} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={allowedPageSizes}
                  showInfo={true}
                  visible={true}
                />
                <Paging defaultPageSize={250} enabled={true} />
                <FilterRow visible={true} />
                <RemoteOperations groupPaging={true} />
                <Column
                  allowReordering={true}
                  width="200px"
                  caption="Client Name"
                  dataField="clientName"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  allowReordering={true}
                  width="100px"
                  caption="Status"
                  dataField="isActivated"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
              </DataGrid> */}

              <Stack
                spacing={2}
                direction="row-reverse"
                style={{
                  height: "36px",
                  marginRight: "10px",
                  marginTop: "5px",
                }}
              >
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => handleAddUser("addClient")}
                >
                  Add
                </Button>
              </Stack>
            </div>
          )}
        </>
      </Drawer>
      {view === "grid" &&
        (selectedValue === "Client Name" ? (
          <div className="row-container">
            <div
              style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {cellData.data.clientName}
            </div>
            <div className="cellIcon" style={{ flex: 0 }}>
              <IconButton
                title="action"
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(e) => {
                  handleClick(e, cellData.rowIndex, cellData.data);
                }}
              >
                <img
                  alt=""
                  src={actionIcon}
                  style={{ cursor: "pointer", height: "20px", width: "21px" }}
                // onClick={(e) => {
                //   handleClick(e, cellData.rowIndex, cellData.data);
                // }}
                />
              </IconButton>
            </div>
            {
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl.element}
                open={open && anchorEl.index === cellData.rowIndex}
                onClose={handleCloseMenu}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "22ch",
                    fontSize: "10px",
                    marginLeft: "15px",
                  },
                }}
                sx={{
                  "& .MuiMenu-paper": {
                    left: menuPosition.x + "px!important",
                    top: menuPosition.y + "px!important",
                  },
                }}
              >
                {menuItemUser.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "selectedOption"}
                    onClick={() => {
                      handleMenuItemClick(option, cellData.data);
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            }
          </div>
        ) : (
          <div className="row-container">
            <div
              style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {cellData.data.username}
            </div>
            <div className="cellIcon" style={{ flex: 0 }}>
              <IconButton
                title="action"
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(e) => {
                  handleClick(e, cellData.rowIndex, cellData.data);
                }}
              >
                <img
                  alt=""
                  src={actionIcon}
                  style={{ cursor: "pointer", height: "20px", width: "21px" }}
                // onClick={(e) => {
                //   handleClick(e, cellData.rowIndex, cellData.data);
                // }}
                />
              </IconButton>
            </div>
            {
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl.element}
                open={open && anchorEl.index === cellData.rowIndex}
                onClose={handleCloseMenu}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "22ch",
                    fontSize: "10px",
                    marginLeft: "15px",
                  },
                }}
                sx={{
                  "& .MuiMenu-paper": {
                    left: menuPosition.x + "px!important",
                    top: menuPosition.y + "px!important",
                  },
                }}
              >
                {_menuItemUser.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "selectedOption"}
                    onClick={() => {
                      _handleMenuItemClick(option, cellData.data);
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            }
          </div>
        ))}
    </>
  );
}
export default EditAccessControl;
