import "../../Admin/ClientPortalList/ClientPortalList.css";
import Box from "@mui/material/Box";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {Tab, Paper, IconButton } from "@mui/material";
import "../../Admin/Acces Control/AccessControl.css";
import { Button as Button2 } from "devextreme-react/button";
import clean from "../../assets/clean.png";
import ClearFiltericon from "../../assets/ClearFiltericon.png";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import {
  getChsCustomization,
  postChsCustomization,
} from "../../api/Customization";
import { useAuthContext } from "../../Context/AuthProvider";
import Alerts from "../../Alert/Alert";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import EditAcessControl from "../Acces Control/EditAcessControl";
import DataGrid, {
  Column,
  FilterRow,
  Paging,
  Pager,
  ColumnChooser,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import {
  findClientsAccessData,
  getUserClientData,
} from "../../api/AccessControlApi/AccessControlApi";
import { accessControlAudit } from "../../api/Audit Logs/AuditLogs";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { SelectBox } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import { getAtsClientName, getAtsClientUser } from "../../api/clients";
import Vector from "../../assets/Vector.png";
const groupingValues = [
  {
    value: "Client Name",
    text: "Client Name",
  },
  {
    value: "User",
    text: "User",
  },
];

const customStore = (setStatus, initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const take = options.take || 200;
      const skip = options.skip || 0;
      const sort = options.sort || null;
      try {
        const res = await getAtsClientName(filter, take, skip, sort);
        if (!res.data.data || res.data.data.response.length === 0) {
          return {
            data: [],
            totalCount: 0,
          };
        }

        let dataRes = [];
        if (res.data.data) {
          const startIndex = (options.skip || 0) + 1;
          dataRes = res.data.data.response.map((item, index) => {
            return {
              id: index + startIndex,
              clientName: item.clientName,
              assigned: item.assigned,
              clientId: item.clientId,
              isAutoProcessingEnabled: item.isAutoProcessingEnabled,
            };
          });
        }
        return {
          data: dataRes,
          totalCount: res.data.data.count,
        };
      } catch (error) {
        setStatus({
          display: true,
          text: `Something went wrong`,
          statusType: "warning",
        });
        return {
          data: [],
          totalCount: 0,
        };
      }
    },
  });
const customStoreUserDetails = (setStatus, initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const sort = options.sort || null;
      let res;
      try {
        res = await getAtsClientUser(filter, sort);
        if (!res.data.data || res.data.data.response.length === 0) {
          return {
            data: [],
            totalCount: 0,
          };
        }
        let dataRes = [];
        if (res.data.data) {
          const startIndex = (options.skip || 0) + 1;
          dataRes = res.data.data.response.map((item, index) => {
            return {
              id: index + startIndex,
              username: item.username,
              assigned: item.assigned,
              isAccessGranted: item.isAccessGranted,
            };
          });
        }
        return {
          data: dataRes,
          totalCount: res.data.data.count,
        };
      } catch (error) {
        setStatus({
          display: true,
          text: `Something went wrong`,
          statusType: "warning",
        });
        return {
          data: [],
          totalCount: 0,
        };
      }
    },
  });
const onExporting = (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Main sheet");
  // Obtain selected rows data
  const selectedRows = e.component.getSelectedRowsData();
  // Export only selected rows
  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
    customizeExportData: (columns, rows) => {
      return {
        columns,
        rows: selectedRows,
      };
    },
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "AccessControlList.xlsx"
      );
    });
  });
};
function AccessControl({ drawerOpener }) {
  const allowedPageSizes = [50, 100, 250];
  const [ClientOrUser, setClientOrUser] = useState("");
  const [accssControlAuditState, setAccssControlAuditState] = useState([]);
  const [ChooseOption, setChooseOption] = useState([]);
  const [postClient, setPostClient] = useState("");
  const [ViewClientUserDetails, setViewClientUserDetails] = useState([]);
  const [selectedTab, setSelectedTab] = useState("1");
  const [selectedValue, setSelectedValue] = useState("Client Name");
  const [adminClientData, setAdminClientData] = useState([]);
  const [adminUserData, setAdminUserData] = useState([]);
  const [Accordion, setAccordion] = useState([]);
  const [clearCustomizationPopUp, setClearCustomizationPopUp] = useState(false);
  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  const [expandedRows, setExpandedRows] = useState({});
  const dataGridRef = useRef(null);
  const dataGridRefUser = useRef(null);

  const { accessToken } = useAuthContext();
  const { userInfo } = useAuthContext();

  const saveState = useCallback(
    async (state) => {
      if (userInfo.userName) {
        const res = await postChsCustomization(
          userInfo.userName,
          "AccessControlClientList",
          state
        );
      }
    },
    [userInfo]
  );

  const loadState = useCallback(async () => {
    if (userInfo.userName) {
      const res = await getChsCustomization(
        userInfo.userName,
        "AccessControlClientList"
      );
      return res.data.data;
    }
  }, [userInfo]);
  const toggleData = (rowIndex) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowIndex]: !prevExpandedRows[rowIndex],
    }));
  };

  const saveStateUserName = useCallback(
    async (state) => {
      if (userInfo.userName) {
        const res = await postChsCustomization(
          userInfo.userName,
          "AccessControlUserList",
          state
        );
      }
    },
    [userInfo]
  );

  const loadStateUserName = useCallback(async () => {
    if (userInfo.userName) {
      const res = await getChsCustomization(
        userInfo.userName,
        "AccessControlUserList"
      );
      return res.data.data;
    }
  }, [userInfo]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (selectedValue === "Client Name") {
      setAdminClientData(customStore(setStatus, setAccordion));
    } else {
      setAdminUserData(customStoreUserDetails(setStatus));
    }
  }, [selectedValue]);
  useEffect(() => {
    if (accessToken != "null") {
      // getAccessControlData(accessToken);
      getAccessData(accessToken);
      ClientAccessData(accessToken);
      get_User_ClientData(accessToken, ClientOrUser, postClient);
    }
  }, [accessToken, ClientOrUser, postClient]);

  const toggleGroupColumn = useCallback((e) => {
    if (selectedValue === "Client Name") {
      clearFilters();
    } else {
      clearFiltersUser();
    }
    const newGrouping = e.value;
    setSelectedValue(newGrouping);
  }, [selectedValue]);

  async function getAccessData(accessToken) {
    try {
      const accessAuditData = await accessControlAudit(accessToken);
      if (accessAuditData) {
        const AuditData = accessAuditData.data.data.response.map(
          (item, index) => {
            return {
              id: index + 1,
              changedBy: item.changedBy,
              fieldName: item.fieldName,
              lastUpdatedDate: item.lastUpdatedDate,
              newValue: item.newValue,
              oldValue: item.oldValue,
            };
          }
        );
        if (accessAuditData.status === 200) {
          if (accessAuditData.data != null) {
            setAccssControlAuditState(AuditData);
          }
        }
      }
    } catch (error) {}
  }

  async function ClientAccessData(accessToken) {
    try {
      const viewClientData = await findClientsAccessData(accessToken);

      if (viewClientData) {
        const chooseUserClient = viewClientData.data.data.response.map(
          (info, index) => {
            return info;
          }
        );

        if (viewClientData.status === 200) {
          if (viewClientData.data != null) {
            setChooseOption(chooseUserClient);
          }
        }
      }
    } catch (error) {}
  }

  async function get_User_ClientData(accessToken, ClientOrUser, postClient) {
    if (postClient) {
      try {
        const userClient_Data = await getUserClientData(
          accessToken,
          ClientOrUser,
          postClient
        );
        const selectUsernameOrClient = userClient_Data.data.data.response.map(
          (info, index) => {
            let accessGrant = "";
            if (info.isAccessGranted === 1) {
              accessGrant = "Allow";
            } else if (info.isAccessGranted === 0) {
              accessGrant = "Deny";
            } else {
              accessGrant = "";
            }
            return {
              id: index + 1,
              clientId: info.clientId,
              clientName: info.clientName,
              accessGrant: accessGrant,
              username: info.username ? info.username : "",
            };
          }
        );
        if (userClient_Data.status === 200) {
          if (userClient_Data.data != null) {
            setViewClientUserDetails(selectUsernameOrClient);
          }
        }
      } catch (error) {}
    }
  }

  const handleCellPrepared = (e) => {
    if (
      e.cellElement.ariaLabel === "Column Client Name" ||
      e.cellElement.ariaLabel === "Column User Name"
    ) {
      e.cellElement.style.backgroundColor = "#008ce3";
    }
  };

  const clearFilters = (e) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.clearFilter();
    }
  };

  const clearFiltersUser = () => {
    if (dataGridRefUser.current) {
      dataGridRefUser.current.instance.clearFilter();
    }
  };

  const refreshAccessControl = () => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh();
    }
  };

  const refreshAssignedUser = () => {
    if (dataGridRefUser.current) {
      dataGridRefUser.current.instance.refresh();
    }
  };

  const clearCustomizationDataGrid = () => {
    if (selectedValue === "Client Name") {
      const dataGridInstance = dataGridRef.current.instance;
      const columns = dataGridInstance.getVisibleColumns();
      columns.forEach((column) => {
        column.visible = true;
        column.showInColumnChooser = true;
      });
      dataGridInstance.option("columns", columns);
      dataGridInstance.clearFilter();
      dataGridInstance.clearSelection();
      setClearCustomizationPopUp(false);
    } else {
      const dataGridInstance = dataGridRefUser.current.instance;
      const columns = dataGridInstance.getVisibleColumns();
      columns.forEach((column) => {
        column.visible = true;
        column.showInColumnChooser = true;
      });
      dataGridInstance.option("columns", columns);
      dataGridInstance.clearFilter();
      dataGridInstance.clearSelection();
      setClearCustomizationPopUp(false);
    }
  };
  const customChanges = {
    icon: "undo",
    text: "Clear",
    type: "default",
    onClick: () => clearCustomizationDataGrid(),
  };
  const cancelCustomchanges = {
    text: "Close",
    icon: "remove",
    type: "danger",
    onClick: () => {
      setClearCustomizationPopUp(false);
    },
  };

  function insertLineBreaks(data) {
    return data.replace(/(?:[^,]*,){5}/g, "$&\n");
  }

  return (
    <>
      {status.display && <Alerts status={status} sx={{ margin: "10px" }} />}

      <Popup
        visible={clearCustomizationPopUp}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        container="body"
        width={500}
        height={200}
        title="Alert"
      >
        <Position my="center" of={"main_dataGridClass"} collision="fit" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={customChanges}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelCustomchanges}
        />
        <p>Your custom changes made by you on grid will be removed..</p>
      </Popup>
      <TabContext value={selectedTab}>
        <Box sx={{ width: "100%", marginTop: "50px", height: "15px" }}>
          <Paper square>
            <TabList
              onChange={handleTabChange}
              sx={{ marginLeft: drawerOpener ? "240px" : "60px" }}
            >
              <Tab
                sx={{
                  textTransform: "none",
                }}
                className="tab-text-style"
                label="Access Control"
                value="1"
              />
            </TabList>
          </Paper>
        </Box>
        <TabPanel value="1">
          <EditAcessControl
            drawerOpener={drawerOpener}
            selectedValue={selectedValue}
            refreshAccessControl={refreshAccessControl}
            refreshAssignedUser={refreshAssignedUser}
            view="top"
          />
          <div
            className="main-div"
            style={{
              height: "80vh",
              // width: "100%",
              marginTop: "1%",
              marginLeft: drawerOpener ? "255px" : "23px",
              width: drawerOpener ? "86%" : "100%",
            }}
          >
            <Box
              className="main-div"
              style={{
                height: "80vh",
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "20px",
              }}
            >
              {selectedValue === "Client Name" ? (
                <DataGrid
                  dataSource={adminClientData}
                  keyExpr="id"
                  key="index"
                  height="90%"
                  width="100%"
                  showRowLines={true}
                  showColumnLines={true}
                  hoverStateEnabled
                  showBorders={true}
                  allowColumnResizing={true}
                  allowColumnReordering={true}
                  columnMinWidth={50}
                  loadPanel={{ enabled: true }}
                  filterSyncEnabled={true}
                  remoteOperations={true}
                  onExporting={onExporting}
                  columnResizingMode="widget"
                  onCellPrepared={handleCellPrepared}
                  ref={dataGridRef}
                >
                  <StateStoring
                    enabled={true}
                    type="custom"
                    customLoad={loadState}
                    customSave={saveState}
                  />
                  <Scrolling mode="standard" />
                  <Toolbar>
                    <Item location="before">
                      <div className="informer">
                        <div className="count"></div>
                      </div>
                    </Item>
                    <Item location="before" style={{ margin: "10px" }}>
                      <SelectBox
                        width="225"
                        height={40}
                        items={groupingValues}
                        displayExpr="text"
                        valueExpr="value"
                        value={selectedValue}
                        onValueChanged={toggleGroupColumn}
                      />
                    </Item>
                    <Item location="after">
                      <Button2
                        hint="Refresh"
                        icon="refresh"
                        onClick={refreshAccessControl}
                      />
                    </Item>
                    <Item>
                      <img
                        title="Clear Filter"
                        className="clean"
                        src={ClearFiltericon}
                        onClick={() => {
                          const isFilterApplied =
                            dataGridRef.current.instance.getCombinedFilter();
                          if (isFilterApplied != undefined) {
                            clearFilters();
                          }
                        }}
                      ></img>
                    </Item>
                    <Item name="columnChooserButton" />
                    <Item name="exportButton" />
                    <Item>
                      <img
                        title="Clear Customization"
                        className="clean"
                        src={clean}
                        onClick={() => {
                          setClearCustomizationPopUp(true);
                        }}
                      ></img>
                    </Item>
                  </Toolbar>
                  <ColumnChooser enabled={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <ColumnFixing enabled={true} />
                  <FilterRow visible={true} />
                  <Scrolling columnRenderingMode="virtual" />
                  <ColumnChooser enabled={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <ColumnFixing enabled={true} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showInfo={true}
                    visible={true}
                  />
                  <Paging defaultPageSize={50} enabled={true} />
                  <FilterRow visible={true} />
                  <Column
                    allowSorting={false}
                    width="50px"
                    allowFiltering={false}
                    alignment="left"
                    caption="S.No"
                    dataField="id"
                    allowReordering={false}
                  />
                  <Column
                    allowReordering={true}
                    width="200px"
                    caption="Client Name"
                    dataField="clientName"
                    editorOptions={{
                      showClearButton: true,
                    }}
                    cellRender={(cellData) => {
                      return (
                        <>
                          <EditAcessControl
                            cellData={cellData}
                            view="grid"
                            selectedValue={selectedValue}
                            refreshAccessControl={refreshAccessControl}
                          />
                        </>
                      );
                    }}
                  />
                  <Column
                    allowReordering={true}
                    width="200px"
                    caption="Auto Upload"
                    dataField="isAutoProcessingEnabled"
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    width="210px"
                    caption="Assigned Users"
                    dataField="assigned"
                    cellRender={(cellData, indexValue) => {
                      return (
                        <>
                          <div
                            style={{ display: "flex" }}
                            onClick={() => toggleData(cellData.rowIndex)}
                          >
                            <IconButton>
                              <img
                                title="View"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "11px",
                                }}
                                width="20px"
                                src={Vector}
                              />
                            </IconButton>
                            {expandedRows[cellData.rowIndex] ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      window.innerWidth > 600
                                        ? "row"
                                        : "column",
                                    width: "100%",
                                    overflow: "auto",
                                    whiteSpace: "pre-wrap",
                                    alignItems: "center",
                                  }}
                                >
                                  {insertLineBreaks(
                                    cellData.data.assigned
                                      ? cellData.data.assigned
                                      : ""
                                  ).replace(/,/g, ", ")}
                                </div>
                              </>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {cellData.data.assigned
                                  ? `${cellData.data.assigned
                                      .substring(0, 130)
                                      .replace(/,/g, ", ")} ${
                                      cellData.data.assigned.length > 130
                                        ? "..."
                                        : ""
                                    }`
                                  : ""}
                              </div>
                            )}
                          </div>
                        </>
                      );
                    }}
                  />
                </DataGrid>
              ) : (
                <DataGrid
                  dataSource={adminUserData}
                  keyExpr="id"
                  key="index"
                  height="90%"
                  width="100%"
                  showRowLines={true}
                  showColumnLines={true}
                  hoverStateEnabled
                  showBorders={true}
                  allowColumnResizing={true}
                  allowColumnReordering={true}
                  columnMinWidth={50}
                  loadPanel={{ enabled: true }}
                  filterSyncEnabled={true}
                  remoteOperations={true}
                  onExporting={onExporting}
                  onCellPrepared={handleCellPrepared}
                  columnResizingMode="widget"
                  ref={dataGridRefUser}
                >
                  <StateStoring
                    enabled={true}
                    type="custom"
                    customLoad={loadStateUserName}
                    customSave={saveStateUserName}
                  />
                  <Scrolling mode="standard" />
                  <Toolbar>
                    <Item location="before">
                      <div className="informer">
                        <div className="count"></div>
                      </div>
                    </Item>
                    <Item location="before" style={{ margin: "10px" }}>
                      <SelectBox
                        width="225"
                        height={40}
                        items={groupingValues}
                        displayExpr="text"
                        valueExpr="value"
                        value={selectedValue}
                        onValueChanged={toggleGroupColumn}
                      />
                    </Item>
                    <Item location="after">
                      <Button2
                        hint="Refresh"
                        icon="refresh"
                        onClick={refreshAssignedUser}
                      />
                    </Item>
                    <Item>
                      <img
                        title="Clear Filter"
                        className="clean"
                        src={ClearFiltericon}
                        onClick={() => {
                          const isFilterApplied =
                            dataGridRefUser.current.instance.getCombinedFilter();
                          if (isFilterApplied != undefined) {
                            clearFiltersUser();
                          }
                        }}
                      ></img>
                    </Item>

                    <Item name="columnChooserButton" />
                    <Item name="exportButton" />
                    <Item>
                      <img
                        title="Clear Customization"
                        className="clean"
                        src={clean}
                        onClick={() => {
                          setClearCustomizationPopUp(true);
                        }}
                      ></img>
                    </Item>
                  </Toolbar>
                  <ColumnChooser enabled={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <ColumnFixing enabled={true} />
                  <FilterRow visible={true} />
                  <Scrolling columnRenderingMode="virtual" />
                  <ColumnChooser enabled={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <ColumnFixing enabled={true} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showInfo={true}
                    visible={true}
                  />
                  <Paging defaultPageSize={50} enabled={true} />
                  <FilterRow visible={true} />

                  <Column
                    allowSorting={false}
                    width="50px"
                    allowFiltering={false}
                    alignment="left"
                    caption="S.No"
                    dataField="id"
                    allowReordering={false}
                    fixed={true}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="200px"
                    caption="User Name"
                    visible={true}
                    // fixed={true}
                    dataField="username"
                    editorOptions={{
                      showClearButton: true,
                    }}
                    cellRender={(cellData) => {
                      return (
                        <>
                          <EditAcessControl
                            cellData={cellData}
                            view="grid"
                            selectedValue={selectedValue}
                            refreshAssignedUser={refreshAssignedUser}
                          />
                        </>
                      );
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="200px"
                    caption="Assigned Client Name"
                    dataField="assigned"
                    editorOptions={{
                      showClearButton: true,
                    }}
                    cellRender={(cellData, indexValue) => {
                      return (
                        <>
                          <div
                            style={{ display: "flex" }}
                            onClick={() => toggleData(cellData.rowIndex)}
                          >
                            <IconButton>
                              <img
                                title="View"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "11px",
                                }}
                                width="20px"
                                src={Vector}
                              />
                            </IconButton>
                            {expandedRows[cellData.rowIndex] ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      window.innerWidth > 600
                                        ? "row"
                                        : "column",
                                    width: "100%",
                                    overflow: "auto",
                                    whiteSpace: "pre-wrap",
                                    alignItems: "center",
                                  }}
                                >
                                  {insertLineBreaks(
                                    cellData.data.assigned
                                  ).replace(/,/g, ", ")}
                                </div>
                              </>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {cellData.data.assigned
                                  ? `${cellData.data.assigned
                                      .substring(0, 80)
                                      .replace(/,/g, ", ")} ${
                                      cellData.data.assigned.length > 80
                                        ? "..."
                                        : ""
                                    }`
                                  : ""}
                              </div>
                            )}
                          </div>
                        </>
                      );
                    }}
                  />
                </DataGrid>
              )}
            </Box>
            {/* </BootstrapDialog> */}
          </div>
        </TabPanel>
      </TabContext>
    </>
  );
}
export default AccessControl;
