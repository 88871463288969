import Button from "@mui/material/Button";
import "./ClientPortalList.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DropDownBox from "devextreme-react/drop-down-box";
import "devextreme/dist/css/dx.light.css";
import { useEffect, useRef, useState } from "react";
import { Stack, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import actionIcon from "../../assets/actionIcon.png";
import "./ClientPortalList.css";
import {
  GroupBySelection,
  accountNameStatus,
  categoryOption,
  postClientData,
  updateClientData,
} from "../../api/AdminForm";
import DataGrid, {
  Column,
  FilterRow,
  Paging,
  // Scrolling,
  RowDragging,
  Pager,
  ColumnChooser,
  Sorting,
  Lookup,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Editing,
  Scrolling,
  StateStoring,
  Selection,
} from "devextreme-react/data-grid";
import { useAuthContext } from "../../Context/AuthProvider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ViewSidebar from "../ViewSidebar";
import { Link, Router, useNavigate } from "react-router-dom";
import Alerts from "../../Alert/Alert";
import CustomStore from "devextreme/data/custom_store";
import ClientPortalRowAudit from "../../Admin/ClientPortalList/ClientPortalRowAudit";
import { CRM_REDIRECT_URL } from "../../constant/serverConstant";
import ElementConfig from "../../Admin/ClientPortalList/ElementConfig";
import i from "../../assets/i.png";
import { styled } from "@mui/system";
import { getTooltipValue } from "../../api/ToolTipApi/TooltipAPI";

const CustomTooltip = styled(Tooltip)({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "white",
    color: "grey",
    border: "1px solid grey",
  },
});
const menuItem = ["Edit", "Delete", "Audit Logs"];
const ITEM_HEIGHT = 20;
const optionsValue = [
  { id: 1, name: "Basic Authentication" },
  { id: 2, name: "Oauth" },
  { id: 3, name: "KeyBased Authentication" },
];
const statusValue = [
  { id: 1, name: "Active" },
  { id: 0, name: "In-Active" },
];
const customStore = (initialFilter = null) =>
  new CustomStore({
    key: "Sno",
    load: async function (options) {
      const filter = options.filter || ["isaccount", "=", "1"];
      const take = options.take || 10;
      const skip = options.skip;
      const sort = options.sort || null;
      let filterValue = filter;
      if (filterValue) {
        filterValue.forEach((element) => {
          if (element instanceof Array) {
            if (element[0] === "Sno") {
              if (element[0] !== "Sno") {
                filterValue.push(element);
              } else {
                filterValue = ["isaccount", "=", "1"];
              }
            } else {
              filterValue = filterValue;
            }
          } else {
            if (filterValue && Array.isArray(filterValue)) {
              const hasSno = filterValue.some(
                (element) =>
                  typeof element === "string" && element.includes("Sno")
              );
              if (!hasSno) {
                filterValue = filter;
              } else {
                filterValue = ["isaccount", "=", "1"];
              }
            }
          }
        });
      }
      const res = await accountNameStatus(filterValue, take, skip, sort);
      let dataRes = [];
      if (res.data.data) {
        dataRes = res.data.data.response.map((dataItem, index) => {
          const startIndex = (options.skip || 0) + 1;
          return {
            key: "Sno",
            Sno: index + startIndex,
            id: dataItem.id,
            accountType: dataItem.accounttype ? dataItem.accounttype : "",
            accountId: dataItem.id ? dataItem.id : "",
            name: dataItem.name ? dataItem.name : "",
            parentaccountname: dataItem.parentaccountname
              ? dataItem.parentaccountname
              : "",
          };
        });
      } else {
        return {
          data: [],
          totalCount: 0,
        };
      }
      return {
        data: dataRes,
        totalCount: res.data.data.count,
      };
    },
  });

function ClientForm({
  cellData,
  drawerOpener,
  view,
  setUpdateList,
  refresh,
  _refresh,
  selectedTab,
}) {
  const [groupByDropdown, setGroupByDropdown] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [gridBoxValue, setGridBoxValue] = useState([]);
  const [accountOptionData, setAccountOptionData] = useState([]);
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
  const [categoryOptionData, setCategoryOptionData] = useState([]);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [viewDetails, setViewDetails] = useState(false);
  const [formData, setFormData] = useState({
    isScreenShotEnabled: 1, isActivated: null
  });
  const [openAudit, setOpenAudit] = useState(false);

  const [_cellData, setCellData] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [anchorEl, setAnchorEl] = useState({ element: null, index: -1 });
  const [isEdit, setIsEdit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  const { accessToken, userInfo } = useAuthContext();
  const navigate = useNavigate();
  const clientDatRef = useRef("");
  const clientDatRefFilter = useRef("");
  const [toolTipValue, setToolTipValue] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAts, setIsOpenAts] = useState(false);
  const [isOpenGroup, setIsOpenGroup] = useState(false);
  const [isActiveOpen, setActiveIsOpen] = useState(false);
  const [errors, setErrors] = useState({
    clientName: "",
    loginUrl: "",
    agencyCompanyId: "",
    agencyId: "",
    username: "",
    password: "",
    jobsUrl: "",
    jdUrl: "",
    contactEmail: "",
  });
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleOpenAts = () => {
    setIsOpenAts(true);
  };
  const handleOpenGroup = () => {
    setIsOpenGroup(true);
  };
  const haldleOpenActive = () => {
    setActiveIsOpen(true)
  };

  const handleCloseDrop = () => {
    setIsOpen(false);
  };
  const handleCloseDropAts = () => {
    setIsOpenAts(false);
  };
  const handleCloseDropGroup = () => {
    setIsOpenGroup(false);
  };
  const haldleCloseActive = () => {
    setActiveIsOpen(false)
  };
  useEffect(() => {
    clientDatRef.current = customStore();
  }, [clientDatRef]);

  async function getToolTip() {
    try {
      const res = await getTooltipValue("clients");
      if (res.data.data) {
        res.data.data.response.forEach((element) => {
          if (element.fieldName === "name") {
            setToolTipValue(element.toolTipText);
          }
        });
      }
    } catch (error) { }
  }
  useEffect(() => {
    if (isSidebarOpen) {
      getToolTip();
    }
  }, [isSidebarOpen]);

  async function AdminFormGroupBy(accessToken) {
    try {
      const groupData = await GroupBySelection(accessToken);

      if (groupData) {
        const groupByData = groupData.data.data.response.map((item, index) => {
          return item;
        });
        if (groupData.data.status === "succeed") {
          if (groupData.data.data != null) {
            setGroupByDropdown(groupByData);
          }
        }
      }
    } catch (error) { }
  }
  const labelStyles = {
    fontSize: "20px", // Set your desired font size here
  };

  const toggleSidebar = () => {
    // setIsEdit(false);
    if (isSidebarOpen) {
      clearFilters();
    }
    setTimeout(() => {
      setIsSidebarOpen(!isSidebarOpen);
    }, 500);
    if (!isSidebarOpen) {
      setFormData({
        ...formData,
        isScreenShotEnabled: 1,
        isAutoProcessingEnabled: 0,
        isCvSent: 0,
      });
    } else {
      setFormData({});
    }
    setSelectedOption(null);
    setAnchorEl({ element: null, index: -1 });
    if (!isSidebarOpen) {
      setGridBoxValue([]);
    }
    setErrors({
      clientName: "",
      loginUrl: "",
      agencyCompanyId: "",
      agencyId: "",
      username: "",
      password: "",
      jobsUrl: "",
      jdUrl: "",
      contactEmail: "",
    });
  };

  const handleClosetoggleSidebar = () => {
    // clearFilters();
    clearFilters();
    setTimeout(() => {
      setIsSidebarOpen(false);
    }, 500);

    setFormData({ isScreenShotEnabled: 1 });
    setErrors({
      clientName: "",
      loginUrl: "",
      agencyCompanyId: "",
      agencyId: "",
      username: "",
      password: "",
      jobsUrl: "",
      jdUrl: "",
      contactEmail: "",
    });
  };

  async function _updateClientData(accessToken, userName, formData) {
    // alert('aaaaaaaaaaaaaa')
    delete formData.groupName;
    delete formData.categoryName;
    delete formData.id;
    let formsData = {
      ...formData,
      name: formData.clientName,
    };
    delete formData.clientName;
    try {
      const res = await updateClientData(accessToken, userName, formsData);
      if (res.data.data.message == "Clients Updated Successfully!!") {
        setStatus({
          display: true,
          text: "Clients Updated Successfully",
          statusType: "success",
        });
        setIsSidebarOpen(false);
        if (selectedTab == 1) {
          refresh();
        } else {
          _refresh();
        }
      }
    } catch (error) {
      setStatus({
        display: true,
        text: `Something went wrong`,
        statusType: "warning",
      });
      setFormData({});
      setIsSidebarOpen(false);
    }
  }

  const handleClick = (event, index, cellData) => {
    setCellData(cellData);
    setFormData(cellData);
    const { pageX, pageY } = event;
    const _pageY = pageY - document.documentElement.scrollTop;
    setMenuPosition({ x: pageX, y: _pageY });
    setAnchorEl({ element: event.currentTarget, index });
  };

  // function syncDataGridSelection(e) {
  //   setGridBoxValue(e.value);
  // }
  useEffect(() => {
    if (selectedOption === "Edit") {
      toggleSidebar();
      setGridBoxValue([
        { name: cellData.data.companyName, accountId: cellData.data.accountId },
      ]);
      setFormData({
        ...formData,
        isAutoProcessingEnabled: formData.isAutoProcessingEnabled,
        isCvSent: formData.isCvSent,
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    setFormData({});
  }, []);

  async function accountNameDropdown() {
    try {
    } catch (error) { }
  }
  function syncDataGridSelection(e) {
    setGridBoxValue(e.value);
  }

  function onGridBoxOpened(e) {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }

  useEffect(() => {
    if (accessToken != "null") {
    }
    if (
      accessToken &&
      isSidebarOpen &&
      !categoryOptionData.length &&
      !accountOptionData.length
    ) {
      AdminFormGroupBy(accessToken);
      categoryDropdown(accessToken);
      accountNameDropdown(accessToken);
    }
  }, [accessToken, isSidebarOpen]);

  const handleCloseMenu = () => {
    setAnchorEl({ element: null, index: -1 });
  };

  const handleMenuItemClick = (option) => {
    if (option === "Edit") {
      setIsEdit(true);
      setFormData({
        ...formData,
        isActivated: formData.isActivated === 1 ? 0 : 1,
        isAutoProcessingEnabled: formData.isAutoProcessingEnabled,
      });
    }
    setSelectedOption(option);
    setViewDetails(!viewDetails);
    handleCloseMenu();
    if (option === "Audit Logs") {
      setOpenAudit(true);
    }
  };

  async function categoryDropdown() {
    try {
      const categoryData = await categoryOption();
      if (categoryData) {
        const filterCategory = categoryData.data.data.response.map(
          (item, index) => {
            return item;
          }
        );
        if (categoryData.status === "succeed" || categoryData.status === 200) {
          if (categoryData.data != null) {
            setCategoryOptionData(filterCategory);
          }
        }
      }
    } catch (error) { }
  }
  useEffect(() => {
    if (toggleSidebar) {
      clearFilters();
    }
  }, [toggleSidebar]);
  function clearFilters() {
    if (clientDatRefFilter.current) {
      clientDatRefFilter.current.instance.clearFilter();
    }
  }

  function dataGridRender() {
    return (
      <div className="dropdown-job-selector-DG">
        {/* <button onClick={()=>clearFilters()}>click</button> */}
        <DataGrid
          keyExpr="Sno"
          dataSource={clientDatRef.current}
          hoverStateEnabled={true}
          selectedRowKeys={gridBoxValue}
          onSelectionChanged={dataGridOnSelectionChanged}
          height="100%"
          filterSyncEnabled={true}
          remoteOperations={true}
          loadPanel={{ enabled: true }}
          showBorders={true}
          allowColumnResizing={true}
          columnResizingMode="widget"
          allowColumnReordering={true}
          ref={clientDatRefFilter}
        >
          <StateStoring enabled={true} />
          <FilterRow visible={true} />
          <Column dataField="Sno" visible={false} allowFiltering={false} />
          <Column
            visible={false}
            dataField="Sno"
            width={"50px"}
            editorOptions={{
              showClearButton: true,
            }}
          />
          <Column
            caption="Account ID"
            dataField="id"
            dataType="string"
            width={"50px"}
            editorOptions={{
              showClearButton: true,
            }}
          />
          <Column
            caption="Account Name"
            dataField="name"
            width={"150px"}
            editorOptions={{
              showClearButton: true,
            }}
          />
          <Column
            caption="Account Type"
            dataField="accounttype"
            width={"150px"}
            editorOptions={{
              showClearButton: true,
            }}
          />
          <Column
            caption="Parent Account Name"
            dataField="parentaccountname"
            width={"150px"}
            editorOptions={{
              showClearButton: true,
            }}
          />
          <Selection mode="single" />
          <Scrolling mode="virtual" />
          <Paging enabled={true} pageSize={10} />
        </DataGrid>
      </div>
    );
  }

  function dataGridOnSelectionChanged(e) {
    if (e.selectedRowsData.length) {
      if (e.selectedRowsData[0].name) {
        setGridBoxValue(e.selectedRowsData);
        if (gridBoxValue) {
          setFormData({
            ...formData,
            companyName: e.selectedRowsData[0].name,
            companyId: e.selectedRowsData[0].accountId,
          });
          setIsGridBoxOpened(false);
        }
      }
    }
  }

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const urlRegex = /^(ftp|http|https):\/\/[^? "]+(\?[^ "]+)?$/;
    //     if (!emailRegex.test(formData.contactEmail)) {
    //   newErrors.contactEmail = "Invalid email address";
    //   isValid = false;
    //   setStatus({
    //     display: true,
    //     text: newErrors.contactEmail,
    //     statusType: "warning",
    //   });
    // }
    if (
      formData.companyName === undefined &&
      formData.companyId === undefined
    ) {
      isValid = false;
      setStatus({
        display: true,
        text: "Account Name is mandatory",
        statusType: "warning",
      });
    } else if (!urlRegex.test(formData.loginUrl)) {
      isValid = false;
      setStatus({
        display: true,
        text: "Invalid loginUrl",
        statusType: "warning",
      });
    }
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const error = validateField(name, value);
    // Update the state for the current field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
    // Update the form data if there is no error
    if (!error) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  async function handleFormSubmit(e) {
    e.preventDefault();
    let isValid = false;
    if (formData.contactEmail) {
      isValid = validateForm();
    }
    else if (
      formData.companyName === undefined &&
      formData.companyId === undefined
    ) {
      isValid = validateForm();
    } else if (formData.loginUrl) {
      isValid = validateForm();
    }
    else {
      isValid = true;
    }
    if (isValid) {
      // alert('nnnnnnnnnnnnnnnn')
      if (isEdit && accessToken !== "null" && userInfo) {
        _updateClientData(accessToken, userInfo.userName, formData);
      } else {
        if (accessToken !== "null" && userInfo) {
          try {
            const apiResponse = await postClientData(
              accessToken,
              userInfo.userName,
              formData
            );
            if (
              apiResponse.data.data.message ===
              "Clients has been inserted successfully!!"
            ) {
              setStatus({
                display: true,
                text: "Clients added Successfully",
                statusType: "success",
              });
              setIsSidebarOpen(false);
              if (selectedTab == 1) {
                refresh();
              } else {
                _refresh();
              }
              setUpdateList(false);
              setFormData({ isScreenShotEnabled: 1 });
            } else if (
              apiResponse.data.data.message === "Clients Already Exists!!"
            ) {
              setStatus({
                display: true,
                text: "Clients already Exists",
                statusType: "warning",
              });
              setIsSidebarOpen(false);
              setUpdateList(false);
              setFormData({ isScreenShotEnabled: 1 });
            }
          } catch (error) {
            setStatus({
              display: true,
              text: `Something went wrong`,
              statusType: "warning",
            });
            setIsSidebarOpen(false);
          }
        }
      }
    } else {
      // alert('wronttttttt')
      return
    }
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validateField = (fieldName, value) => {
    if (
      fieldName === "jobsUrl" ||
      fieldName === "jdUrl" ||
      fieldName === "contactEmail"
    ) {
      if ((value ?? "").length < 1 || (value ?? "").length > 255) {
        return "Must be between 1 and 255 characters";
      }
      return "";
    } else if (fieldName === "loginUrl") {
      var urlRegex = /^(ftp|http|https):\/\/[^? "]+(\?[^ "]+)?$/;
      if ((value ?? "").length < 1 || (value ?? "").length > 1500) {
        return "Must be between 1 and 1500 characters";
      } else if (!urlRegex.test(value)) {
        return "Invalid URL";
      }
      return "";
    } else {
      if ((value ?? "").length < 1 || (value ?? "").length > 50) {
        return "Must be between 1 and 50 characters";
      }
      return "";
    }
  };

  return (
    <>
      {status.display && <Alerts status={status} sx={{ margin: "10px" }} />}
      {selectedOption === "Delete" && (
        <ViewSidebar
          // viewAccountDetails={viewAccountDetails}
          accountOptionData={accountOptionData}
          viewDetails={viewDetails}
          setViewDetails={setViewDetails}
          _cellData={_cellData}
          selectedTab={selectedTab}
          refresh={refresh}
          _refresh={_refresh}
        // handleViewDetailsSidebar={handleViewDetailsSidebar}
        />
      )}

      {selectedOption === "Audit Logs" && (
        <>
          <ClientPortalRowAudit
            cellData={cellData}
            openAudit={openAudit}
            setOpenAudit={setOpenAudit}
          />
          <ElementConfig openAudit={openAudit} setOpenAudit={setOpenAudit} />
        </>
      )}
      {view === "top" && (
        <div
          className={drawerOpener ? "add-btn" : "close-btn"}
          style={{
            display: "flex",
            width: drawerOpener ? "80%" : "90%",
            justifyContent: "space-between",
            marginLeft: drawerOpener ? "245px" : "40px",
          }}
        >
          <Stack spacing={2} direction="row">
            <Button
              sx={{ textTransform: "none" }}
              size="large"
              variant="contained"
              onClick={toggleSidebar}
            >
              Add New
            </Button>
          </Stack>
        </div>
      )}

      <Drawer
        anchor="right"
        open={isSidebarOpen}
        onClose={toggleSidebar}
        PaperProps={{ style: { width: 600 } }}
      >
        <DialogTitle
          style={{
            background: "rgb(0 140 227)",
            color: "white",
            fontSize: "20px",
          }}
          id="responsive-dialog-title"
        >
          {isEdit === true ? "Edit" : "Add"}
        </DialogTitle>
        <IconButton
          size="large"
          aria-label="close"
          onClick={handleClosetoggleSidebar}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <form onSubmit={handleFormSubmit}>
          <Box sx={{ width: "95%", marginLeft: "20px", display: "flex" }}>
            <Grid container>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box
                  title={isEdit ? "Can't Edit" : ""}
                  className="abce"
                  sx={{ flexDirection: "column", width: "95%" }}
                >
                  <span className="fieldName" style={{ marginLeft: "0px" }}>
                    Account Name<span className="asterisk">*</span>
                  </span>
                  <div
                    className="main-dropDownBox"
                    style={{ pointerEvents: isEdit ? "none" : "" }}
                  >
                    <div className="dropdown-job-selector">
                      <DropDownBox
                        style={{ background: isEdit ? "#dddddd" : "" }}
                        height="55px"
                        value={gridBoxValue}
                        opened={isGridBoxOpened}
                        valueExpr="Sno"
                        deferRendering={false}
                        displayExpr={(e) => {
                          if (gridBoxValue && gridBoxValue.length) {
                            return gridBoxValue[0].name;
                          } else {
                            return "";
                          }
                        }}
                        placeholder="Select..."
                        showClearButton={true}
                        // dataSource={accountOptionData}
                        onValueChanged={syncDataGridSelection}
                        onOptionChanged={onGridBoxOpened}
                        contentRender={dataGridRender}
                        dropDownOptions={{ closeOnValueSelect: true }}
                      />
                    </div>
                  </div>
                </Box>
              </Grid>

              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                {/* Group By field */}
                <Box
                  title={isEdit ? "Can't Edit" : ""}
                  sx={{ flexDirection: "column", width: "100%" }}
                >
                  <span className="fieldName">
                    Group By<span className="asterisk">*</span>
                  </span>
                  <Autocomplete
                    sx={{
                      margin: "5px",
                      width: "95%",
                      pointerEvents: isEdit ? "none" : "",
                      background: isEdit ? "#dddddd" : "",
                    }}
                    id="outlined-required"
                    name="groupId"
                    options={groupByDropdown}
                    onOpen={handleOpenGroup}
                    onClose={handleCloseDropGroup}
                    value={
                      groupByDropdown.find(
                        (option) => option.name === formData.groupName
                      ) || null
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          isOpenGroup || formData.groupName ? "" : "Select ..."
                        }
                        required
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setFormData({
                          ...formData,
                          groupId: value.id,
                          groupName: value.name,
                        });
                      } else {
                        setFormData({
                          ...formData,
                          groupId: null,
                          groupName: null,
                        });
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box
                  title={isEdit ? "Can't Edit" : ""}
                  sx={{ flexDirection: "column", width: "100%" }}
                >
                  <span className="fieldName">
                    ATS Name<span className="asterisk">*</span>
                  </span>
                  <Autocomplete
                    sx={{
                      margin: "5px",
                      width: "95%",
                      pointerEvents: isEdit ? "none" : "",
                      background: isEdit ? "#dddddd" : "",
                    }}
                    required
                    id="outlined-required"
                    size="large"
                    options={categoryOptionData}
                    onOpen={handleOpenAts}
                    onClose={handleCloseDropAts}
                    name="categoryid"
                    getOptionLabel={(option) => option.atsName}
                    // value={formData.atsName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          isOpenAts || formData.atsName ? "" : "Select ..."
                        }
                        required
                      />
                    )}
                    value={
                      categoryOptionData.find(
                        (option) => option.atsName === formData.atsName
                      ) || null
                    }
                    onChange={(e, value) => {
                      if (value) {
                        setFormData({
                          ...formData,
                          atsId: value.atsId,
                          atsName: value.atsName,
                        });
                      } else {
                        setFormData({
                          ...formData,
                          categoryid: null,
                        });
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    Client Name
                    <span className="asterisk">
                      * &nbsp;
                      <Tooltip
                        title={toolTipValue ? toolTipValue : ""}
                        placement="right"
                      >
                        <img src={i} alt="tooltip" />
                      </Tooltip>
                    </span>
                  </span>
                  <TextField
                    sx={{ margin: "5px", width: "95%" }}
                    required
                    id="outlined-required"
                    // label="Client Name"
                    name="clientName"
                    // defaultValue={_cellData.clientName}
                    // error={
                    //   (formData.clientName ?? "").length < 3 ||
                    //   (formData.clientName ?? "").length > 250
                    // }
                    inputProps={{
                      maxLength: 50,
                    }}
                    error={Boolean(errors.clientName)}
                    helperText={errors.clientName}
                    value={formData.clientName}
                    onChange={handleInputChange}
                  />
                </Box>
              </Grid>

              <hr
                style={{
                  borderTop: "1px solid #b2adad",
                  width: "97%",
                  margin: "8px 0 31px 0",
                }}
              />
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    Login URL<span className="asterisk">*</span>
                  </span>
                  <TextField
                    sx={{ margin: "5px", width: "95%" }}
                    required
                    id="outlined-required"
                    // label="Login URL"
                    value={formData.loginUrl}
                    onChange={handleInputChange}
                    name="loginUrl"
                    error={Boolean(errors.loginUrl)}
                    helperText={errors.loginUrl}
                    inputProps={{
                      maxLength: 1500,
                    }}
                  // onInput={(e) => {
                  //     e.target.value = e.target.value.slice(0, 200)
                  // }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    Authentication Type<span className="asterisk">*</span>
                  </span>
                  <Autocomplete
                    sx={{ margin: "5px", width: "95%" }}
                    required
                    id="outlined-required"
                    size="large"
                    options={optionsValue}
                    onOpen={handleOpen}
                    onClose={handleCloseDrop}
                    name="authenticationType"
                    value={
                      optionsValue.find(
                        (option) => option.name === formData.authenticationType
                      ) || null
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          isOpen || formData.authenticationType
                            ? ""
                            : "Select ..."
                        }
                        required
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setFormData({
                          ...formData,
                          authenticationType: value.name,
                        });
                      } else {
                        // Handle the case when value is undefined (e.g., user cleared the selection)
                        setFormData({
                          ...formData,
                          authenticationType: null,
                          // You might want to adjust this based on your data structure
                        });
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">Agency Company ID</span>
                  <TextField
                    sx={{ margin: "5px", width: "95%" }}
                    id="outlined-required"
                    // label="Agency Company Name"
                    value={formData.agencyCompanyId}
                    onChange={handleInputChange}
                    name="agencyCompanyId"
                    error={Boolean(errors.agencyCompanyId)}
                    helperText={errors.agencyCompanyId}
                    inputProps={{
                      maxLength: 50,
                    }}
                  // onInput={(e) => {
                  //     e.target.value = e.target.value.slice(0, 200)
                  // }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">Agency ID</span>
                  <TextField
                    sx={{ margin: "5px", width: "95%" }}
                    id="outlined-required"
                    // label="Agency ID"
                    value={formData.agencyId}
                    onChange={handleInputChange}
                    name="agencyId"
                    error={Boolean(errors.agencyId)}
                    helperText={errors.agencyId}
                    inputProps={{
                      maxLength: 50,
                    }}
                  // onInput={(e) => {
                  //     e.target.value = e.target.value.slice(0, 200)
                  // }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    User Name<span className="asterisk">*</span>
                  </span>
                  <TextField
                    sx={{ margin: "5px", width: "95%" }}
                    required
                    id="outlined-required"
                    // label="User Name"
                    value={formData.username}
                    onChange={handleInputChange}
                    name="username"
                    error={Boolean(errors.username)}
                    helperText={errors.username}
                    inputProps={{
                      maxLength: 50,
                    }}
                  // onInput={(e) => {
                  //     e.target.value = e.target.value.slice(0, 200)
                  // }}
                  />
                </Box>
              </Grid>

              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    Password<span className="asterisk">*</span>
                  </span>
                  <TextField
                    sx={{ margin: "5px" }}
                    required
                    id="outlined-required"
                    value={formData.password}
                    onChange={handleInputChange}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          style={{ cursor: "pointer" }}
                          position="end"
                        >
                          <IconButton onClick={handleTogglePasswordVisibility}>
                            {showPassword ? (
                              <Tooltip title="Hide" arrow>
                                <VisibilityOff />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Show" arrow>
                                <Visibility />
                              </Tooltip>
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    Job URL
                    {/* <span className="asterisk">*</span> */}
                  </span>
                  <TextField
                    sx={{ margin: "5px", width: "95%" }}
                    // required
                    id="outlined-required"
                    // label="Job URL"
                    value={formData.jobsUrl}
                    onChange={handleInputChange}
                    name="jobsUrl"
                    error={Boolean(errors.jobsUrl)}
                    helperText={errors.jobsUrl}
                    inputProps={{
                      maxLength: 255,
                    }}
                  // onInput={(e) => {
                  //     e.target.value = e.target.value.slice(0, 200)
                  // }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    Job Description URL
                    {/* <span className="asterisk">*</span> */}
                  </span>
                  <TextField
                    sx={{ margin: "5px", width: "95%" }}
                    // required
                    id="outlined-required"
                    // label="Job Description URL"
                    value={formData.jdUrl}
                    onChange={handleInputChange}
                    name="jdUrl"
                    error={Boolean(errors.jdUrl)}
                    helperText={errors.jdUrl}
                    inputProps={{
                      maxLength: 255,
                    }}
                    Id
                  // onInput={(e) => {
                  //     e.target.value = e.target.value.slice(0, 200)
                  // }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">Registered Email ID</span>
                  <TextField
                    sx={{ margin: "5px", width: "95%" }}
                    id="outlined-required"
                    // label="User Name"
                    value={formData.contactEmail}
                    onChange={handleInputChange}
                    name="contactEmail"
                    error={Boolean(errors.contactEmail)}
                    helperText={errors.contactEmail}
                    inputProps={{
                      maxLength: 255,
                    }}
                  // onInput={(e) => {
                  //     e.target.value = e.target.value.slice(0, 200)
                  // }}
                  />
                </Box>
              </Grid>
              <Grid item md={6} sx={{ display: "flex", justifyContent: "center", marginBottom: "25px", }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    Status <span className="asterisk">*</span>
                  </span>
                  <Autocomplete
                    sx={{ margin: "5px", width: "95%" }}
                    required
                    id="outlined-required"
                    size="large"
                    options={statusValue}
                    onOpen={haldleOpenActive}
                    onClose={haldleCloseActive}
                    name="isActivated"
                    value={
                      statusValue.find((option) => option.id === formData.isActivated) || null
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          isActiveOpen || formData.isActivated
                            ? ""
                            : "Select ..."
                        }
                        required
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setFormData({
                          ...formData,
                          isActivated: value.id,
                        });
                      } else {
                        setFormData({
                          ...formData,
                          isActivated: null,
                        });
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item md={12}>
                <Box sx={{ flexDirection: "column" }}>
                  <div sx={{ display: "flex", marginLeft: "10px" }}>
                    <Grid item md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="large"
                            name="isScreenShotEnabled"
                            checked={true}
                            color="default"
                          />
                        }
                      />
                      <span style={labelStyles}>
                        Screenshot <span className="asterisk">*</span>
                      </span>
                    </Grid>
                    <Grid item md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="large"
                            name="isAutoProcessingEnabled"
                            checked={
                              formData.isAutoProcessingEnabled ? true : false
                            }
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                [e.target.name]: e.target.checked ? 1 : 0,
                              });
                            }}
                          />
                        }
                      />
                      <span style={labelStyles}>
                        Auto Duplicate Check Process
                      </span>
                    </Grid>
                    <Grid item md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="large"
                            name="isCvSent"
                            checked={formData.isCvSent}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                [e.target.name]: e.target.checked ? 1 : 0,
                              });
                            }}
                          />
                        }
                      />
                      <span style={labelStyles}>CV Sent</span>
                    </Grid>
                  </div>
                </Box>
              </Grid>
              <Grid
                item
                md={12}
                xs={10.2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Button variant="contained" size="large" type="submit">
                  {isEdit === true ? "Save" : "Add"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Drawer>

      {view === "grid" && (
        <div className="row-container">
          <div
            style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {cellData && (
              <>
                <a
                  href={`${CRM_REDIRECT_URL}Account/View/?accountId=${cellData.data.companyId}`}
                  target="_blank"
                  className="hoverOption"
                >
                  <span
                    style={{ cursor: "pointer", textDecoration: "none" }}
                    // onClick={(e) => {
                    //   handleElementConfig(e, cellData.data);
                    // }}
                    title={cellData.data.companyName}
                  >
                    {cellData.data.companyName}
                  </span>
                </a>
              </>
            )}
          </div>
          <div className="cellIcon" style={{ flex: 0 }}>
            <img
              alt=""
              src={actionIcon}
              style={{ cursor: "pointer", height: "20px", width: "21px" }}
              onClick={(e) => {
                handleClick(e, cellData.rowIndex, cellData.data);
              }}
            />
          </div>
          {
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl.element}
              open={anchorEl.index === cellData.rowIndex}
              onClose={handleCloseMenu}
              PaperProps={{
                style: {
                  // maxHeight: ITEM_HEIGHT * 4.5,
                  width: "18ch",
                  fontSize: "10px",
                  marginLeft: "15px",
                  // background: 'red',
                  // left: menuPosition.x+'px!important',
                  // top: menuPosition.y+'px'
                },
              }}
              sx={{
                "& .MuiMenu-paper": {
                  left: menuPosition.x + "px!important",
                  top: menuPosition.y + "px!important",
                },
              }}
            >
              {menuItem.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === "selectedOption"}
                  onClick={() => {
                    handleMenuItemClick(option);
                    // handleCloseMenu();
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          }
        </div>
      )}
    </>
  );
}
export default ClientForm;
