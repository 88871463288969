import axios from "axios";

import * as serverContstant from "../constant/serverConstant";
import { AxiosInstance } from "../constant/httpsHandler";
import AppConstants from "../constant";

export async function get_user_authenticationLogs(
  userInfo,
  filter,
  userRole,
  take,
  skip,
  sort
) {
  let usersRole = "";
  if (
    userRole === "Global Administrator" ||
    userRole === "ClientPortal Administrator 90"
  ) {
    usersRole = "admin";
  } else {
    usersRole = "user";
  }

  const resquestBody = {
    sort: sort,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "authenticationlogs",
    filter: filter,
    username: userInfo.userName,
    roleAccess: usersRole,
  };

  return AxiosInstance.post(
    serverContstant.API_PATH +
      "/" +
      serverContstant.API_ENDPOINTS.AUTH_USER_LOGS +
      "?source=chs",
    resquestBody
  );
}

export async function get_user_activityLogs(
  userInfo,
  filter,
  userRole,
  take,
  skip,
  sort
) {
  let usersRole = "";
  if (
    userRole === "Global Administrator" ||
    userRole === "ClientPortal Administrator 90"
  ) {
    usersRole = "admin";
  } else {
    usersRole = "user";
  }
  const resquestBody = {
    sort: sort,
    // username: userInfo.userName,
    username: userInfo.userName,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "useractivitylog",
    filter: filter,
    roleAccess: usersRole,
  };
  return AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_USER_ACTIVITY}?source=chs`,
    resquestBody
  );
}
