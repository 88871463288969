import "devextreme/dist/css/dx.light.css";
import "./DataGridList.css";
import noImage from "../src/assets/noImage.png";
import "../src/PreLoader/Loaders";
// import { LoadPanel } from "devextreme-react/load-panel";
import { useCallback, useEffect, useRef, useState } from "react";
import { getProductsFromApi } from "./utils";
import { Button } from "devextreme-react/button";
import { Tabs, Tab, Paper, Box } from "@mui/material";
import DataGrid, {
  Column,
  FilterRow,
  Paging,
  // Scrolling,
  RowDragging,
  Pager,
  ColumnChooser,
  Sorting,
  Lookup,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Editing,
  Scrolling,
  StateStoring,
  Selection,
} from "devextreme-react/data-grid";

import { getClientList } from "./api/clients";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ClearFilterIcon from "../src/assets/ClearFiltericon.png";
import CustomStore from "devextreme/data/custom_store";
import { getChsCustomization, postChsCustomization } from "./api/Customization";
import { useAuthContext } from "./Context/AuthProvider";
import { Button as Button2 } from "devextreme-react/button";
import clean from "../src/assets/clean.png";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import Alerts from "./Alert/Alert";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";

const formatDateTo24Hour = (date) => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

const customStore = (setStatus, userInfo, userRole, initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const take = options.take || 50;
      const skip = options.skip || null;
      const sort = options.sort || [
        {
          selector: "updatedAt",
          desc: true,
        },
      ];
      let res;
      try {
        res = await getClientList(
          filter,
          take,
          skip,
          sort,
          userInfo.userName,
          userRole
        );
        if (!res.data.data || res.data.data.response.length === 0) {
          return {
            data: [],
            totalCount: 0,
          };
        }
        let dataRes = [];
        if (res && res.data && res.data.data && res.data.data.response) {
          const startIndex = (options.skip || 0) + 1;
          dataRes = res.data.data.response.map((item, index) => {
            let formattedDateUpdat = "";
            if (item.lastLogin) {
              const dateStringUpdated = item.lastLogin;
              const inputDate = new Date(dateStringUpdated);
              formattedDateUpdat = `${inputDate
                .getUTCDate()
                .toString()
                .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                month: "short",
              }).format(inputDate)}-${inputDate.getUTCFullYear()} ${inputDate
                .getUTCHours()
                .toString()
                .padStart(2, "0")}:${inputDate
                .getUTCMinutes()
                .toString()
                .padStart(2, "0")}:${inputDate
                .getUTCSeconds()
                .toString()
                .padStart(2, "0")}`;
            } else {
              formattedDateUpdat = "";
            }
            let updatedDate = "";
            if (item.updatedAt) {
              const dateStringUpdated = item.updatedAt;
              const inputDate = new Date(dateStringUpdated);
              updatedDate = `${inputDate
                .getUTCDate()
                .toString()
                .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                month: "short",
              }).format(inputDate)}-${inputDate.getUTCFullYear()} ${inputDate
                .getUTCHours()
                .toString()
                .padStart(2, "0")}:${inputDate
                .getUTCMinutes()
                .toString()
                .padStart(2, "0")}:${inputDate
                .getUTCSeconds()
                .toString()
                .padStart(2, "0")}`;
            } else {
              updatedDate = "";
            }

            return {
              id: startIndex + index,
              clientName: item.clientName,
              loginUrl: item.loginUrl,
              lastLogin: formattedDateUpdat,
              status: item.status,
              logoUrl: item.logoUrl,
              ATSName: item.ATSName,
              atsId: item.atsId,
              clientId: item.clientId,
              groupId: item.groupId,
              groupName: item.groupName,
              days: item.days,
              // deleted: item.deleted,
              updatedBy: item.updatedBy,
              updatedAt: updatedDate,
              createdBy: item.createdBy,
              contactEmail: item.contactEmail ? item.contactEmail : "",
            };
          });
        }
        return {
          data: dataRes,
          totalCount: res.data.data.count,
        };
      } catch (error) {
        return {
          data: [],
          totalCount: 0,
        };
      }
    },
  });

const initialList = [
  {
    columns: [
      {
        visibleIndex: 0,
        dataField: "id",
        name: "id",
        dataType: "number",
        width: "50px",
        visible: true,
      },
      {
        visibleIndex: 1,
        dataField: "clientName",
        name: "clientName",
        dataType: "string",
        width: "250px",
        visible: true,
      },
      {
        visibleIndex: 2,
        dataField: "ATSName",
        name: "ATSName",
        dataType: "string",
        width: "210px",
        visible: true,
      },
      {
        visibleIndex: 3,
        dataField: "groupName",
        name: "groupName",
        dataType: "string",
        width: "210px",
        visible: true,
      },
      {
        visibleIndex: 4,
        dataField: "status",
        name: "status",
        dataType: "string",
        width: "250px",
        visible: true,
      },
      {
        visibleIndex: 5,
        dataField: "lastLogin",
        name: "lastLogin",
        dataType: "date",
        width: "250px",
        visible: true,
      },
      {
        visibleIndex: 6,
        dataField: "days",
        name: "days",
        dataType: "number",
        width: "400px",
        visible: true,
      },
    ],
    allowedPageSizes: [50, 100, 250],
    filterPanel: { filterEnabled: true },
    filterValue: null,
    searchText: "",
    pageIndex: 0,
    pageSize: 50,
  },
];
const onExporting = (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Main sheet");
  // Obtain selected rows data
  const selectedRows = e.component.getSelectedRowsData();
  // Export only selected rows
  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
    customizeExportData: (columns, rows) => {
      return {
        columns,
        rows: selectedRows,
      };
    },
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "SelectedDataGrid.xlsx"
      );
    });
  });
};
function DataGridList({ drawerOpener }) {
  const allowedPageSizes = [50, 100, 250];
  const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";
  const [tableLoaderErrInfo, setTableLoaderErrInfo] = useState("Loading");
  const dataGridRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState("0");
  const [clientDatagridData, setClientDatagridData] = useState([]);
  const { userInfo, userRole } = useAuthContext();
  const [clearCustom, setClearCustom] = useState(false);
  const [clearCustomizationPopUp, setClearCustomizationPopUp] = useState(false);
  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  useEffect(() => {
    if (userRole) {
      setClientDatagridData(customStore(setStatus, userInfo, userRole));
    }
  }, [selectedTab, userInfo, userRole]);

  function getStatusColor(data) {
    if (data != undefined) {
      let color = "";
      if (data.status === "Active") {
        return (color = "#32CD32");
      } else {
        return (color = "#D00000");
      }
    }
  }
  const onExporting = (e) => {
    try {
      if (!clientDatagridData || clientDatagridData.length === 0) {
        return;
      }

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "MyClient.xlsx"
          );
        });
      });
    } catch (error) {}
  };

  function setStatuss(e) {
    return <div style={{ color: getStatusColor(e.data) }}>{e.value}</div>;
  }

  const saveState = useCallback(
    async (state) => {
      if (userInfo.userName) {
        const res = await postChsCustomization(
          userInfo.userName,
          "MyClient",
          state
        );
      }
    },
    [userInfo]
  );

  const loadState = useCallback(async () => {
    if (userInfo.userName) {
      const res = await getChsCustomization(userInfo.userName, "MyClient");
      return res.data.data;
    }
  }, [userInfo, clearCustom]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCellPrepared = (e) => {
    if (e.cellElement.ariaLabel === "Column Portal Name") {
      e.cellElement.style.backgroundColor = "#008ce3";
    }
    if (
      e.column.dataField === "groupName" &&
      e.rowType === "data" &&
      e.data.groupName === "Candidate Portal"
    ) {
      e.cellElement.style.backgroundColor = "#B7E1FC";
    } else if (
      e.column.dataField === "groupName" &&
      e.rowType === "data" &&
      (e.data.groupName.toLowerCase().includes("back office") ||
        e.data.groupName.toLowerCase().includes("backoffice"))
    ) {
      e.cellElement.style.backgroundColor = "#6EADD4";
    }
  };
  const clearFilters = (e) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.clearFilter();
      dataGridRef.current.instance.clearSelection();
    }
  };

  const refresh = (e) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh();
    }
  };
  const clearCustomizationDataGrid = () => {
    const dataGridInstance = dataGridRef.current.instance;
    const columns = dataGridInstance.getVisibleColumns();
    columns.forEach((column) => {
      column.visible = true;
      column.showInColumnChooser = true;
    });
    dataGridInstance.option("columns", columns);
    dataGridInstance.clearFilter();
    dataGridInstance.clearSelection();
    setClearCustomizationPopUp(false);
  };

  const customChanges = {
    icon: "undo",
    text: "Clear",
    type: "default",
    onClick: () => clearCustomizationDataGrid(),
  };
  const cancelCustomchanges = {
    text: "Close",
    icon: "remove",
    type: "danger",
    onClick: () => {
      setClearCustomizationPopUp(false);
    },
  };

  return (
    <>
      {status.display && <Alerts status={status} sx={{ margin: "10px" }} />}
      <Popup
        visible={clearCustomizationPopUp}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        container="body"
        width={500}
        height={200}
        title="Alert"
      >
        <Position my="center" of={"main_dataGridClass"} collision="fit" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={customChanges}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelCustomchanges}
        />
        <p>Your custom changes made by you on grid will be removed..</p>
      </Popup>
      <TabContext value={selectedTab}>
        <Box sx={{ width: "100%", marginTop: "50px", height: "15px" }}>
          <Paper square>
            <TabList
              onChange={handleTabChange}
              sx={{ marginLeft: drawerOpener ? "240px" : "60px" }}
            >
              <Tab
                sx={{
                  textTransform: "none",
                }}
                className="tab-text-style"
                label="My Client"
                value="0"
              />
            </TabList>
          </Paper>
        </Box>
        <TabPanel value="0">
          <div
            className="main-div"
            style={{
              // height: "85vh",
              // width: "100%",
              marginTop: "28px",
              marginLeft: drawerOpener ? "255px" : "38px",
              width: drawerOpener ? "86%" : "99%",
            }}
          >
            <div className="_pull-right">
              <div className="legendparent">
                <span className="legendLable">
                  <span className="redspan1"></span>
                  My Client
                </span>
              </div>
              <div className="legendparent">
                <span className="legendLable">
                  <span className="redspan2"></span>
                  Back office
                </span>
              </div>
            </div>
            <hr className="line-datagrid" />
            <DataGrid
              dataSource={clientDatagridData}
              keyExpr="id"
              key="index"
              ref={dataGridRef}
              columnResizingMode="widget"
              height="90%"
              width="100%"
              showRowLines={true}
              showColumnLines={true}
              hoverStateEnabled
              showBorders={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              columnMinWidth={50}
              loadPanel={{ enabled: true }}
              onCellPrepared={handleCellPrepared}
              filterSyncEnabled={true}
              remoteOperations={true}
              onExporting={onExporting}
              noDataText={"No record found"}
            >
              <Selection
                mode="multiple"
                showCheckBoxesMode="always"
                selectAllMode="page"
                // showSelectionControls={true}
                selectByRowClick={false}
              />
              <Toolbar>
                <Item location="after">
                  <Button2 hint="Refresh" icon="refresh" onClick={refresh} />
                </Item>
                <Item>
                  <img
                    title="Clear Filter"
                    className="clean"
                    src={ClearFilterIcon}
                    onClick={() => {
                      const isFilterApplied =
                        dataGridRef.current.instance.getCombinedFilter();
                      if (isFilterApplied != undefined) {
                      }
                      clearFilters();
                    }}
                  ></img>
                </Item>

                <Item name="columnChooserButton" />
                <Item name="exportButton" />
                <Item>
                  <img
                    title="Clear Customization"
                    className="clean"
                    src={clean}
                    onClick={() => {
                      setClearCustomizationPopUp(true);
                    }}
                  ></img>
                </Item>
              </Toolbar>
              <Scrolling columnRenderingMode="virtual" />
              <ColumnChooser enabled={true} />
              <Export enabled={true} allowExportSelectedData={true} />
              <ColumnFixing enabled={true} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
                showInfo={true}
                visible={true}
              />
              <Paging defaultPageSize={50} enabled={true} />
              <FilterRow visible={true} />
              <Export enabled={true} allowExportSelectedData={false} />
              <StateStoring
                enabled={true}
                type="custom"
                customLoad={loadState}
                customSave={saveState}
              />
              <Column
                allowSorting={false}
                width="50px"
                alignment="left"
                caption="S.No"
                dataField="id"
                allowReordering={false}
              />
              <Column
                cellRender={(cellData) => {
                  let redirectUrl;
                  if (cellData.data.loginUrl) {
                    let questionMarkIndex = cellData.data.loginUrl.indexOf("?");
                    if (questionMarkIndex !== -1) {
                      redirectUrl =
                        cellData.data.loginUrl +
                        "&clientId=" +
                        cellData.data.clientId;
                    } else {
                      redirectUrl =
                        cellData.data.loginUrl +
                        "?clientId=" +
                        cellData.data.clientId;
                      // +
                      // "?clientId=" +
                      // portalClientId;
                    }
                  }

                  return (
                    <>
                      <a
                        title={
                          cellData.data.status === "Active"
                            ? "Active Client"
                            : "In-Active Client"
                        }
                        href={cellData.data.loginUrl ? redirectUrl : "#"}
                        target={
                          cellData.data.status === "Active" ? "_blank" : ""
                        }
                        rel="noopener noreferrer"
                      >
                        <img
                          src={cellData.data.logoUrl}
                          width="40px"
                          height="40px"
                          style={{ marginLeft: "-5px" }}
                          onError={(e) => {
                            e.target.setAttribute("src", noImage);
                          }}
                        />
                        &nbsp; &nbsp;{cellData.data.clientName}
                      </a>
                    </>
                  );
                }}
                allowReordering={true}
                width="250px"
                caption="Client Name"
                dataField="clientName"
                editorOptions={{
                  showClearButton: true,
                }}
              />

              <Column
                width="210px"
                caption="Category"
                dataField="ATSName"
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column
                width="210px"
                caption="Layout"
                dataField="groupName"
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column
                alignment="center"
                allowReordering={true}
                width="250px"
                caption="Status"
                dataField="status"
                cellRender={setStatuss}
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column
                allowReordering={true}
                width="250px"
                caption="Last Login"
                dataField="lastLogin"
                dataType="date"
                editorOptions={{
                  showClearButton: true,
                }}
                format={filterDateFormat}
              />
              <Column
                allowReordering={true}
                width="400px"
                caption="Days"
                alignment="left"
                dataField="days"
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column dataField="loginUrl" visible={false} />
              <Column dataField="logoUrl" visible={false} />
              {/* <Column dataField="deleted" visible={false} /> */}
              <Column dataField="contactEmail" visible={false} />
              <Column dataField="createdBy" visible={false} />
              <Column dataField="updatedBy" visible={false} />
              <Column
                dataField="updatedAt"
                visible={false}
                dataType="date"
                editorOptions={{
                  showClearButton: true,
                }}
                format={filterDateFormat}
              />
            </DataGrid>
          </div>
        </TabPanel>
      </TabContext>
    </>
  );
}
export default DataGridList;
