import "./App.css";
import "./index.css";
import { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import Drawer from "./AppDrawer/Drawer";
import { BrowserRouter } from "react-router-dom";

import { useAuthContext } from "./Context/AuthProvider";

function App() {
  const [drawerOpener, setDrawerOpener] = useState(false);

  const { accessToken } = useAuthContext();

  return (
    <div className="App">
      <div id="main-wrapper" className="full-height homepage">
        <BrowserRouter>
          {accessToken !== "null" && (
            <>
              <Drawer
                drawerOpener={drawerOpener}
                setDrawerOpener={setDrawerOpener}
              />
              <Dashboard
                drawerOpener={drawerOpener}
                setDrawerOpener={setDrawerOpener}
              />
            </>
          )}
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
