import axios from "axios";
import AppConstants from ".";

// const authToken = document.cookie;

// Function to get the value of a specific cookie
export function getCookie(cookieName) {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var cookieParts = cookie.split("=");
    var name = cookieParts[0];
    var value = cookieParts[1];
    if (name === cookieName) {
      return decodeURIComponent(value);
    }
  }
  return null;
}

export const AxiosInstance = createAxiosInstance("API_PATH");
export const AxiosInstanceCDS = createAxiosInstance("API_CDS");

function craeteInterceptor(instace) {
  var authToken = getCookie("_at");

  instace.interceptors.request.use(
    function (config) {
      // config.headers['Authorization'] = `Bearer ${authToken}`;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}
craeteInterceptor(AxiosInstance);
craeteInterceptor(AxiosInstanceCDS);

function createAxiosInstance(path) {
  var authToken = getCookie("_at");
  return axios.create({
    baseURL: AppConstants.serverConstants[path],
    timeout: -1,
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
}
