import React from "react";
import "./Footer.css";

function Footer(props) {
  return (
    <>
      <footer className="footer main-footer static-footer low-height-footer">
        {/* <div className="footer-child">
          <div className="date"> */}
        <p> &copy;{new Date().getFullYear()} Hitech Client Portal </p>
        {/* </div>
        </div> */}
      </footer>
    </>
  );
}

export default Footer;
