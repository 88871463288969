import axios from "axios";
import {
  REFRESH_TOKEN_CUP,
  REFRESH_TOKEN_API_KEY,
  API_ENDPOINTS,
  CLIENT_ID,
  REDIRECT_URI,
  SIGNIN_DEV,
} from "../constant/serverConstant";

export const GetToken = async (code) => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const body = `grant_type=authorization_code&client_id=${CLIENT_ID}&code=${code}&redirect_uri=${REDIRECT_URI}/`;

  return axios.post(`${SIGNIN_DEV}/${API_ENDPOINTS.SIGNIN_DEV}`, body, {
    headers: headers,
  });
};

export const GetNewAccessToken = async (refreshToken) => {
  const body = `grant_type=refresh_token&client_id=${CLIENT_ID}&refresh_token=${refreshToken}`;

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  return axios.post(`${SIGNIN_DEV}/${API_ENDPOINTS.SIGNIN_DEV}`, body, {
    headers: headers,
  });
};

export const GetNewRefreshTokenCup = async (unicode) => {
  const body = JSON.stringify({
    data: { query: { term: { "code.keyword": { value: unicode } } } },
    type: "GET",
  });
  // const headers = {
  //     "Content-Type": "application/x-www-form-urlencoded"
  // }

  const headers = {
    "content-type": "application/json",
    "X-API-KEY": `${REFRESH_TOKEN_API_KEY}`,
  };

  return axios.post(`${REFRESH_TOKEN_CUP}`, body, { headers: headers });
};

export const GetNewRefreshTokenCupPost = async (unicode, refreshToken) => {
  const body = JSON.stringify({
    type: "POST",
    data: {
      IsAuthenticated: true,
      code: unicode,
      token: refreshToken,
      dateCreated: new Date().toISOString(),
      dateUpdated: new Date().toISOString(),
    },
  });

  // const headers = {
  //     "Content-Type": "application/x-www-form-urlencoded"
  // }

  const headers = {
    "content-type": "application/json",
    "X-API-KEY": `${REFRESH_TOKEN_API_KEY}`,
  };

  return axios.post(`${REFRESH_TOKEN_CUP}`, body, { headers: headers });
};
