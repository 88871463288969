import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DropDownBox from "devextreme-react/drop-down-box";
import "devextreme/dist/css/dx.light.css";
import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import actionIcon from "../../assets/actionIcon.png";

import "../ClientPortalList/ClientPortalList.css";
import viewRowDetails from "../../assets/viewRowDetails.png";
import ViewAccessRowData from "./ViewAccessRowData";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";
import viewImg from "../../assets/viewImg.png";

import { deleteAtsAutoLoginData } from "../../api/AdminForm";
import DataGrid, {
  Column,
  FilterRow,
  Paging,
  // Scrolling,
  RowDragging,
  Pager,
  ColumnChooser,
  Sorting,
  Lookup,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Editing,
  Scrolling,
  StateStoring,
  Selection,
} from "devextreme-react/data-grid";
import { useAuthContext } from "../../Context/AuthProvider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DeleteAccessControlData } from "../../api/AccessControlApi/AccessControlApi";
import {
  categoryOption,
  postAtsLogingTag,
  EditAtsLogingTag,
} from "../../api/AdminForm";
import Alerts from "../../Alert/Alert";
import { esES } from "@mui/x-data-grid";

const menuItem = ["Edit", "Delete", "Audit Log"];
const atsNames = ["Workday", "Teleo", "Success Fector"];
const InputElementTypes = ["Button", "Text", "Checkbox"];
const databaseFields = [
  "User Name",
  "Password",
  "Agency ID",
  "Company ID",
  "Button",
  "OTP Field",
  "OTP Email",
  "Job Filter",
  "Job Search"
];
const buttonIntent = ["Login", "Next", "Submit", "OTP", "otpContinue","Search"];
const attributes = ["ID", "Class", "Name"];
const formInputCategoryLabelsText = {
  Button: "Button Intent",
  Text: "Database Field",
  Checkbox: "Database Field",
};
const ITEM_HEIGHT = 30;

function EditATSTags({
  cellData,
  drawerOpener,
  isSidebarOpens,
  toggleSidebars,
  view,
  refresh,
}) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [gridBoxValue, setGridBoxValue] = useState([]);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [viewDetails, setViewDetails] = useState(false);
  const [cellDetails, setCellDetails] = useState({});
  const [_cellData, setCellData] = useState({});
  const [ViewAccessRowDataDialog, setViewAccessRowDataDialog] = useState(false);
  const [portalDataGrid, setPortalDataGrid] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [anchorEl, setAnchorEl] = useState({ element: null, index: -1 });
  const [isAuditLogOpen, setIsAuditLogOpen] = useState(false);
  const [categoryOptionData, setCategoryOptionData] = useState([]);
  const [isOpenGroup, setIsOpenGroup] = useState(false);
  const [isElement, setisElement] = useState(false);
  const [isDynamicOpen, setisDynamicOpen] = useState(false);
  const [isOpenAttribute, setisOpenAttribute] = useState(false);

  const [AtsformData, setAtsformData] = useState({
    atsId: "",
    dbField: "",
    inputElementType: "",
    attribute: "",
    attributeValue: "",
    createdBy: "",
    updatedBy: "",
    deleted: 0,
  });
  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  const [AddAts, setAddAts] = useState(false);
  const [InputElementTypesDynamicData, setInputElementTypesDynamicData] =
    useState([]);
  const [formInputCategoryLabels, setFormInputCategoryLabels] = useState(
    formInputCategoryLabelsText.Text
  );

  const { userInfo } = useAuthContext();

  const AtsformDataSmallCase = {
    "User Name": "username",
    Password: "password",
    "Agency ID": "agencyId",
    "Company ID": "companyId",
    Button: "button",
    Login: "login",
    Next: "next",
    Search:"search",
    Submit: "submit",
    OTP: "otp",
    "OTP Field": "otpField",
    "OTP Email": "graphEmailAddress",
    otpContinue: "otpContinue",
    "Job Filter": "jobFilter",
    "Job Search" : "jobSearch"

  };

  // const dbFieldData = {
  //   "User Name": "username",
  //   Password: "password",
  //   "Agency ID": "agencyId",
  //   "Company ID": "companyId",

  // };

  // const { accessToken } = useAuthContext();
  const drawerWidth = 600;
  const open = Boolean(anchorEl.element);

  const handleView = (e, cellData) => {
    setCellDetails(cellData.data);
    setViewAccessRowDataDialog(!ViewAccessRowDataDialog);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setSelectedOption(null);
    setAnchorEl({ element: null, index: -1 });
  };

  const handleopenAdd = () => {
    setAddAts(!AddAts);
    setAtsformData({
      atsId: "",
      dbField: "",
      inputElementType: "",
      attribute: "",
      attributeValue: "",
    });
  };

  const handleClick = (event, index, cellData) => {
    setCellData(cellData);
    setAtsformData({
      atsName: cellData.atsName ? cellData.atsName : "",
      atsId: cellData.atsId ? cellData.atsId : "",
      attribute: cellData.attribute ? cellData.attribute : "",
      attributeValue: cellData.attributeValue ? cellData.attributeValue : "",
      createdBy: cellData.createdBy ? cellData.createdBy : "",
      dbField: cellData.dbField ? cellData.dbField : "",
      buttonIntent: cellData.buttonIntent ? cellData.buttonIntent : "",
      inputElementType: cellData.inputElementType
        ? cellData.inputElementType
        : "",
    });
    const { pageX, pageY } = event;
    const _pageY = pageY - document.documentElement.scrollTop;
    setMenuPosition({ x: pageX, y: _pageY });
    setAnchorEl({ element: event.currentTarget, index });
  };
  useEffect(() => {
    if (selectedOption === "Edit") {
      setIsSidebarOpen(true);
      setSelectedOption(null);
      setAnchorEl({ element: null, index: -1 });
    } else if (selectedOption === "Audit Log") {
    }
  }, [selectedOption]);
  const handleCloseMenu = () => {
    setAnchorEl({ element: null, index: -1 });
  };

  const handleMenuItemClick = (option, cellData) => {
    setSelectedOption(option);
    setViewDetails(!viewDetails);
    setAnchorEl({ element: null, index: -1 });
    if (option === "Audit Log") {
      setIsAuditLogOpen(true);
    }
  };

  const handleClose = () => {
    setViewDetails(false);
    setAtsformData({});
  };

  // For delete data
  async function handleDelete(userInfo, cellData) {
    let payload;
    if (cellData.data.dbFieldActual !== null) {
      payload = {
        id: cellData.data.elementId,
        deleted: 1,
        updatedBy: userInfo.userName,
        dbField: cellData.data.dbField,
        atsId: cellData.data.atsId,
      };
    } else {
      payload = {
        id: cellData.data.elementId,
        deleted: 1,
        updatedBy: userInfo.userName,
        buttonIntent: cellData.data.buttonIntent,
        atsId: cellData.data.atsId,
      };
    }
    try {
      const res = await deleteAtsAutoLoginData(payload);
      if (res.data.status === "succeed") {
        if (res.data.data.message === "Ats Tag Updated Successfully!!") {
          setStatus({
            display: true,
            text: "Record deleted successfully",
            statusType: "success",
          });
          setViewDetails(false);
          refresh();
        }
      } else {
        setStatus({
          display: true,
          text: "Please contact to administrator",
          statusType: "warning",
        });
        setViewDetails(false);
        refresh();
      }
    } catch (error) {
      setStatus({
        display: true,
        text: `Something went wrong`,
        statusType: "warning",
      });
      setViewDetails(false);
    }
    // }
  }

  useEffect(() => {
    if (!categoryOptionData.length && AddAts) {
      categoryDropdown();
    }
  }, [AddAts]);
  useEffect(() => {
    if (!categoryOptionData.length && isSidebarOpen) {
      categoryDropdown();
    }
  }, [isSidebarOpen]);
  // For ATS Name data
  async function categoryDropdown() {
    try {
      const categoryData = await categoryOption();
      if (categoryData) {
        const filterCategory = categoryData.data.data.response.map(
          (item, index) => {
            return item;
          }
        );
        if (categoryData.status === "succeed" || categoryData.status === 200) {
          if (categoryData.data != null) {
            setCategoryOptionData(filterCategory);
          }
        }
      }
    } catch (error) {}
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAtsformData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputEdit = (event) => {
    const { name, value } = event.target;
    setAtsformData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // For add new data

  async function handleFormSubmit(e) {
    e.preventDefault();
    try {
      if (
        (!AtsformData.atsId ||
          !AtsformData.inputElementType ||
          !AtsformData.attribute ||
          !AtsformData.attributeValue) &&
        (!AtsformData.dbField || !AtsformData.buttonIntent)
      ) {
        setStatus({
          display: true,
          text: "All Fields are required",
          statusType: "warning",
        });
      } else {
        const apiResponse = await postAtsLogingTag(userInfo, AtsformData);
        if (apiResponse.data.data.message === "Ats Tag Posted Successfully!!") {
          refresh();
          setStatus({
            display: true,
            text: "ATS Tag Posted Successfully!!",
            statusType: "success",
          });
          setAtsformData({
            atsId: "",
            dbField: "",
            inputElementType: "",
            attribute: "",
            attributeValue: "",
          });
          setAddAts(false);
        } else if (apiResponse.data.data.message === "Data Already Exist!!") {
          refresh();
          setStatus({
            display: true,
            text: "Data Already Exist",
            statusType: "warning",
          });
          setAtsformData({
            atsId: "",
            dbField: "",
            inputElementType: "",
            attribute: "",
            attributeValue: "",
          });
          setAddAts(false);
        } else if (
          apiResponse.data.data.message ===
          "Atleast One Client must be Exist in ATS!!"
        ) {
          refresh();
          setStatus({
            display: true,
            text: "Atleast One Client must be Exist in ATS!!",
            statusType: "warning",
          });
          setAtsformData({
            atsId: "",
            dbField: "",
            inputElementType: "",
            attribute: "",
            attributeValue: "",
          });
          setAddAts(false);
        }
      }
    } catch (error) {
      setStatus({
        display: true,
        text: `Something went wrong`,
        statusType: "warning",
      });
    }
    // }
  }

  // For edit data

  async function handleEditAts(e) {
    e.preventDefault();
    try {
      if (
        (!AtsformData.atsId ||
          !AtsformData.inputElementType ||
          !AtsformData.attribute ||
          !AtsformData.attributeValue) &&
        (!AtsformData.dbField || !AtsformData.buttonIntent)
      ) {
        setStatus({
          display: true,
          text: "All Fields are required",
          statusType: "warning",
        });
      } else {
        const apiResponse = await EditAtsLogingTag(
          userInfo,
          AtsformData,
          cellData
        );

        if (
          apiResponse.data.data.message === "Ats Tag Updated Successfully!!"
        ) {
          refresh();
          setStatus({
            display: true,
            text: "ATS Tag Edited Successfully!!",
            statusType: "success",
          });
          setIsSidebarOpen(false);
        }
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (AtsformData.inputElementType === "Button") {
      setFormInputCategoryLabels(
        formInputCategoryLabelsText[AtsformData.inputElementType]
      );
      setInputElementTypesDynamicData(buttonIntent);
      setAtsformData({ ...AtsformData, dbField: null });
    } else if (AtsformData.inputElementType === "Text") {
      setFormInputCategoryLabels(
        formInputCategoryLabelsText[AtsformData.inputElementType]
      );
      setInputElementTypesDynamicData(databaseFields);
      setAtsformData({ ...AtsformData, buttonIntent: null });
    } else if (AtsformData.inputElementType === "CheckBox") {
      setFormInputCategoryLabels("Database Field");
      setInputElementTypesDynamicData(databaseFields);
      setAtsformData({ ...AtsformData, buttonIntent: null });
    }
    setAtsformData((prevState) => ({ ...prevState }));
  }, [AtsformData.inputElementType]);

  // const handleOpenAts = () => {
  //   setIsOpenAts(true);
  // };
  const handleOpenGroup = () => {
    setIsOpenGroup(true);
  };
  const handleCloseDropGroup = () => {
    setIsOpenGroup(false);
  };

  const handleOpenElementType = () => {
    setisElement(true);
  };
  const handleClosedElementType = () => {
    setisElement(false);
  };

  const handleOpenDynamic = () => {
    setisDynamicOpen(true);
  };
  const handleClosedDynamic = () => {
    setisDynamicOpen(false);
  };

  const handleOpenAttribute = () => {
    setisOpenAttribute(true);
  };
  const handleCloseAttribute = () => {
    setisOpenAttribute(false);
  };

  return (
    <>
      {status.display && <Alerts status={status} sx={{ margin: "10px" }} />}

      <ViewAccessRowData
        isAuditLogOpen={isAuditLogOpen}
        setIsAuditLogOpen={setIsAuditLogOpen}
        ViewAccessRowDataDialog={ViewAccessRowDataDialog}
        setViewAccessRowDataDialog={setViewAccessRowDataDialog}
        cellDetails={cellData}
      />

      {/* For Add New Data */}
      <Drawer
        anchor="right"
        open={AddAts}
        onClose={handleopenAdd}
        PaperProps={{ style: { width: 600 } }}
      >
        <DialogTitle
          style={{
            background: "rgb(0 140 227)",
            color: "white",
            fontSize: "20px",
          }}
          id="responsive-dialog-title"
        >
          Add
        </DialogTitle>
        <IconButton
          size="large"
          aria-label="close"
          onClick={() => setAddAts(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          {" "}
          <CloseIcon fontSize="large" />
        </IconButton>
        <form onSubmit={handleFormSubmit}>
          <Box sx={{ width: "95%", marginLeft: "20px", display: "flex" }}>
            <Grid container>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                {/* Group By field */}
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    ATS Name<span className="asterisk">*</span>
                  </span>
                  <Autocomplete
                    sx={{ margin: "5px", width: "95%" }}
                    onOpen={handleOpenGroup}
                    onClose={handleCloseDropGroup}
                    required
                    id="atsName"
                    name="atsId"
                    options={categoryOptionData}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          isOpenGroup || AtsformData.atsId ? "" : "Select ..."
                        }
                        variant="outlined"
                      />
                    )}
                    // value={AtsformData.atsName}
                    getOptionLabel={(option) => option.atsName}
                    onChange={(e, value) => {
                      if (value) {
                        setAtsformData({ ...AtsformData, atsId: value.atsId });
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    Input Element Type<span className="asterisk">*</span>
                  </span>
                  <Autocomplete
                    sx={{ margin: "5px", width: "95%" }}
                    required
                    id="outlined-required"
                    name="type"
                    onOpen={handleOpenElementType}
                    onClose={handleClosedElementType}
                    options={InputElementTypes}
                    // getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          isElement || AtsformData.inputElementType
                            ? ""
                            : "Select ..."
                        }
                        variant="outlined"
                      />
                    )}
                    value={AtsformData.inputElementType}
                    onChange={(event, value) =>
                      setAtsformData({
                        ...AtsformData,
                        inputElementType: value,
                      })
                    }
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                {/* Client Name field */}
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    {formInputCategoryLabels}
                    <span className="asterisk">*</span>
                  </span>

                  <Autocomplete
                    sx={{ margin: "5px", width: "95%" }}
                    required
                    id="outlined-required"
                    name="type"
                    options={InputElementTypesDynamicData}
                    onOpen={handleOpenDynamic}
                    onClose={handleClosedDynamic}
                    // getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          isDynamicOpen ||
                          AtsformData.dbField ||
                          AtsformData.buttonIntent
                            ? ""
                            : "Select ..."
                        }
                        variant="outlined"
                      />
                    )}
                    value={
                      AtsformData.dbField !== undefined &&
                      AtsformData.dbField !== null
                        ? AtsformData.dbField
                        : AtsformData.buttonIntent !== undefined &&
                          AtsformData.buttonIntent !== null
                        ? AtsformData.buttonIntent
                        : ""
                    }
                    onChange={(event, value) => {
                      if (formInputCategoryLabels === "Button Intent") {
                        setAtsformData({
                          ...AtsformData,
                          buttonIntent: AtsformDataSmallCase[value],
                        });
                      } else {
                        setAtsformData({
                          ...AtsformData,
                          dbField: AtsformDataSmallCase[value],
                        });
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    Attribute<span className="asterisk">*</span>
                  </span>
                  <Autocomplete
                    sx={{ margin: "5px", width: "95%" }}
                    required
                    onOpen={handleOpenAttribute}
                    onClose={handleCloseAttribute}
                    id="outlined-required"
                    name="type"
                    options={attributes}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          isOpenAttribute || AtsformData.attribute
                            ? ""
                            : "Select ..."
                        }
                        variant="outlined"
                      />
                    )}
                    value={AtsformData.attribute}
                    onChange={(event, value) =>
                      setAtsformData({ ...AtsformData, attribute: value })
                    }
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    Attribute Value<span className="asterisk">*</span>
                  </span>
                  <TextField
                    sx={{ margin: "5px", width: "95%" }}
                    id="outlined-required"
                    name="attributeValue"
                    value={AtsformData.attributeValue}
                    onChange={handleInputChange}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={12}
                xs={10.2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <Button variant="contained" size="large" type="submit">
                  Add
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Drawer>
      <Drawer
        anchor="right"
        open={isSidebarOpen}
        onClose={toggleSidebar}
        PaperProps={{ style: { width: 600 } }}
      >
        <DialogTitle
          style={{
            background: "rgb(0 140 227)",
            color: "white",
            fontSize: "20px",
          }}
          id="responsive-dialog-title"
        >
          Edit
        </DialogTitle>
        <IconButton
          size="large"
          aria-label="close"
          onClick={() => setIsSidebarOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <form onSubmit={handleEditAts}>
          <Box sx={{ width: "95%", marginLeft: "20px", display: "flex" }}>
            <Grid container>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box
                  title={isSidebarOpen ? "Can't Edit" : ""}
                  sx={{ flexDirection: "column", width: "100%" }}
                >
                  <span className="fieldName">
                    ATS Name<span className="asterisk">*</span>
                  </span>
                  <Autocomplete
                    sx={{
                      margin: "5px",
                      width: "95%",
                      pointerEvents: isSidebarOpen ? "none" : "",
                      background: isSidebarOpen ? "#dddddd" : "",
                    }}
                    required
                    id="atsName"
                    // label="Select"
                    name="atsId"
                    options={categoryOptionData}
                    // onOpen={handleOpenGroup}
                    // onClose={handleCloseDropGroup}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          isOpenGroup || AtsformData.atsId ? "" : "Select ..."
                        }
                        variant="outlined"
                        required
                      />
                    )}
                    // label={
                    //   isOpenGroup ? "" : "Select ..."
                    // }
                    value={AtsformData} // Set the entire AtsformData as the value
                    getOptionLabel={(option) => option.atsName}
                    onChange={(e, value) => {
                      if (value) {
                        setAtsformData({ ...AtsformData, atsId: value.atsId });
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box
                  title={isSidebarOpen ? "Can't Edit" : ""}
                  sx={{ flexDirection: "column", width: "100%" }}
                >
                  <span className="fieldName">
                    Input Element Type<span className="asterisk">*</span>
                  </span>
                  <Autocomplete
                    sx={{
                      margin: "5px",
                      width: "95%",
                      pointerEvents: isSidebarOpen ? "none" : "",
                      background: isSidebarOpen ? "#dddddd" : "",
                    }}
                    required
                    id="outlined-required"
                    label="Select"
                    name="type"
                    options={InputElementTypes}
                    // getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label={
                          isElement || AtsformData.inputElementType
                            ? ""
                            : "Select ..."
                        }
                        variant="outlined"
                      />
                    )}
                    value={AtsformData.inputElementType}
                    onChange={(event, value) =>
                      setAtsformData({
                        ...AtsformData,
                        inputElementType: value,
                      })
                    }
                  />
                </Box>
              </Grid>

              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                {/* Client Name field */}
                <Box
                  title={isSidebarOpen ? "Can't Edit" : ""}
                  sx={{ flexDirection: "column", width: "100%" }}
                >
                  <span className="fieldName">
                    {formInputCategoryLabels}
                    <span className="asterisk">*</span>
                  </span>

                  <Autocomplete
                    sx={{
                      margin: "5px",
                      width: "95%",
                      pointerEvents: isSidebarOpen ? "none" : "",
                      background: isSidebarOpen ? "#dddddd" : "",
                    }}
                    required
                    id="outlined-required"
                    label={
                      isDynamicOpen ||
                      AtsformData.dbField ||
                      AtsformData.buttonIntent
                        ? ""
                        : "Select ..."
                    }
                    name="type"
                    options={InputElementTypesDynamicData}
                    // getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          isDynamicOpen ||
                          AtsformData.dbField ||
                          AtsformData.buttonIntent
                            ? ""
                            : "Select ..."
                        }
                        variant="outlined"
                        required
                      />
                    )}
                    value={
                      AtsformData.dbField !== undefined &&
                      AtsformData.dbField !== null
                        ? AtsformData.dbField
                        : AtsformData.buttonIntent !== undefined &&
                          AtsformData.buttonIntent !== null
                        ? AtsformData.buttonIntent
                        : ""
                    }
                    onChange={(event, value) => {
                      if (formInputCategoryLabels === "Button Intent") {
                        setAtsformData({
                          ...AtsformData,
                          buttonIntent: AtsformDataSmallCase[value],
                        });
                      } else {
                        setAtsformData({
                          ...AtsformData,
                          dbField: AtsformDataSmallCase[value],
                        });
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    Attribute<span className="asterisk">*</span>
                  </span>
                  <Autocomplete
                    sx={{ margin: "5px", width: "95%" }}
                    required
                    id="outlined-required"
                    label="Select"
                    name="type"
                    options={attributes}
                    // defaultValue={cellData.data.attribute}
                    // getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          isOpenAttribute || AtsformData.attribute
                            ? ""
                            : "Select ..."
                        }
                        variant="outlined"
                        required
                      />
                    )}
                    value={AtsformData.attribute}
                    onChange={(event, value) =>
                      setAtsformData({ ...AtsformData, attribute: value })
                    }
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    Attribute Value<span className="asterisk">*</span>
                  </span>
                  <TextField
                    sx={{ margin: "5px", width: "95%" }}
                    id="outlined-required"
                    required
                    // label="Agency Company Name"
                    // value={formData.agencyCompanyId}
                    // onChange={handleInputChange}
                    name="attributeValue"
                    // onInput={(e) => {
                    //     e.target.value = e.target.value.slice(0, 200)
                    // }}
                    value={AtsformData.attributeValue}
                    // onChange={(event, value) => setAtsformData({ ...AtsformData, attributeValue: value })}
                    onChange={handleInputEdit}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={12}
                xs={10.2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <Button variant="contained" size="large" type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Drawer>
      {selectedOption === "Delete" && (
        <Dialog
          open={viewDetails}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          style={{
            backdropFilter: "blur(4px)",
            backgroundColor: "rgba(111, 126, 140, 0.2)",
            transition: "opacity 100ms ease 0s",
            zIndex: 1600,
          }}
        >
          <DialogTitle
            style={{
              background: "#3a86ff",
              marginBottom: "3rem",
              color: "white",
            }}
          >
            {"Alert"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{ color: "#14213d" }}
              id="alert-dialog-slide-description"
            >
              Are you sure you want to delete this record?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ background: "#38b000" }}
              variant="contained"
              onClick={() => {
                handleDelete(userInfo, cellData);
              }}
            >
              Yes
            </Button>
            <Button
              style={{ background: "#c9184a" }}
              variant="contained"
              onClick={handleClose}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {selectedOption === "Audit Log" && (
        <>
          <ViewAccessRowData selectedOption={selectedOption} />
        </>
      )}
      {view === "top" && (
        <>
          <div
            className={drawerOpener ? "add-btn" : "close-btn"}
            style={{
              display: "flex",
              marginLeft: drawerOpener ? "255px" : "45px",
              justifyContent: "space-between",
              marginTop: "2.75vh",
              marginBottom: "1.75vh",
            }}
          >
            <Stack spacing={2} direction="row">
              <Button size="large" variant="contained" onClick={handleopenAdd}>
                Add New
              </Button>
            </Stack>
          </div>
        </>
      )}
      {view === "grid" && (
        <div className="row-container">
          <div
            style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {cellData.data.atsName}
          </div>
          <div className="cellIcon" style={{ flex: 0 }}>
            <img
              title="View"
              style={{ cursor: "pointer" }}
              width="20px"
              src={viewImg}
              onClick={(e) => handleView(e, cellData)}
            />
            &nbsp; &nbsp;
            {/* <IconButton
              title="action"
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(e) => {
                handleClick(e, cellData.rowIndex, cellData.data);
              }}
            >
              <MoreVertIcon
                fontSize="medium"
                style={{ transform: "rotate(90deg)" }}
              />
            </IconButton> */}
            <img
              alt=""
              src={actionIcon}
              style={{ cursor: "pointer", height: "20px", width: "21px" }}
              onClick={(e) => {
                handleClick(e, cellData.rowIndex, cellData.data);
              }}
            />
          </div>
          {
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl.element}
              open={open && anchorEl.index === cellData.rowIndex}
              onClose={handleCloseMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "18ch",
                  fontSize: "10px",
                  marginLeft: "15px",
                },
              }}
              sx={{
                "& .MuiMenu-paper": {
                  left: menuPosition.x + "px!important",
                  top: menuPosition.y + "px!important",
                },
              }}
            >
              {menuItem.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === "selectedOption"}
                  onClick={() => {
                    handleMenuItemClick(option, cellData);
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          }
        </div>
      )}
    </>
  );
}
export default EditATSTags;
