import axios from "axios";
import * as serverContstant from "../../constant/serverConstant";
import { AxiosInstance } from "../../constant/httpsHandler";
import AppConstants from "../../constant";
export async function RecieveTootlTipData(accessToken) {
  const requestBody = {
    createdBy: "ravil.singh",
  };

  return axios.post(
    serverContstant.API_PATH +
      "/" +
      serverContstant.API_ENDPOINTS.RECEIVE_TOOLTIP_DATA +
      "?pageNo=1&pageSize=50&source=chs",
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
}
export async function getAuditLogsDetails(accessToken) {
  const requestBody = {
    moduleName: "tooltips",
  };
  return axios.post(
    serverContstant.API_PATH +
      "/" +
      serverContstant.API_ENDPOINTS.GET_AUDIT_LOGS_DETAILS +
      "?pageNo=0&pageSize=100&source=chs",
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
}

export const create_tooltip = async (userName, body) => {
  let payloadValue = {
    ...body,
    isEnabled: 0,
    createdBy: userName,
    updatedBy: userName,
    parentTable: body.functionName,
    deleted: 0,
    caption: body.toolTipText,
  };
  delete payloadValue.functionName;
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.CREATE_TOOLTIP}?source=ClientHub`,
    payloadValue
  );
};
export const updateToolTip = async (userName, body, deleteData, enable) => {
  let payloadValue = {};
  if (!deleteData && !enable) {
    payloadValue = {
      ...body,
      isEnabled: body.isEnabled ? body.isEnabled : 0,
      createdBy: userName,
      updatedBy: userName,
      parentTable: body.functionName,
      deleted: 0,
      caption: body.toolTipText,
    };
    delete payloadValue.functionName;
  } else if (enable) {
    payloadValue = { ...body };
  } else {
    payloadValue = { ...body };
  }
  return await AxiosInstance.put(
    `${AppConstants.serverConstants.API_ENDPOINTS.UPDATE_TOOLTIP}?source=ClientHub`,
    payloadValue
  );
};

export const getTooltipList = async (
  filter,
  take,
  skip,
  sort,
  functionName
) => {
  const body = {
    sort: sort,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "tooltips",
    filter: filter,
  };
  let bodyValue = {};
  if (functionName !== "") {
    bodyValue = {
      ...body,
      functionName: functionName,
    };
  } else {
    bodyValue = { ...body };
  }
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_TOOLTIP}?source=ClientHub`,
    bodyValue
  );
};

export const getFunctionName = async () => {
  return await AxiosInstance.get(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_FUNCTION_NAME}?source=ClientHub`
  );
};

export const getFieldName = async (functionName) => {
  return await AxiosInstance.get(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_FIELD_NAME}/${functionName}?source=ClientHub`
  );
};

export const getTooltipValue = async (functionName) => {
  const body = {
    sort: null,
    requireTotalCount: true,
    columnInclude: [],
    skip: 0,
    take: 100,
    TableName: "tooltips",
    functionName: functionName,
    filter: ["isEnabled", "=", "1"],
  };
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_TOOLTIP_VALUE}?source=ClientHub`,
    body
  );
};
