import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DropDownBox from "devextreme-react/drop-down-box";
import "devextreme/dist/css/dx.light.css";
import { useCallback, useEffect, useState } from "react";
import "../ClientPortalList/ClientPortalList.css";
import "./ViewAccessRowData.css";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { DataGrid, Toolbar } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  ColumnFixing,
  Export,
  FilterRow,
  Item,
  Pager,
  Paging,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { AtsConfigrationAuditLogsDetails } from "../../api/Audit Logs/AuditLogs";
import {
  getChsCustomization,
  postChsCustomization,
} from "../../api/Customization";
import { useAuthContext } from "../../Context/AuthProvider";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
const allowedPageSizes = [50, 100, 250];
const customStore = (cellDetails, initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || null;
      const take = options.take || 50;
      const skip = options.skip;
      const sort = options.sort || [
        {
          selector: "lastUpdatedDate",
          desc: true,
        },
      ];
      let res;
      try {
        res = await AtsConfigrationAuditLogsDetails(
          filter,
          cellDetails,
          sort,
          take,
          skip
        );
        // Check if res.data is not null before accessing its properties
        if (res.data && res.data.data) {
          if (res.data.data.response && res.data.data.response.length) {
            const dataRes = res.data.data.response.map((item, index) => {
              let lastUpdatedDate = "";
              if (item.lastUpdatedDate) {
                const dateString = item.lastUpdatedDate;
                const inputDateCreated = new Date(dateString);
                // Format the date in the desired format without any time zone adjustments
                lastUpdatedDate = `${inputDateCreated
                  .getUTCDate()
                  .toString()
                  .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                  month: "short",
                }).format(
                  inputDateCreated
                )}-${inputDateCreated.getUTCFullYear()} ${inputDateCreated
                  .getUTCHours()
                  .toString()
                  .padStart(2, "0")}:${inputDateCreated
                  .getUTCMinutes()
                  .toString()
                  .padStart(2, "0")}:${inputDateCreated
                  .getUTCSeconds()
                  .toString()
                  .padStart(2, "0")}`;
              }
              return {
                id: index + 1,
                changedBy: item.changedBy,
                fieldName: item.fieldName,
                lastUpdatedDate: lastUpdatedDate,
                newValue: item.newValue,
                oldValue: item.oldValue,
              };
            });

            return {
              data: dataRes,
              totalCount: res.data.data.response.length,
            };
          }
        }
      } catch (error) {
        // Handle other errors here
        // setStatus({
        //     display: true,
        //     text: `${error.code} , Please contact the administrator`,
        //     statusType: "warning",
        // })
      }

      // If the response is null or doesn't contain the expected properties
      return {
        data: [
          {
            id: null,
            data: "no data",
          },
        ],
        totalCount: 0,
      };
    },
  });

function ViewAccessRowData({
  isAuditLogOpen,
  setIsAuditLogOpen,
  cellDetails,
  ViewAccessRowDataDialog,
  setViewAccessRowDataDialog,
}) {
  const { userInfo } = useAuthContext();
  const [clientListData, setClientListData] = useState([]);
  const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";
  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  //   const { accessToken } = useAuthContext();
  //   const toggleSidebar = () => {
  //     setIsSidebarOpen(!isSidebarOpen);
  //     setSelectedOption(null);
  //     setAnchorEl({ element: null, index: -1 });
  //     console.log(!isSidebarOpen);
  //   };
  //   const handleClosetoggleSidebar = () => {
  //     setIsSidebarOpen(false);
  //   };
  useEffect(() => {
    // clientDatRef.current = customStore(selectedTab, setStatus);
    setClientListData(customStore(cellDetails));
  }, []);

  const toggleSidebarClose = () => {
    setViewAccessRowDataDialog(false);
  };
  function handleAuditOpen() {
    setIsAuditLogOpen(true);
    setViewAccessRowDataDialog(false);
  }

  function handleCloseAudit() {
    setIsAuditLogOpen(false);
  }

  // useEffect(() => {
  //     if (selectedOption === 'Audit Log') {
  //         setIsAuditLogOpen(true);
  //     }
  // }, [selectedOption])

  const saveState = useCallback(
    async (state) => {
      if (userInfo.userName) {
        const res = await postChsCustomization(
          userInfo.userName,
          "ATSAutoLoginAudit",
          state
        );
      }
    },
    [userInfo]
  );

  const loadState = useCallback(async () => {
    if (userInfo.userName) {
      const res = await getChsCustomization(
        userInfo.userName,
        "ATSAutoLoginAudit"
      );
      return res.data.data;
    }
  }, [userInfo]);

  return (
    <>
      {cellDetails !== null &&
        cellDetails !== undefined &&
        Object.keys(cellDetails).length > 0 && (
          <Drawer
            anchor="right"
            open={ViewAccessRowDataDialog}
            onClose={toggleSidebarClose}
            PaperProps={{ style: { width: 600 } }}
          >
            <DialogTitle
              style={{
                background: "rgb(0 140 227)",
                color: "white",
                fontSize: "20px",
              }}
              id="responsive-dialog-title"
            >
              View
            </DialogTitle>
            <IconButton
              size="large"
              aria-label="close"
              onClick={toggleSidebarClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "white",
              }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
            <div className="details-container">
              <div className="button-style">
                <Button
                  onClick={handleAuditOpen}
                  variant="contained"
                  sx={{ marginRight: "2%" }}
                >
                  Audit Logs
                </Button>
              </div>
              <div className="details-header">
                <span>ATS Name</span>
              </div>
              <div className="details-content-row-items">
                <div className="row" style={{ margin: "18px 0" }}>
                  <span
                    className="col-sm-6"
                    style={{ fontWeight: 600, fontSize: "18px" }}
                  >
                    Input Element Type
                  </span>
                  <span className="col-sm-6" style={{ fontSize: "15px" }}>
                    {cellDetails ? cellDetails.data.inputElementType : "N/A"}
                  </span>
                </div>
                <div className="row" style={{ margin: "18px 0" }}>
                  <span
                    className="col-sm-6"
                    style={{ fontWeight: 600, fontSize: "18px" }}
                  >
                    Database Field
                  </span>
                  <span className="col-sm-6" style={{ fontSize: "15px" }}>
                    {cellDetails ? cellDetails.data.dbField : "N/A"}
                  </span>
                </div>
                <div className="row" style={{ margin: "18px 0" }}>
                  <span
                    className="col-sm-6"
                    style={{ fontWeight: 600, fontSize: "18px" }}
                  >
                    Attribute
                  </span>
                  <span className="col-sm-6" style={{ fontSize: "15px" }}>
                    {cellDetails ? cellDetails.data.attribute : "N/A"}
                  </span>
                </div>
                <div className="row" style={{ margin: "18px 0" }}>
                  <span
                    className="col-sm-6"
                    style={{ fontWeight: 600, fontSize: "18px" }}
                  >
                    Attribute Value
                  </span>
                  <span className="col-sm-6" style={{ fontSize: "15px" }}>
                    {cellDetails ? cellDetails.data.attributeValue : "N/A"}
                  </span>
                </div>
              </div>
            </div>
          </Drawer>
        )}

      <>
        <div className="audit-div">
          <BootstrapDialog
            onClose={handleCloseAudit}
            aria-labelledby="customized-dialog-title"
            open={isAuditLogOpen}
            className="dialog-box"
            sx={{
              "& .MuiDialog-paper": {
                maxWidth: "80%",
              },
            }}
          >
            <DialogTitle
              sx={{
                m: 0,
                p: 2,
                color: "white",
                background: "rgb(0, 140, 227)",
              }}
              id="customized-dialog-title"
              className="dialog-text"
            >
              Audit Logs
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setIsAuditLogOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "white",
              }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
            <Box
              className="main-div"
              style={{
                height: "100vh",
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "20px",
              }}
            >
              <DataGrid
                dataSource={clientListData}
                keyExpr="id"
                key="index"
                height="90%"
                width="100%"
                showRowLines={true}
                showColumnLines={true}
                hoverStateEnabled
                showBorders={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnMinWidth={50}
                loadPanel={{ enabled: true }}
                filterSyncEnabled={true}
                columnResizingMode="widget"
                remoteOperations={true}
                noDataText="no data "
              >
                {/* <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={loadState}
                  customSave={saveState}
                /> */}
                <Scrolling mode="standard" />
                {/* <Toolbar>
                  <Item></Item>
                  <Item name="columnChooserButton" />
                  <Item name="exportButton" />
                  <Item>
                    <img title="Clear Customization" className="clean"></img>
                  </Item>
                </Toolbar>
                <ColumnChooser enabled={true} />
                <Export enabled={true} allowExportSelectedData={false} /> */}
                <ColumnFixing enabled={true} />
                <FilterRow visible={true} />
                <Scrolling columnRenderingMode="virtual" />
                {/* <ColumnChooser enabled={true} />
                <Export enabled={true} allowExportSelectedData={false} /> */}
                <ColumnFixing enabled={true} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={allowedPageSizes}
                  showInfo={true}
                  visible={true}
                />
                <Paging defaultPageSize={50} enabled={true} />
                <FilterRow visible={true} />
                <Column
                  allowSorting={false}
                  width="50px"
                  allowFiltering={false}
                  alignment="left"
                  caption="S.No"
                  dataField="id"
                  allowReordering={false}
                />
                <Column
                  allowReordering={true}
                  width="200px"
                  caption="Field Name"
                  dataField="fieldName"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  allowReordering={true}
                  width="200px"
                  caption="Old Value"
                  dataField="oldValue"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  width="210px"
                  caption="New Value"
                  dataField="newValue"
                />

                <Column
                  allowReordering={true}
                  width="200px"
                  caption="Changed By"
                  dataField="changedBy"
                  // cellRender={setStatus}
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  allowReordering={true}
                  width="200px"
                  caption="Last Updated Date "
                  dataField="lastUpdatedDate"
                  dataType="date"
                  format={filterDateFormat}
                  // cellRender={setStatus}
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
              </DataGrid>
            </Box>
          </BootstrapDialog>
        </div>
      </>
    </>
  );
}
export default ViewAccessRowData;
