import axios from "axios";
import * as serverContstant from "../constant/serverConstant";
import { AxiosInstance } from "../constant/httpsHandler";
import AppConstants from "../constant";

export const getClients = async (selectedTab, userInfo, userRole) => {
  let usersRole = "";
  if (
    userRole === "Global Administrator" ||
    userRole === "ClientPortal Administrator 90"
  ) {
    usersRole = "admin";
  } else {
    usersRole = "user";
  }

  const body = {
    groupId: parseInt(selectedTab) + 1,
    username: userInfo.userName,
    roleAccess: usersRole,
  };
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_CLIENT}?pageNo=0&pageSize=100&source=chs`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};


export const getClientList = async (
  filter,
  take,
  skip,
  sort,
  userName,
  userRole
) => {
  let usersRole = "";
  if (
    userRole === "Global Administrator" ||
    userRole === "ClientPortal Administrator 90"
  ) {
    usersRole = "admin";
  } else {
    usersRole = "user";
  }

  const body = {
    sort: sort,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "clients",
    filter: filter,
    username: userName,
    roleAccess: usersRole,
  };
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_CLIENT_PORTAL_LIST}?source=ClientHub`,
    body
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getClientNameDropDown = async (filter, take, skip, sort) => {
  let filterValue = [];
  if (filter) {
    if (filter.includes("and")) {
      filterValue = filter;
    } else {
      if (filter[2] instanceof Object) {
        filterValue = null;
      } else {
        filterValue = filter;
      }
    }
  }
  const body = {
    sort: sort,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "clients",
    filter: filterValue,
  };
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_CLIENT_NAME}?source=chs`,
    body
  );
};

export const getClientNamedata = async (take, skip, sort) => {
  const body = {
    sort: sort,
    requireTotalCount: true,
    skip: skip,
    take: take,
    TableName: "clients",
  };
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_CLIENT_NAME}?source=chs`,
    body
  );
};

export const activeUserList = async (filter, take, skip) => {
  let filterValue = [];
  if (filter) {
    if (filter.includes("and")) {
      filterValue = filter;
    } else {
      if (filter[2] instanceof Object) {
        filterValue = null;
      } else {
        filterValue = filter;
      }
    }
  }
  const body = {
    sort: null,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "users",
    filter: filterValue,
  };
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_ACTIVE_USER}?source=chs`,
    body
  );
};

export const activeUserListdata = async (take) => {
 
  const body = {
    requireTotalCount: true,
    take: take,
    TableName: "users",
  };
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_ACTIVE_USER}?source=chs`,
    body
  );
};

export const postActiveUser = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.POST_ACTIVE_USER}?source=chs&isUser=false`,
    body
  );
};

export const postActiveClient = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.POST_ACTIVE_USER}?source=chs&isUser=true`,
    body
  );
};

export const getActiveUserById = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_ACTIVE_USER_BY_ID}?source=chs`,
    body
  );
};

export const getClientByName = async (body) => {
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_CLIENT_BY_USERNAME}?source=chs`,
    body
  );
};

export const getAccessControlAuditLogs = async (filter, take, skip, sort) => {
  const body = {
    sort: sort,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "audit",
    moduleName: "clientaccess",
    filter: filter,
  };
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_ACCESS_CONTROL_AUDIT}?source=chs`,
    body
  );
};
export async function getElementConfig(filter, clientId, take, skip) {
  const payloadData = {
    sort: null,
    clientId: clientId,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "elementconfiguration",
    filter: filter,
  };
  return AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_ELEMENT_CONFIGURATION}?source=test`,
    payloadData
  );
}

export async function getAtsClientName(filter, take, skip, sort) {
  let isAutoProcessingEnabled = [];
  if (filter[0] === "isAutoProcessingEnabled") {
    if (filter[2] === true) {
      isAutoProcessingEnabled = [filter[0], filter[1], 1];
    } else {
      isAutoProcessingEnabled = [filter[0], filter[1], 0];
    }
  } else {
    isAutoProcessingEnabled = filter;
  }

  const payloadData = {
    sort: sort,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "clientaccess",
    filter: isAutoProcessingEnabled,
  };
  return AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_ADMIN_CLIENT_NAME}?source=test`,
    payloadData
  );
}

export async function getAtsClientUser(filter, sort) {
  const payloadData = {
    sort: sort,
    requireTotalCount: true,
    columnInclude: [],
    skip: 0,
    take: 100,
    TableName: "clientaccess",
    filter: filter,
  };
  return AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_ADMIN_USER_DETAILS}?source=test`,
    payloadData
  );
}

export async function postElemnetData(
  accessToken,
  userName,
  formsData,
  clientId,
  atsId
) {
  const payload = {
    ...formsData,
    clientId: clientId,
    atsId: atsId,
    createdby: userName,
    updatedby: userName,
    deleted: 0,
  };
  if (formsData.dbField == null) {
    delete payload.dbField;
  } else if (formsData.buttonIntent == null) {
    delete payload.buttonIntent;
  }
  return axios.post(
    serverContstant.API_PATH +
      "/" +
      serverContstant.API_ENDPOINTS.POST_ELEMENT_DATA +
      "?source=chs",
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export async function EditElemnetData(
  accessToken,
  formsData,
  clientId,
  updatedby,
  userInfo
) {

  const payload = {
    ...formsData,
    id: clientId,
    updatedby: userInfo.userName,
  };
  if (formsData.dbField == null) {
    delete payload.dbField;
  } else if (formsData.buttonIntent == null) {
    delete payload.buttonIntent;
  }
  return axios.put(
    serverContstant.API_PATH +
      "/" +
      serverContstant.API_ENDPOINTS.UPDATE_ELEMENT_CONFIG_DATA +
      "?source=chs",
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export async function deleteElemnetData(accessToken, userInfo, cellData) {
  const payload = {
    deleted: 1,
    id: cellData.data.id,
    updatedby: userInfo.userName,
  };
  return axios.put(
    serverContstant.API_PATH +
      "/" +
      serverContstant.API_ENDPOINTS.UPDATE_ELEMENT_CONFIG_DATA +
      "?source=chs",
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}
export async function ElementDataAudit(cellData, filter, take, skip, sort) {
  const payload = {
    sort: sort,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "audit",
    moduleName: "elementconfiguration",
    parentId: cellData.data.id,
    filter: filter,
  };
  return AxiosInstance.post(
    serverContstant.API_PATH +
      "/" +
      `${serverContstant.API_ENDPOINTS.GET_AUDIT_LOGS_DETAILS}?source=chs`,
    payload
  );
}
