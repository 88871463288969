import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
// import "../ClientPortalList.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
// import "./AuthenticationLog.css";
import { Tabs, Tab, Paper } from "@mui/material";
import { Button as Button2 } from "devextreme-react/button";
import DataGrid from "devextreme-react/data-grid";
import left from "../../assets/left.png";
import {
  Selection,
  Column,
  FilterRow,
  Paging,
  Pager,
  ColumnChooser,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { getTooltipList, updateToolTip } from "../../api/ToolTipApi/TooltipAPI";
import { useAuthContext } from "../../Context/AuthProvider";
import "../../Admin/Tooltip/Tooltip.css";

import CustomStore from "devextreme/data/custom_store";
import { wait } from "@testing-library/user-event/dist/utils";
import Alerts from "../../Alert/Alert";
import TooltipEdit from "./TooltipEdit";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import clean from "../../assets/clean.png";
import ClearFiltericon from "../../assets/ClearFiltericon.png";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, checked }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0199f9",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    borderRadius: "50%", // Make the thumb circular
    backgroundColor: checked ? "transparent" : "#0099f9", // Set the color for the circle when switch is off
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const customStore = (functionName, setStatus, initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const take = options.take || 10;
      const skip = options.skip;
      const sort = options.sort;
      try {
        const res = await getTooltipList(
          filter,
          take,
          skip,
          sort,
          functionName
        );
        if (!res.data.data || res.data.data.response.length === 0) {
          return {
            data: [],
            totalCount: 0,
          };
        }
        let dataRes = [];
        if (res.data.data) {
          dataRes = res.data.data.response.map((item, index) => {
            let formattedDateUpdat = "";
            if (item.lastModified) {
              const dateStringUpdated = item.lastModified;
              const inputDate = new Date(dateStringUpdated);
              formattedDateUpdat = `${inputDate
                .getUTCDate()
                .toString()
                .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                month: "short",
              }).format(inputDate)}-${inputDate.getUTCFullYear()} ${inputDate
                .getUTCHours()
                .toString()
                .padStart(2, "0")}:${inputDate
                .getUTCMinutes()
                .toString()
                .padStart(2, "0")}:${inputDate
                .getUTCSeconds()
                .toString()
                .padStart(2, "0")}`;
            } else {
              formattedDateUpdat = "";
            }
            return {
              loadMode: "raw",
              key: "id",
              id: index + 1,
              functionName: item.functionName ? item.functionName : "",
              lastModified: formattedDateUpdat ? formattedDateUpdat : "",
              fieldName: item.fieldName ? item.fieldName : "",
              isEnabled: item.isEnabled ? item.isEnabled : "",
              toolTipText: item.toolTipText ? item.toolTipText : "",
              toolTipId: item.toolTipId ? item.toolTipId : "",
            };
          });
          return {
            data: dataRes,
            totalCount: res.data.data.count,
          };
        }
      } catch (error) {
        setStatus({
          display: true,
          text: `Something went wrong`,
          statusType: "warning",
        });
        return {
          data: [],
          totalCount: 0,
        };
      }
    },
  });

function Tooltip({ drawerOpener }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [ViewOtherDataGrid, seViewOtherDataGrid] = useState("");
  const [open, setOpen] = useState(false);
  const { userInfo } = useAuthContext();
  const handleClose = () => setOpen(false);
  const [toggleValues, setToggleValues] = useState([]);
  const allowedPageSizes = [50, 100, 250];
  const [dataGridValue, setDataGridValue] = useState([]);
  const clientDatRef = useRef("");
  const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";
  const [_dataGridValue, _setDataGridValue] = useState([]);
  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  const [clearCustomizationPopUp, setClearCustomizationPopUp] = useState(false);

  const toolTipRef = useRef("");
  const _toolTipRef = useRef("");
  useEffect(() => {
    if (ViewOtherDataGrid.functionName !== "") {
      clientDatRef.current = customStore(
        ViewOtherDataGrid.functionName,
        setStatus
      );
      setDataGridValue(customStore(ViewOtherDataGrid.functionName, setStatus));
    } else {
      clientDatRef.current = customStore("", setStatus);
      setDataGridValue(customStore("", setStatus));
    }
  }, [clientDatRef, ViewOtherDataGrid]);

  function onContentReady(e) {
    setToggleValues(
      e.component
        .getDataSource()
        .items()
        .map((item) => item.isEnabled)
    );
  }
  function refresh() {
    if (toolTipRef.current) {
      toolTipRef.current.instance.refresh();
    }
  }
  function _refresh() {
    if (_toolTipRef.current) {
      _toolTipRef.current.instance.refresh();
    }
  }

  const handleToggleChange = async (index, e) => {
    const newToggleValues = [...toggleValues];
    newToggleValues[index.rowIndex] = !newToggleValues[index.rowIndex]; // Toggle the value at the specific index
    setToggleValues(newToggleValues);
    let enableValue;
    if (!toggleValues[index.rowIndex]) {
      enableValue = 1;
    } else {
      enableValue = 0;
    }
    const body = {
      parentTable: index.data.functionName,
      fieldName: index.data.fieldName,
      isEnabled: enableValue, // Toggle the isEnabled value
      id: index.data.toolTipId,
      updatedBy: userInfo.userName,
    };
    try {
      const apiResponse = await updateToolTip(
        userInfo.userName,
        body,
        false,
        true
      );
      if (body.isEnabled === 1) {
        setStatus({
          display: true,
          text: "Tooltip Enabled Successfully",
          statusType: "success",
        });
        _refresh();
      } else if (body.isEnabled === 0) {
        setStatus({
          display: true,
          text: "Tooltip Disabled",
          statusType: "warning",
        });
        _refresh();
      } else if (
        apiResponse.data.data.message === "Tooltip Field Already Present!!"
      ) {
        setStatus({
          display: true,
          text: "Tooltip Already Exists",
          statusType: "info",
        });
        _refresh();
      }
    } catch (error) {
      setStatus({
        display: true,
        text: "Tooltip Already Exists",
        statusType: "warning",
      });
      _refresh();
    }
  };

  useEffect(() => {
    if (ViewOtherDataGrid.functionName !== "") {
      clientDatRef.current = customStore(
        ViewOtherDataGrid.functionName,
        setStatus
      );
    }
  }, [ViewOtherDataGrid]);


  const handleCellPrepared = (e) => {
    if (e.cellElement.ariaLabel === "Column Function Name") {
      e.cellElement.style.backgroundColor = "#008ce3";
    } else if (e.cellElement.ariaLabel === "Column Field Name") {
      e.cellElement.style.backgroundColor = "#008ce3";
    }
  };

  function refresh() {
    if (toolTipRef.current) {
      toolTipRef.current.instance.refresh();
    }
  }

  function refreshinside() {
    if (_toolTipRef.current) {
      _toolTipRef.current.instance.refresh();
    }
  }

  const clearFilters = (e) => {
    if (toolTipRef.current) {
      toolTipRef.current.instance.clearFilter();
    }
  };

  const clearCustomizationDataGrid = () => {
    const dataGridInstance = toolTipRef.current.instance;
    const columns = dataGridInstance.getVisibleColumns();
    columns.forEach((column) => {
      column.visible = true;
      column.showInColumnChooser = true;
    });
    dataGridInstance.option("columns", columns);
    dataGridInstance.clearFilter();
    dataGridInstance.clearSelection();
    setClearCustomizationPopUp(false);
  };

  const customChanges = {
    icon: "undo",
    text: "Clear",
    type: "default",
    onClick: () => clearCustomizationDataGrid(),
  };
  const cancelCustomchanges = {
    text: "Close",
    icon: "remove",
    type: "danger",
    onClick: () => {
      setClearCustomizationPopUp(false);
    },
  };
  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    // Obtain selected rows data
    const selectedRows = e.component.getSelectedRowsData();
    // Export only selected rows
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      customizeExportData: (columns, rows) => {
        return {
          columns,
          rows: selectedRows,
        };
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "Tooltip.xlsx"
        );
      });
    });
  };

  return (
    <>
      <Popup
        visible={clearCustomizationPopUp}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        container="body"
        width={500}
        height={200}
        title="Alert"
      >
        <Position my="center" of={"main_dataGridClass"} collision="fit" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={customChanges}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelCustomchanges}
        />
        <p>Your custom changes made by you on grid will be removed..</p>
      </Popup>
      {status.display && <Alerts status={status} sx={{ margin: "10px" }} />}
      {/* <TooltipAuditLogs handleClose={handleClose} open={open} /> */}
      <Box className={drawerOpener ? "add-tabs" : "close-tabs"}>
        <Paper style={{ width: "98%", marginLeft: "55px" }}>
          <Tabs
            value={selectedTab}
            // onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered={false}
          >
            {ViewOtherDataGrid.functionName !== undefined && (
              <IconButton
                onClick={() => seViewOtherDataGrid("")}
                style={{ marginRight: "-36px" }}
              >
                <img src={left} />
              </IconButton>
            )}
            <Tab
              sx={{
                fontSize: "large",
                fontWeight: "700",
                marginLeft:
                  ViewOtherDataGrid.functionName !== undefined ? "1%" : "0%",
                textTransform: "none",
              }}
              label="Tooltips"
            />
          </Tabs>
        </Paper>
        {selectedTab === 0 ? (
          <>
            <TooltipEdit
              view="top"
              setStatus={setStatus}
              ViewOtherDataGrid={ViewOtherDataGrid}
              refresh={refresh}
              _refresh={_refresh}
              seViewOtherDataGrid={seViewOtherDataGrid}
            />
            {/* //After click on grid Field */}
            {ViewOtherDataGrid.functionName !== undefined ? (
              <div>
                <></>
                <div
                  className="main-div"
                  style={{
                    height: "90vh",
                    // width: "100%",
                    marginTop: "1%",
                    marginLeft: drawerOpener ? "255px" : "65px",
                    width: drawerOpener ? "86%" : "96%",
                  }}
                >
                  <DataGrid
                    dataSource={clientDatRef.current}
                    keyExpr="id"
                    key="index"
                    height="80%"
                    width="100%"
                    showRowLines={true}
                    showColumnLines={true}
                    hoverStateEnabled
                    showBorders={true}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    columnMinWidth={50}
                    loadPanel={{ enabled: true }}
                    onCellPrepared={handleCellPrepared}
                    onContentReady={onContentReady}
                    columnResizingMode="widget"
                    onExporting={onExporting}
                    ref={_toolTipRef}
                  >
                    <Scrolling columnRenderingMode="virtual" />
                    <ColumnFixing enabled={true} />
                    <Toolbar>
                      <Item location="after">
                        <Button2
                          hint="Refresh"
                          icon="refresh"
                          onClick={refreshinside}
                        />
                      </Item>
                      <Item name="columnChooserButton" />
                      <Item name="exportButton" />
                    </Toolbar>
                    <ColumnChooser enabled={true} />
                    <Export enabled={true} allowExportSelectedData={false} />

                    <ColumnFixing enabled={true} />
                    <Pager
                      showPageSizeSelector={true}
                      allowedPageSizes={allowedPageSizes}
                      showInfo={true}
                      visible={true}
                    />

                    <Paging defaultPageSize={50} enabled={true} />

                    <FilterRow visible={true} />
                    <Column
                      allowSorting={false}
                      width="50px"
                      allowFiltering={false}
                      alignment="left"
                      caption="S.No"
                      dataField="id"
                      allowReordering={false}
                    />
                    <Column
                      allowReordering={true}
                      width="300px"
                      caption="Field Name"
                      dataField="fieldName"
                      editorOptions={{
                        showClearButton: true,
                      }}
                      cellRender={(cellData) => {
                        return (
                          <>
                            <TooltipEdit
                              view="grid"
                              cellData={cellData}
                              setStatus={setStatus}
                              ViewOtherDataGrid={ViewOtherDataGrid}
                              refresh={refresh}
                              _refresh={_refresh}
                            />
                          </>
                        );
                      }}
                    />
                    <Column
                      allowReordering={true}
                      width="400px"
                      caption="Tooltip"
                      dataField="toolTipText"
                      editorOptions={{
                        showClearButton: true,
                      }}
                    />
                    <Column
                      allowReordering={true}
                      width="400px"
                      caption="Enable / Disable"
                      dataField="isEnabled"
                      dataType="boolean"
                      editorOptions={{
                        showClearButton: true,
                      }}
                      cellRender={(cellData) => {
                        return (
                          <>
                            <div>
                              <div>
                                <IOSSwitch
                                  checked={toggleValues[cellData.rowIndex]}
                                  onChange={(e) =>
                                    handleToggleChange(cellData, e)
                                  }
                                />
                              </div>
                            </div>
                          </>
                        );
                      }}
                    />
                  </DataGrid>
                </div>
              </div>
            ) : (
              <div
                className="main-div"
                style={{
                  height: "90vh",
                  // width: "100%",
                  marginTop: "1%",

                  marginLeft: drawerOpener ? "255px" : "65px",
                  width: drawerOpener ? "86%" : "96.5%",
                }}
              >
                <DataGrid
                  dataSource={clientDatRef.current}
                  keyExpr="id"
                  key="index"
                  height="80%"
                  width="100%"
                  showRowLines={true}
                  showColumnLines={true}
                  hoverStateEnabled
                  showBorders={true}
                  allowColumnResizing={true}
                  allowColumnReordering={true}
                  columnMinWidth={50}
                  loadPanel={{ enabled: true }}
                  onCellPrepared={handleCellPrepared}
                  ref={toolTipRef}
                  remoteOperations={true}
                  onExporting={onExporting}
                  columnResizingMode="widget"
                  filterSyncEnabled={true}
                >
                  <StateStoring
                    enabled={true}
                    type="custom"
                    // customLoad={loadStateUserName}
                    // customSave={saveStateUserName}
                  />
                  <Scrolling mode="standard" />

                  <Toolbar>
                    <Item location="after">
                      <Button2
                        hint="Refresh"
                        icon="refresh"
                        onClick={refresh}
                      />
                    </Item>
                    <Item>
                      <DevExtremToolbarClearfilter
                        clearFilters={clearFilters}
                        toolTipRef={toolTipRef}
                      />
                    </Item>
                    <Item name="columnChooserButton" />
                    <Item name="exportButton" />
                    <Item>
                      <img
                        title="Clear Customization"
                        className="clean"
                        src={clean}
                        onClick={() => {
                          setClearCustomizationPopUp(true);
                        }}
                      ></img>
                    </Item>
                  </Toolbar>
                  <ColumnChooser enabled={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <ColumnFixing enabled={true} />
                  <FilterRow visible={true} />
                  <Scrolling columnRenderingMode="virtual" />

                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showInfo={true}
                    visible={true}
                  />
                  <Paging defaultPageSize={50} enabled={true} />

                  <Column
                    allowSorting={false}
                    width="50px"
                    allowFiltering={false}
                    alignment="left"
                    caption="S.No"
                    dataField="id"
                    allowReordering={false}
                  />
                  <Column
                    allowReordering={true}
                    width="300px"
                    caption="Function Name"
                    dataField="functionName"
                    editorOptions={{
                      showClearButton: true,
                    }}
                    cellRender={(cellData) => {
                      return (
                        <>
                          <a
                            onClick={() => {
                              seViewOtherDataGrid(cellData.data);
                            }}
                          >
                            {cellData.data.functionName}
                          </a>
                        </>
                      );
                    }}
                  />
                  {/* <Column
                    allowReordering={true}
                    width="400px"
                    caption="Field Name"
                    dataField="fieldName"
                    editorOptions={{
                      showClearButton: true,
                    }}
                  /> */}
                  <Column
                    allowReordering={true}
                    width="400px"
                    caption="Last Modified"
                    dataField="lastModified"
                    dataType="date"
                    format={filterDateFormat}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                </DataGrid>
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
export default Tooltip;

function DevExtremToolbarClearfilter({ clearFilters, toolTipRef }) {
  const [filterAlert, setfilterAlert] = useState(false);

  return (
    <>
      <img
        title="Clear Filter"
        className="clean"
        src={ClearFiltericon}
        onClick={() => {
          const isFilterApplied =
            toolTipRef.current.instance.getCombinedFilter();
          if (isFilterApplied != undefined) {
            clearFilters();
            // setfilterAlert(true);
            // setTimeout(() => {
            //   setfilterAlert(false);
            // }, 3000);
          }
        }}
      ></img>

      {/* < SnackBar filterAlert={filterAlert} /> */}
      {/* <SnackBar /> */}
    </>
  );
}
