import axios from "axios";
import * as serverContstant from "../../constant/serverConstant";
import { AxiosInstance } from "../../constant/httpsHandler";

export async function ClientPortalAuditLog(filter, cellData, take, skip, sort) {
  const resquestBody = {
    sort: sort,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "audit",
    moduleName: "clients",
    parentId: cellData.data ? cellData.data.clientId : cellData,
    filter: filter,
  };

  return AxiosInstance.post(
    serverContstant.API_PATH +
      "/" +
      `${serverContstant.API_ENDPOINTS.GET_AUDIT_LOGS_DETAILS}?source=chs`,
    resquestBody
  );
}

export async function accessControlAudit(accessToken) {
  const resquestBody = {
    moduleName: "clientaccess",
  };
  return axios.post(
    serverContstant.API_PATH +
      "/" +
      serverContstant.API_ENDPOINTS.GET_AUDIT_LOGS_DETAILS +
      "?pageNo=0&pageSize=100&source=chs",
    resquestBody,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export async function AtsConfigrationAuditLogsDetails(
  filter,
  cellDetails,
  sort,
  take,
  skip
) {
  const resquestBody = {
    sort: sort,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "audit",
    moduleName: "elementconfiguration",
    parentId: cellDetails.data.elementId,
    filter: filter,
  };

  return AxiosInstance.post(
    serverContstant.API_PATH +
      "/" +
      `${serverContstant.API_ENDPOINTS.GET_AUDIT_LOGS_DETAILS}?source=chs`,
    resquestBody
  );
}

export const ToolTipAudit = async (filter, skip, take, sort) => {
  const body = {
    sort: sort,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "audit",
    moduleName: "tooltips",
    filter: filter,
  };
  return AxiosInstance.post(
    serverContstant.API_PATH +
      "/" +
      `${serverContstant.API_ENDPOINTS.GET_ACCESS_CONTROL_AUDIT}?source=chs`,
    body
  );
};

export const CustomizableFieldsAuditLogs = async (filter, take, skip, sort) => {
  const body = {
    sort: sort,
    requireTotalCount: true,
    columnInclude: [],
    skip: skip,
    take: take,
    TableName: "audit",
    moduleName: "Customizable",
    filter: filter,
  };

  return AxiosInstance.post(
    serverContstant.API_PATH +
      "/" +
      `${serverContstant.API_ENDPOINTS.GET_ACCESS_CONTROL_AUDIT}?source=chs`,
    body
  );
};
