import "../Tabs/TabList.css";
import noImage from "../assets/noImage.png";
import React, { useState, useEffect } from "react";
import { getClients, getClientList } from "../api/clients";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Tabs, Tab, Paper, Grid } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import { TabContext } from "@mui/lab";
import { useAuthContext } from "../../src/Context/AuthProvider";
import Alerts from "../../src/Alert/Alert";

function TabsList({ drawerOpener }) {
  const [clientResponse, setClientResponse] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedTab, setSelectedTab] = useState("0");
  const [noDataText, setNoDataText] = useState("No Record Found");
  const [selectedStatus, setSelectedStatus] = useState('Active');

  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  const [loadingStates, setLoadingStates] = useState({});
  const { userInfo, userRole } = useAuthContext();

  useEffect(() => {
    if (userRole) {
      // fetchData();
      getClientLists(selectedStatus);
    } else {
      setTimeout(() => {
        setLoader(false);
        setNoDataText("Something went wrong");
      }, 10000);
    }
  }, [selectedTab, userRole]);


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setLoader(true);
    setClientResponse([]);
  };

  async function fetchData() {
    try {
      const clients = await getClients(selectedTab, userInfo, userRole);
      if (clients) {
        const tempArr = clients.data.data.response;
        if (tempArr.length) {
          setClientResponse(tempArr);
          setLoader(false);
        } else {
          setClientResponse(tempArr);
          setLoader(false);
          setStatus({
            display: true,
            text: "No Record Found",
            statusType: "warning",
          });
          closeAlert();
        }
      }
    } catch (error) {
      setLoader(false);
      setStatus({
        display: true,
        text: `Something went wrong`,
        statusType: "warning",
      });
      closeAlert();
    }
  }

  function closeAlert() {
    setTimeout(() => {
      setStatus({
        display: false,
        text: "",
        statusType: "",
      });
    }, 3000);
  }
  useEffect(() => {
    if (clientResponse.length > 0) {
      setLoader(false);
    }
  }, [clientResponse]);

  async function getClientLists(selectedStatus) {
    if (selectedStatus == 'All') {
      var filter = [];
    } else {
      var filter = ["status", "=", selectedStatus];
    }
    const take = 50;
    const skip = null;
    const sort = [
      {
        selector: "updatedAt",
        desc: true,
      },
    ];
    try {
      const clients = await getClientList(filter, take, skip, sort, userInfo.userName, userRole);

      if (clients) {
        const tempArr = clients.data.data.response;
        if (tempArr.length) {
          setClientResponse(tempArr);
          setLoader(false);
        } else {
          setClientResponse(tempArr);
          setLoader(false);
          setStatus({
            display: true,
            text: "No Record Found",
            statusType: "warning",
          });
          closeAlert();
        }
      }
    } catch (error) {
      setLoader(false);
      setStatus({
        display: true,
        text: `Something went wrong`,
        statusType: "warning",
      });
      closeAlert();
    }
  }
  // Handle change event
  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
    getClientLists(event.target.value);
  };
  return (
    <>
      {status.display && <Alerts status={status} sx={{ margin: "10px" }} />}

      <Box>
        <TabContext value={selectedTab}>
          <Box sx={{ width: "100%", marginTop: "50px", height: "15px" }}>
            <Paper square>
              <TabList
                onChange={handleTabChange}
                sx={{ marginLeft: drawerOpener ? "240px" : "60px" }}
              >
                <Tab
                  sx={{
                    textTransform: "none",
                  }}
                  className="tab-text-style"
                  label="Client Portal"
                  value="0"
                />
                <Tab
                  sx={{
                    textTransform: "none",
                  }}
                  className="tab-text-style"
                  label="Back Office"
                  value="1"
                />
              </TabList>
            </Paper>
          </Box>

          <div
            className="page-wrapper"
            id="layoutContentDiv"
            style={{ marginLeft: drawerOpener ? "240px" : "55px" }}
          >
            <TabPanel value="0">
              <div>
                <div>
                  {!loader ? (
                    <div>
                      <div className="slectbox">
                        <div className="pull-right-child">
                          <label htmlFor="status">Status :</label>
                          <select id="status" className="slectitem" name="status" value={selectedStatus} onChange={handleChange}>
                            <option value="All">All</option>
                            <option value="Active">Active</option>
                            <option value="In-Active">In-Active</option>
                          </select>
                          {/* <p>Selected Status: {selectedStatus}</p> */}
                        </div>
                      </div>
                      <div className="mainLegenWrap">
                        <div className="pull-right-child">
                          <span className="legend">
                            <span className="content1"></span>
                            Active
                          </span>
                        </div>
                        <div className="pull-right-child">
                          <span className="legend">
                            <span className="content2"></span>
                            Inactive
                          </span>
                        </div>
                      </div>
                      <Grid container spacing={5}>
                        {clientResponse.length ? (
                          clientResponse.map((portal, index) => {
                            const isLoading = loadingStates[portal.id] || false;
                            const portalClientId = portal.clientId;
                            let redirectUrl;
                            if (portal.loginUrl) {
                              var questionMarkIndex =
                                portal.loginUrl.indexOf("?");
                              if (questionMarkIndex !== -1) {
                                redirectUrl =
                                  portal.loginUrl +
                                  "&clientId=" +
                                  portalClientId;
                              } else {
                                redirectUrl =
                                  portal.loginUrl +
                                  "?clientId=" +
                                  portalClientId;
                              }
                            }
                            function getStyle(border) {
                              if (portal.status == "Active") {
                                border = portal.status === "Active" ? "2px solid red" : "2px solid #00bd36"
                              }
                              if (portal.status == "In-Active") {
                                border = portal.status === "In-Active" ? "2px solid red" : "2px solid #00bd36"
                              }
                              else {
                                border = portal.isActive === true ? "2px solid red" : "2px solid #00bd36"
                              }
                              return border
                            }
                            return (
                              <>
                                <Grid item xs={3} md={2}>
                                  <a
                                    key={index}
                                    href={redirectUrl}
                                    target="#"
                                    rel="noreferrer"
                                  >
                                    <div
                                      // title={portal.isActive ? "Active Client" : "Inactive Client"}
                                      style={{
                                        border: getStyle(),
                                      }}
                                      // title={
                                      //   portal.isActive === true
                                      //     ? "Active Client"
                                      //     : "Inactive Client"
                                      // }
                                      // style={{
                                      //   border:
                                      //     portal.isActive === true ? "2px solid #00bd36" : "2px solid red ",
                                      //   cursor: portal.isactive === 1 ? "pointer": "not-allowed"
                                      // }}
                                      className="tinybox"
                                    >
                                      <div className="text-center mangeItem" key={index}>
                                        {isLoading ? (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              marginTop: "70%",
                                            }}
                                          >
                                            <CircularProgress />
                                          </Box>
                                        ) : (
                                          <img
                                            src={portal.logoUrl}
                                            width="80%"
                                            height="80%"
                                            style={{ marginTop: "-12px" }}
                                            onError={(e) => {
                                              e.target.setAttribute(
                                                "src",
                                                noImage
                                              );
                                            }}
                                          />
                                        )}
                                      </div>
                                      <p className="text-center">
                                        {portal.clientName}
                                      </p>
                                    </div>
                                  </a>
                                </Grid>
                              </>
                            );
                          })
                        ) : (
                          <>
                            <Box
                              sx={{
                                width: "100%",
                                height: "70vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {noDataText}
                            </Box>
                          </>
                        )}
                      </Grid>
                    </div>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </div>
              </div>
            </TabPanel>
            <TabPanel value="1">
              <div>
                <div>
                  {!loader ? (
                    <div>
                      <div className="mainLegenWrap">
                        <div className="pull-right-child">
                          <span className="legend">
                            <span className="content1"></span>
                            Active
                          </span>
                        </div>
                        <div className="pull-right-child">
                          <span className="legend">
                            <span className="content2"></span>
                            Inactive
                          </span>
                        </div>
                      </div>
                      <Grid container spacing={5}>
                        {clientResponse.length ? (
                          clientResponse.map((portal, index) => {
                            const isLoading = loadingStates[portal.id] || false;
                            const portalClientId = portal.clientId;
                            // const portalUrl = new URL(portal.loginUrl);
                            let redirectUrl;
                            if (portal.loginUrl) {
                              redirectUrl =
                                portal.loginUrl + "&clientId=" + portalClientId;
                            } else {
                              portal.loginUrl =
                                portal.loginUrl + "?clientId=" + portalClientId;
                            }
                            return (
                              <>
                                <Grid item xs={3} md={2} key={index}>
                                  <a
                                    href={redirectUrl}
                                    target="#"
                                    rel="noreferrer"
                                  // onClick={() => handlePortalUrl(portal)}
                                  >
                                    <div
                                      title={
                                        portal.isActive === true
                                          ? "Active Client "
                                          : "Inactive Client"
                                      }
                                      style={{
                                        border:
                                          portal.isActive === true
                                            ? "2px solid #00bd36"
                                            : "2px solid red ",
                                        // cursor:
                                        //   portal.isactive === 1
                                        //     ? "pointer"
                                        //     : "not-allowed"
                                      }}
                                      className="tinybox"
                                    >
                                      <div className="text-center mangeItem">
                                        {isLoading ? (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              marginTop: "20%",
                                            }}
                                          >
                                            <CircularProgress />
                                          </Box>
                                        ) : (
                                          <img
                                            src={portal.logoUrl}
                                            width="80%"
                                            height="80%"
                                            style={{ marginTop: "-12px" }}
                                            onError={(e) => {
                                              e.target.setAttribute(
                                                "src",
                                                noImage
                                              );
                                            }}
                                          />
                                        )}
                                      </div>
                                      <p className="text-center">
                                        {portal.clientName}
                                      </p>
                                    </div>
                                  </a>
                                </Grid>
                              </>
                            );
                          })
                        ) : (
                          <>
                            <Box
                              sx={{
                                width: "100%",
                                height: "70vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {noDataText}
                            </Box>
                          </>
                        )}
                      </Grid>
                    </div>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </div>
              </div>
            </TabPanel>
          </div>
        </TabContext>
      </Box>
    </>
  );
}
export default TabsList;
