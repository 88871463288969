import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DropDownBox from "devextreme-react/drop-down-box";
import "devextreme/dist/css/dx.light.css";
import { useCallback, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "../ClientPortalList/ClientPortalList.css";
import viewRowDetails from "../../assets/viewRowDetails.png";
// import ViewAccessRowData from "../Acces Control/ViewAccessRowData"
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";
import viewImg from "../../assets/viewImg.png";
import {
  EditElemnetData,
  deleteElemnetData,
  ElementDataAudit,
} from "../../api/clients";
import DataGrid, {
  Column,
  FilterRow,
  Paging,
  // Scrolling,
  RowDragging,
  Pager,
  ColumnChooser,
  Sorting,
  Lookup,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Editing,
  Scrolling,
  StateStoring,
  Selection,
} from "devextreme-react/data-grid";
import { useAuthContext } from "../../Context/AuthProvider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DeleteAccessControlData } from "../../api/AccessControlApi/AccessControlApi";
import Alerts from "../../Alert/Alert";
import { styled } from "@mui/material/styles";
import CustomStore from "devextreme/data/custom_store";
import {
  getChsCustomization,
  postChsCustomization,
} from "../../api/Customization";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const menuItem = ["Edit", "Delete", "Audit Logs"];
const ITEM_HEIGHT = 20;

const yourDefaultValueHere = "Default Text";
const DataBaseField = [
  "User Name",
  "Password",
  "Agency ID",
  "Company ID",
  "Button",
];
const dbFieldData = {
  "User Name": "username",
  Password: "password",
  "Agency ID": "agencyId",
  "Company ID": "companyId",
  Button: "Login",
  "OTP Field": "otpField",
  "OTP Email": "graphEmailAddress",
};
const InputElementType = ["Button", "Text", "CheckBox"];
const AttributeData = ["ID", "Class", "Name"];

const InputElementTypes = ["Button", "Text", "Checkbox"];
const databaseFields = [
  "User Name",
  "Password",
  "Agency ID",
  "Company ID",
  "Button",
  "OTP Field",
  "OTP Email",
];
const buttonIntent = ["Login", "Next", "Submit", "OTP", "otpContinue"];
const attributes = ["ID", "Class", "Name"];
const formInputCategoryLabelsText = {
  Button: "Button Intent",
  Text: "Database Field",
  Checkbox: "Database Field",
};

function formatDateToCustomFormat(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

const allowedPageSizes = [50, 100, 250];
const customStore = (cellData, initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const take = options.take || 50;
      const skip = options.skip;
      const sort = options.sort || [
        {
          selector: "lastUpdatedDate",
          desc: true,
        },
      ];
      console.log(options, 'sdfnsfgd');
      let res;
      try {
        res = await ElementDataAudit(cellData, filter, take, skip, sort);
        let dataRes = [];
        let totalCount = 0;
        // Check if res.data is not null before accessing its properties
        if (res.data && res.data.data) {
          if (res.data.data.response && res.data.data.response.length) {
            totalCount = res.data.data.response.length;
            const dataRes = res.data.data.response.map((item, index) => {
              let lastUpdatedDate = "";
              if (item.lastUpdatedDate) {
                const dateString = item.lastUpdatedDate;
                const inputDateCreated = new Date(dateString);
                // Format the date in the desired format without any time zone adjustments
                lastUpdatedDate = `${inputDateCreated
                  .getUTCDate()
                  .toString()
                  .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                  month: "short",
                }).format(
                  inputDateCreated
                )}-${inputDateCreated.getUTCFullYear()} ${inputDateCreated
                  .getUTCHours()
                  .toString()
                  .padStart(2, "0")}:${inputDateCreated
                  .getUTCMinutes()
                  .toString()
                  .padStart(2, "0")}:${inputDateCreated
                  .getUTCSeconds()
                  .toString()
                  .padStart(2, "0")}`;
              }
              return {
                id: index + 1,
                changedBy: item.changedBy,
                fieldName: item.fieldName,
                lastUpdatedDate: lastUpdatedDate,
                newValue: item.newValue,
                oldValue: item.oldValue,
              };
            });

            return {
              data: dataRes,
              totalCount: res.data.data.count,
            };
          }
        }
      } catch (error) {
        // Handle other errors here
        // setStatus({
        //     display: true,
        //     text: `${error.code} , Please contact the administrator`,
        //     statusType: "warning",
        // })
      }

      // If the response is null or doesn't contain the expected properties
      return {
        data: [
          {
            id: null,
            data: "no data",
          },
        ],
        totalCount: 0,
      };
    },
  });

function ManuElementConfig({
  updatedBy,
  clientId,
  idValue,
  cellData,
  drawerOpener,
  refresh,
  isSidebarOpenParent,
}) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [gridBoxValue, setGridBoxValue] = useState([]);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [viewDetails, setViewDetails] = useState(false);
  const [cellDetails, setCellDetails] = useState({});
  const [_cellData, setCellData] = useState({});
  const [ViewAccessRowDataDialog, setViewAccessRowDataDialog] = useState(false);
  const [portalDataGrid, setPortalDataGrid] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [anchorEl, setAnchorEl] = useState({ element: null, index: -1 });
  const [formsData, setFormsData] = useState({});
  const [formInputCategoryLabels, setFormInputCategoryLabels] = useState(
    formInputCategoryLabelsText.Text
  );
  const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";
  const [clientListData, setClientListData] = useState([]);
  const [InputElementTypesDynamicData, setInputElementTypesDynamicData] =
    useState([]);
  const [openAudit, setOpenAudit] = useState(false);
  const { accessToken, userInfo } = useAuthContext();
  const [AuditLogsData, setAuditLogsData] = useState([]);

  // const { accessToken } = useAuthContext();
  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  const open = Boolean(anchorEl.element);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setSelectedOption(null);
    setAnchorEl({ element: null, index: -1 });
  };

  useEffect(() => {
    setClientListData(customStore(cellData));
  }, []);

  const handleClick = (event, index, cellData) => {
    setCellData(cellData);
    const { pageX, pageY } = event;
    const _pageY = pageY - document.documentElement.scrollTop;
    setMenuPosition({ x: pageX, y: _pageY });
    setAnchorEl({ element: event.currentTarget, index });
  };

  useEffect(() => {
    if (selectedOption === "Edit") {
      toggleSidebar();
    }
  }, [selectedOption]);

  const handleCloseMenu = () => {
    setAnchorEl({ element: null, index: -1 });
  };
  const handleMenuItemClick = (option) => {
    setSelectedOption(option);
    if (option === "Audit Logs") {
      setOpenAudit(true);
    } else if (option === "Delete") {
      setViewDetails(!viewDetails);
    }
  };

  const handleClose = () => {
    setViewDetails(false);
    setFormsData({});
  };

  useEffect(() => {
    if (formsData.inputElementType === "Button") {
      setFormInputCategoryLabels(
        formInputCategoryLabelsText[formsData.inputElementType]
      );
      setInputElementTypesDynamicData(buttonIntent);
      setFormsData({ ...formsData, dbField: null });
    } else if (formsData.inputElementType === "Text") {
      setFormInputCategoryLabels(
        formInputCategoryLabelsText[formsData.inputElementType]
      );
      setInputElementTypesDynamicData(databaseFields);
      setFormsData({ ...formsData, buttonIntent: null });
    } else if (formsData.inputElementType === "CheckBox") {
      setFormInputCategoryLabels("Database Field");
      setInputElementTypesDynamicData(databaseFields);
      setFormsData({ ...formsData, buttonIntent: null });
    }
    setFormsData((prevState) => ({ ...prevState }));
  }, [formsData.inputElementType]);

  async function handleDelete() {
    try {
      if ((accessToken !== "null", userInfo)) {
        const apiDeleteElement = await deleteElemnetData(
          accessToken,
          userInfo,
          cellData
        );
        if (
          apiDeleteElement.data.data.message ===
          "Element Configuration Updated Successfully!!"
        ) {
          setStatus({
            display: true,
            text: "Data Deleted successfully",
            statusType: "success",
          });
          setViewDetails(false);
          // GetEleConfig(accessToken);
          handleCloseMenu();
          refresh();
        }
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (cellData) {
      setFormsData({
        attribute: cellData.data.Attribute,
        attributeValue: cellData.data.AttributeValue,
        dbField: cellData.data.dbField,
        inputElementType: cellData.data.inputElementType,
        buttonIntent: cellData.data.buttonIntent,
      });
    }
  }, [cellData]);

  async function handleFormSubmit(e) {
    e.preventDefault();
    let _formData = {
      ...formsData,
      dbField: dbFieldData[formsData.dbField],
    };
    if ((accessToken !== "null", userInfo)) {
      try {
        const apiResponse = await EditElemnetData(
          accessToken,
          _formData,
          idValue,
          updatedBy,
          userInfo
        );

        if (
          apiResponse.data.data.message ===
          "Element Configuration Updated Successfully!!"
        ) {
          setStatus({
            display: true,
            text: "Data Edited successfully",
            statusType: "success",
          });
          setIsSidebarOpen(false);
          refresh();
        } else if (
          apiResponse.data.data.message === "Database Field Already Exist!!"
        ) {
          setStatus({
            display: true,
            text: "Database Field Already Exist",
            statusType: "warning",
          });
        }
      } catch (error) {
        setStatus({
          display: true,
          text: `Something went wrong`,
          statusType: "error",
        });
      }
    }
  }
  function handleCloseAudit() {
    setOpenAudit(false);
  }

  const saveState = useCallback(
    async (state) => {
      if (userInfo.userName) {
        const res = await postChsCustomization(
          userInfo.userName,
          "ElementConfigurationAudit",
          state
        );
      }
    },
    [userInfo]
  );

  const loadState = useCallback(async () => {
    if (userInfo.userName) {
      const res = await getChsCustomization(
        userInfo.userName,
        "ElementConfigurationAudit"
      );
      return res.data.data;
    }
  }, [userInfo]);

  return (
    <>
      {status.display && <Alerts status={status} sx={{ margin: "10px" }} />}
      {selectedOption === "Delete" && (
        <Dialog
          open={viewDetails}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          style={{
            backdropFilter: "blur(4px)",
            backgroundColor: "rgba(111, 126, 140, 0.2)",
            transition: "opacity 100ms ease 0s",
            zIndex: 1600,
          }}
        >
          <DialogTitle
            style={{
              background: "#3a86ff",
              marginBottom: "3rem",
              color: "white",
            }}
          >
            {"Alert"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{ color: "#14213d" }}
              id="alert-dialog-slide-description"
            >
              Are you sure you want to delete this record?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleDelete();
              }}
              style={{ background: "#38b000" }}
              variant="contained"
            >
              Yes
            </Button>
            <Button
              style={{ background: "#c9184a" }}
              variant="contained"
              onClick={handleClose}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {selectedOption === "Audit Logs" && (
        <div
          className="audit-div"
          style={
            {
              // height: "100%",
              // // width: "100%",
              // marginTop: "1%",
              // width: "80px"
            }
          }
        >
          <BootstrapDialog
            onClose={handleCloseAudit}
            aria-labelledby="customized-dialog-title"
            open={openAudit}
            className="dialog-box"
            sx={{
              "& .MuiDialog-paper": {
                maxWidth: "80%", // Set your desired width here
              },
            }}
          >
            <DialogTitle
              sx={{ m: 0, p: 2, background: "#008ce3", color: "white" }}
              id="customized-dialog-title"
              className="dialog-text"
            >
              Audit Logs
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseAudit}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "white",
              }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
            <Box
              className="main-div"
              style={{
                height: "100vh",
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "20px",
              }}
            >
              <DataGrid
                dataSource={clientListData}
                keyExpr="id"
                key="index"
                height="90%"
                width="100%"
                showRowLines={true}
                showColumnLines={true}
                hoverStateEnabled
                showBorders={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnMinWidth={50}
                columnResizingMode="widget"
                loadPanel={{ enabled: true }}
              >
                {/* <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={loadState}
                  customSave={saveState}
                /> */}
                <Scrolling mode="standard" />
                {/* <Lookup dataSource={store} valueExpr="ID" displayExpr="Name" /> */}
                {/* <Toolbar>
                  <Item>
            
                  </Item>
              
                  <Item name="columnChooserButton" />
                  <Item name="exportButton" />
                </Toolbar>
                <ColumnChooser enabled={true} />
                <Export enabled={true} allowExportSelectedData={false} /> */}
                <ColumnFixing enabled={true} />
                {/* <LoadPanel visible={loaderCircular} /> */}
                <FilterRow visible={true} />
                {/* <FilterPanel visible={true} /> */}
                {/* <Editing
                            // mode="row"
                            useIcons={true}
                            allowUpdating={false}
                            allowDeleting={true}
                        /> */}

                <Scrolling columnRenderingMode="virtual" />

                {/* <ColumnChooser enabled={true} />
                <Export enabled={true} allowExportSelectedData={false} /> */}
                <ColumnFixing enabled={true} />

                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={allowedPageSizes}
                  showInfo={true}
                  visible={true}
                />

                <Paging defaultPageSize={50} enabled={true} />

                <FilterRow visible={true} />
                <Column
                  allowSorting={false}
                  width="50px"
                  allowFiltering={false}
                  alignment="left"
                  caption="S.No"
                  dataField="id"
                  allowReordering={false}
                />

                <Column
                  allowReordering={true}
                  width="200px"
                  caption="Field Name"
                  dataField="fieldName"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  allowReordering={true}
                  width="200px"
                  caption="Old Value"
                  dataField="oldValue"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  width="210px"
                  caption="New Value"
                  dataField="newValue"
                />

                <Column
                  allowReordering={true}
                  width="200px"
                  caption="Changed By"
                  dataField="changedBy"
                  // cellRender={setStatus}
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  allowReordering={true}
                  width="200px"
                  caption="Last Updated Date "
                  dataField="lastUpdatedDate"
                  dataType="date"
                  format={filterDateFormat}
                  // cellRender={setStatus}
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
              </DataGrid>
            </Box>
          </BootstrapDialog>
        </div>
      )}

      <Drawer
        anchor="right"
        open={isSidebarOpen}
        onClose={toggleSidebar}
        PaperProps={{ style: { width: 600 } }}
      >
        <DialogTitle
          style={{
            background: "rgb(0 140 227)",
            color: "white",
            fontSize: "20px",
          }}
          id="responsive-dialog-title"
        >
          Edit
        </DialogTitle>
        <IconButton
          size="large"
          aria-label="close"
          onClick={() => setIsSidebarOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <form onSubmit={handleFormSubmit}>
          <Box>
            <Grid
              container
              spacing={2}
              sx={{ width: "95%", margin: "15px 0px 15px 0px" }}
            >
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                {/* Group By field */}
                <Box
                  sx={{ flexDirection: "column", width: "100%" }}
                  title={isSidebarOpen ? "Can't Edit" : ""}
                >
                  <span className="fieldName">
                    Input Element Type<span className="asterisk">*</span>
                  </span>
                  <Autocomplete
                    sx={{
                      margin: "5px",
                      width: "95%",
                      pointerEvents: isSidebarOpen ? "none" : "",
                      background: isSidebarOpen ? "#dddddd" : "",
                    }}
                    required
                    id="outlined-required"
                    label="Input Element Type"
                    name="inputElementType"
                    options={InputElementType}
                    // getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Input Element Type"
                        variant="outlined"
                      />
                    )}
                    value={
                      formsData.inputElementType !== undefined &&
                      formsData.inputElementType !== null
                        ? formsData.inputElementType
                        : ""
                    }
                    onChange={(e, value) => {
                      setFormsData({
                        ...formsData,
                        inputElementType: value,
                      });
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                {/* Group By field */}
                <Box
                  sx={{ flexDirection: "column", width: "100%" }}
                  title={isSidebarOpen ? "Can't Edit" : ""}
                >
                  {formInputCategoryLabels}
                  <span className="fieldName">
                    <span className="asterisk">*</span>
                  </span>
                  <Autocomplete
                    sx={{
                      margin: "5px",
                      width: "95%",
                      pointerEvents: isSidebarOpen ? "none" : "",
                      background: isSidebarOpen ? "#dddddd" : "",
                    }}
                    required
                    id="outlined-required"
                    label="Database Field"
                    name="databaseField"
                    options={InputElementTypesDynamicData}
                    // getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Database Field"
                        variant="outlined"
                      />
                    )}
                    value={
                      formsData.dbField !== undefined &&
                      formsData.dbField !== null
                        ? formsData.dbField
                        : formsData.buttonIntent !== undefined &&
                          formsData.buttonIntent !== null
                        ? formsData.buttonIntent
                        : ""
                    }
                    onChange={(e, value) => {
                      if (formInputCategoryLabels === "Button Intent") {
                        setFormsData({ ...formsData, buttonIntent: value });
                      } else {
                        setFormsData({ ...formsData, dbField: value });
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                {/* Group By field */}
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    Atrribute<span className="asterisk">*</span>
                  </span>
                  <Autocomplete
                    sx={{ margin: "5px", width: "95%" }}
                    required
                    id="outlined-required"
                    label="Attribute"
                    name="attribute"
                    options={AttributeData}
                    value={
                      formsData.attribute !== undefined &&
                      formsData.attribute !== null
                        ? formsData.attribute
                        : ""
                    }
                    // getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Atrribute"
                        variant="outlined"
                      />
                    )}
                    onChange={(e, value) => {
                      setFormsData({ ...formsData, attribute: value });
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                <Box sx={{ flexDirection: "column", width: "100%" }}>
                  <span className="fieldName">
                    Attribute Value<span className="asterisk">*</span>
                  </span>
                  <TextField
                    sx={{ margin: "5px", width: "95%" }}
                    required
                    id="outlined-required"
                    // label="Attribute Value"
                    name="attributeValue"
                    // value={formData.clientname}
                    value={
                      formsData.attributeValue !== undefined &&
                      formsData.attributeValue !== null
                        ? formsData.attributeValue
                        : ""
                    }
                    onChange={(e) => {
                      setFormsData({
                        ...formsData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button width="100" variant="contained" type="submit">
                  Add
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Drawer>
      <div className="row-container">
        <div style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}>
          {cellData.data.dbField}
        </div>
        <div className="cellIcon" style={{ flex: 0 }}>
          <IconButton
            title="action"
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={(e) => {
              handleClick(e, cellData.rowIndex, cellData.data);
            }}
          >
            <MoreVertIcon
              fontSize="medium"
              style={{ transform: "rotate(90deg)" }}
            />
          </IconButton>
        </div>
        {
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl.element}
            open={open && anchorEl.index === cellData.rowIndex}
            onClose={handleCloseMenu}
            PaperProps={{
              style: {
                // maxHeight: ITEM_HEIGHT * 4.5,
                // width: "15ch",
                fontSize: "10px",
                marginLeft: "15px",
                // background: 'red',
                // left: menuPosition.x+'px!important',
                // top: menuPosition.y+'px'
              },
            }}
            sx={{
              "& .MuiMenu-paper": {
                left: menuPosition.x + "px!important",
                top: menuPosition.y + "px!important",
              },
            }}
          >
            {menuItem.map((option) => (
              <MenuItem
                key={option}
                selected={option === "selectedOption"}
                onClick={() => {
                  handleMenuItemClick(option);

                  // handleCloseMenu();
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        }
      </div>
    </>
  );
}
export default ManuElementConfig;
