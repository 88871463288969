import axios from "axios";

import * as serverContstant from "../constant/serverConstant";

export async function getUserDetails(accessToken) {
   return axios.get(
    serverContstant.SIGNIN_DEV +
      "/" +
      serverContstant.API_ENDPOINTS.GET_USER_DETAILS +
      "/userInfo",
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
}
