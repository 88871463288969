import { useState, useEffect } from "react";

import Header from "./Header";
import Sidebar from "./Sidebar";

function Dashboard({
  drawerOpener,
  setDrawerOpener,

  // searchKey,
  // setSearchKey,
}) {
  const [navigateComponent, SetnavigateComponent] = useState("grid");

  function getView(view) {
    SetnavigateComponent(view);
  }
  return (
    <div>
      {navigateComponent === "grid" && (
        <>
          {" "}
          <Header
            // searchKey={searchKey}
            // setSearchKey={setSearchKey}

            getView={getView}
            drawerOpener={drawerOpener}
            setDrawerOpener={setDrawerOpener}
          />{" "}
        </>
      )}
    </div>
  );
}

export default Dashboard;
