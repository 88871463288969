import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import "../Admin/ViewSidebar.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { deleteDataCandidate } from "../api/AdminForm";
import { useAuthContext } from "../Context/AuthProvider";
import Alerts from "../Alert/Alert";
function ViewSidebar({
  viewDetails,
  setViewDetails,
  viewAccountDetails,
  _cellData,
  selectedTab,
  refresh,
  _refresh,
}) {
  const [Yesloading, setYesLoading] = useState(false);
  const { accessToken, userInfo } = useAuthContext();
  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  const drawerWidth = 600;

  const handleClose = () => {
    setViewDetails(false);
  };

  async function handleDelete() {
    const payload = {
      id: _cellData.clientId,
      updatedBy: userInfo.userName,
      deleted: 1,
    };
    if (accessToken !== "null" && payload) {
      try {
        const res = await deleteDataCandidate(accessToken, payload);
        setViewDetails(false);
        setStatus({
          display: true,
          text: "Record Deleted successfully",
          statusType: "success",
        });
        if (selectedTab == 1) {
          refresh();
        } else {
          _refresh();
        }
      } catch (error) {
        setViewDetails(false);
        setStatus({
          display: true,
          text: `Something went wrong`,
          statusType: "warning",
        });
      }
    }
  }

 
  return (
    <>
      {status.display && <Alerts status={status} sx={{ margin: "10px" }} />}
      <Dialog
        open={viewDetails}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{
          backdropFilter: "blur(4px)",
          backgroundColor: "rgba(111, 126, 140, 0.2)",
          transition: "opacity 100ms ease 0s",
          zIndex: 1600,
        }}
      >
        <DialogTitle
          style={{
            background: "#3a86ff",
            marginBottom: "3rem",
            color: "white",
          }}
        >
          {"Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ color: "#14213d" }}
            id="alert-dialog-slide-description"
          >
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDelete();
            }}
            style={{ background: "#38b000" }}
            variant="contained"
          >
            Yes{" "}
            {Yesloading && (
              <Box sx={{ display: "flex", m: 1.5, position: "relative" }}>
                <CircularProgress
                  size={24}
                  sx={{
                    color: "wheat",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              </Box>
            )}
          </Button>
          <Button
            style={{ background: "#c9184a" }}
            variant="contained"
            onClick={handleClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default ViewSidebar;
