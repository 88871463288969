import "./ClientPortalList.css";
import Box from "@mui/material/Box";
import { useCallback, useEffect, useRef, useState } from "react";
import { Tabs, Tab, Paper, Tooltip, IconButton } from "@mui/material";
import { Button as Button2 } from "devextreme-react/button";
import { Link, Router, useNavigate } from "react-router-dom";
import { GroupBySelection, get_Post_Client_Data } from "../../api/AdminForm";
import ClientPortalRowAudit from "../../Admin/ClientPortalList/ClientPortalRowAudit";
import DataGrid, {
  Column,
  FilterRow,
  Paging,
  Pager,
  ColumnChooser,
  Item,
  Export,
  ColumnFixing,
  Scrolling,
  RemoteOperations,
  Selection,
  GroupPanel,
  Grouping,
  Toolbar,
  StateStoring,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { useAuthContext } from "../../Context/AuthProvider";
import ClientForm from "./ClientForm";
import CustomStore from "devextreme/data/custom_store";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Alerts from "../../Alert/Alert";
import ElementConfig from "../../Admin/ClientPortalList/ElementConfig";
import clean from "../../assets/clean.png";
import ClearFiltericon from "../../assets/ClearFiltericon.png";
import {
  getChsCustomization,
  postChsCustomization,
} from "../../api/Customization";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";

const allowedPageSizes = [50, 100, 250];
const customStore = (groupId, setStatus, initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const take = options.take || 50;
      const skip = options.skip;
      const sort = options.sort || [
        {
          selector: "updatedAt",
          desc: true,
        },
      ];
      let filterValue = filter;
      if (filter) {
        if (filter.includes("and")) {
          filter.forEach((element) => {
            if (element instanceof Array) {
              if (
                element[0] === "isScreenshotEnabled" ||
                element[0] === "isCvSent" ||
                element[0] === "isAutoProcessingEnabled"
              ) {
                if (element[2]) {
                  element[2] = 1;
                } else {
                  element[2] = 0;
                }
              }
            }
          });
        } else {
          if (
            filter[0] === "isScreenshotEnabled" ||
            filter[0] === "isCvSent" ||
            filter[0] === "isAutoProcessingEnabled"
          ) {
            if (filter[2]) {
              filter[2] = 1;
            } else {
              filter[2] = 0;
            }
          } else {
            filterValue = filter;
          }
        }
      }

      try {
        const res = await get_Post_Client_Data(
          filterValue,
          groupId,
          take,
          skip,
          sort
        );
        if (!res.data.data || res.data.data.response.length === 0) {
          return {
            data: [],
            totalCount: 0,
          };
        }
        let dataRes = [];
        if (res.data.data) {
          const startIndex = (options.skip || 0) + 1;
          dataRes = res.data.data.response.map((item, index) => {
            let createDate = "";
            let updatedDate = "";
            if (item.createdAt) {
              const dateStringUpdated = item.createdAt;
              const inputDate = new Date(dateStringUpdated);
              createDate = `${inputDate
                .getUTCDate()
                .toString()
                .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                month: "short",
              }).format(inputDate)}-${inputDate.getUTCFullYear()} ${inputDate
                .getUTCHours()
                .toString()
                .padStart(2, "0")}:${inputDate
                .getUTCMinutes()
                .toString()
                .padStart(2, "0")}:${inputDate
                .getUTCSeconds()
                .toString()
                .padStart(2, "0")}`;
            } else {
              createDate = "";
            }
            if (item.updatedAt) {
              const dateStringUpdated = item.updatedAt;
              const inputDate = new Date(dateStringUpdated);
              updatedDate = `${inputDate
                .getUTCDate()
                .toString()
                .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                month: "short",
              }).format(inputDate)}-${inputDate.getUTCFullYear()} ${inputDate
                .getUTCHours()
                .toString()
                .padStart(2, "0")}:${inputDate
                .getUTCMinutes()
                .toString()
                .padStart(2, "0")}:${inputDate
                .getUTCSeconds()
                .toString()
                .padStart(2, "0")}`;
            } else {
              updatedDate = "";
            }
            return {
              id: startIndex + index,
              atsName: item.atsName,
              atsId: item.atsId,
              clientName: item.clientName,
              companyId: item.companyId,
              companyName: item.companyName,
              loginUrl: item.loginURL,
              username: item.username,
              logoUrl: item.logoURL,
              isScreenshotEnabled: item.isScreenshotEnabled,
              isCvSent: item.isCVSent,
              password: item.PASSWORD,
              agencyId: item.agencyId,
              categoryId: item.categoryId,
              groupName: item.groupName,
              jobsUrl: item.jobsURL,
              jdUrl: item.jdURL,
              authenticationType: item.authenticationType,
              agencyCompanyId: item.agencyCompanyId,
              groupId: item.groupId ? item.groupId : "",
              clientId: item.clientId,
              contactEmail: item.contactEmail,
              isAutoProcessingEnabled: item.isAutoProcessingEnabled,
              createdBy: item.createdBy,
              updatedBy: item.updatedBy,
              createdAt: createDate,
              updatedAt: updatedDate,
            };
          });
          return {
            data: dataRes,
            totalCount: res.data.data.count,
          };
        }
      } catch (error) {
        setStatus({
          display: true,
          text: `Something went wrong`,
          statusType: "warning",
        });
        return {
          data: [],
          totalCount: 0,
        };
      }
    },
  });

const dataGridTable = [
  {
    searchText: "",
    allowedPageSizes: [50, 100, 250],
    filterPanel: {
      filterEnabled: true,
    },
    pageIndex: 0,
    columns: [
      {
        visibleIndex: 0,
        dataField: "id",
        name: "id",
        dataType: "number",
        width: "50px",
        visible: true,
      },
      {
        visibleIndex: 1,
        dataField: "accountName",
        name: "accountName",
        width: "400px",
        visible: true,
      },
      {
        visibleIndex: 2,
        dataField: "clientName",
        name: "clientName",
        dataType: "string",
        width: "350px",
        visible: true,
      },
      {
        visibleIndex: 3,
        dataField: "atsName",
        name: "atsName",
        dataType: "string",
        width: "300px",
        visible: true,
      },
      {
        visibleIndex: 4,
        dataField: "groupName",
        name: "groupName",
        dataType: "string",
        width: "250px",
        visible: true,
      },
      {
        visibleIndex: 5,
        dataField: "companyId",
        name: "companyId",
        dataType: "string",
        width: "250px",
        visible: false,
      },
    ],
    pageSize: 50,
    selectedRowKeys: [],
    filterValue: null,
  },
];
const onExporting = (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Main sheet");
  const selectedRows = e.component.getSelectedRowsData();

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
    customizeExportData: (columns, rows) => {
      return {
        columns,
        rows: selectedRows,
      };
    },
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "ClientList.xlsx"
      );
    });
  });
};

function ClientPortalList({ drawerOpener }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("1");
  const [groupByDropdown, setGroupByDropdown] = useState([]);
  const [categoryOptionData, setCategoryOptionData] = useState([]);
  const [accountOptionData, setAccountOptionData] = useState([]);
  const [anchorEl, setAnchorEl] = useState({ element: null, index: -1 });
  const [clearCustom, setClearCustom] = useState(false);
  const [openAudit, setOpenAudit] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [cellData, setCellData] = useState({});
  const [updateList, setUpdateList] = useState(true);
  const [clientListData, setClientListData] = useState([]);
  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  const navigate = useNavigate();
  const [showPasswords, setShowPasswords] = useState({});
  const { accessToken } = useAuthContext();
  const [clearCustomization, setClearCustomization] = useState(false);
  const [clearCustomizationPopUp, setClearCustomizationPopUp] = useState(false);
  const dataGridRef = useRef(null);
  const _dataGridRef = useRef(null);
  const { userInfo } = useAuthContext();

  const handleContextMenu = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    // clientDatRef.current = customStore(selectedTab, setStatus);
    setClientListData(customStore(selectedTab, setStatus));
  }, [selectedTab, updateList]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (
      isSidebarOpen &&
      !categoryOptionData.length &&
      !accountOptionData.length
    ) {
      AdminFormGroupBy();
    }
  }, [accessToken, isSidebarOpen]);

  async function AdminFormGroupBy() {
    try {
      const groupData = await GroupBySelection();
      if (groupData) {
        const groupByData = groupData.data.data.response.map((item, index) => {
          return item;
        });

        if (groupData.data.status === "succeed") {
          if (groupData.data.data != null) {
            setGroupByDropdown(groupByData);
          }
        }
      }
    } catch (error) {}
  }
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  useEffect(() => {
    if (selectedOption === "Edit") {
      toggleSidebar();
    }
  }, [selectedOption]);

  const handleCellPrepared = (e) => {
    if (e.cellElement.ariaLabel === "Column Account Name") {
      e.cellElement.style.backgroundColor = "#008ce3";
    }
  };

  const refresh = (e) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh();
    }
  };

  const clearFilters = (e) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.clearFilter();
      dataGridRef.current.instance.clearSelection();
    }
  };

  const _refresh = (e) => {
    if (_dataGridRef.current) {
      _dataGridRef.current.instance.refresh();
    }
  };

  const _clearFilters = (e) => {
    if (_dataGridRef.current) {
      _dataGridRef.current.instance.clearFilter();
      _dataGridRef.current.instance.clearSelection();
    }
  };
  let portalType;
  if (selectedTab === "1") {
    portalType = "ClientATS";
  } else {
    portalType = "ClientATSBackOffice";
  }

  const saveState = useCallback(
    async (state) => {
      if (userInfo.userName) {
        const res = await postChsCustomization(
          userInfo.userName,
          portalType,
          state
        );
      }
    },
    [userInfo, portalType]
  );

  const loadState = useCallback(async () => {
    if (userInfo.userName) {
      const res = await getChsCustomization(userInfo.userName, portalType);
      return res.data.data;
    }
  }, [userInfo, portalType, clearCustom]);

  const clearCustomizationDataGrid = () => {
    if (selectedTab === "1") {
      const dataGridInstance = dataGridRef.current.instance;
      const columns = dataGridInstance.getVisibleColumns();
      columns.forEach((column) => {
        column.visible = true;
        column.showInColumnChooser = true;
      });
      dataGridInstance.option("columns", columns);
      dataGridInstance.clearFilter();
      dataGridInstance.clearSelection();
      setClearCustomizationPopUp(false);
    } else {
      const dataGridInstance = _dataGridRef.current.instance;
      const columns = dataGridInstance.getVisibleColumns();
      columns.forEach((column) => {
        column.visible = true;
        column.showInColumnChooser = true;
      });
      dataGridInstance.option("columns", columns);
      dataGridInstance.clearFilter();
      dataGridInstance.clearSelection();
      setClearCustomizationPopUp(false);
    }
  };

  function handleElementConfig(e, data) {
    e.preventDefault();
    // console.log(data, "locationparent");
    navigate("/Admin/ElementConfiguration", { state: data });
  }

  const customChanges = {
    icon: "undo",
    text: "Clear",
    type: "default",
    onClick: () => clearCustomizationDataGrid(),
  };
  const cancelCustomchanges = {
    text: "Close",
    icon: "remove",
    type: "danger",
    onClick: () => {
      setClearCustomizationPopUp(false);
    },
  };

  const cellRender = (data) => {
    const password = data.data.password;
    const rowIndex = data.rowIndex;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {showPasswords[rowIndex] ? (
          <span>{password}</span>
        ) : (
          <span>*********</span>
        )}
        <IconButton onClick={() => togglePasswordVisibility(rowIndex)}>
          {showPasswords[rowIndex] ? (
            <Tooltip title="Hide" arrow>
              <VisibilityOff />
            </Tooltip>
          ) : (
            <Tooltip title="Show" arrow>
              <Visibility />
            </Tooltip>
          )}
        </IconButton>
      </div>
    );
  };

  const togglePasswordVisibility = (rowIndex) => {
    setShowPasswords((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  };

  return (
    <>
      {status.display && <Alerts status={status} sx={{ margin: "10px" }} />}
      <Popup
        visible={clearCustomizationPopUp}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        container="body"
        width={500}
        height={200}
        title="Alert"
      >
        <Position my="center" of={"main_dataGridClass"} collision="fit" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={customChanges}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelCustomchanges}
        />
        <p>Your custom changes made by you on grid will be removed..</p>
      </Popup>
      <TabContext value={selectedTab}>
        <div className={drawerOpener ? "add-tabs" : "close-tabs"}>
          <Box sx={{ width: "100%", marginTop: "50px", height: "15px" }}>
            <Paper square>
              <TabList
                onChange={handleTabChange}
                sx={{ marginLeft: drawerOpener ? "240px" : "60px" }}
              >
                <Tab
                  sx={{
                    textTransform: "none",
                  }}
                  className="tab-text-style"
                  label="Candidate Portal"
                  value="1"
                />
                <Tab
                  sx={{
                    textTransform: "none",
                  }}
                  className="tab-text-style"
                  label="Back Office"
                  value="2"
                />
              </TabList>
            </Paper>
          </Box>
          <TabPanel value="1">
            <>
              <ClientForm
                isSidebarOpen={isSidebarOpen}
                cellData={cellData}
                drawerOpener={drawerOpener}
                view="top"
                setUpdateList={setUpdateList}
                refresh={refresh}
                selectedTab={selectedTab}
              ></ClientForm>
              <div
                className="main-div"
                style={{
                  height: "80vh",
                  marginTop: "1%",
                  marginLeft: drawerOpener ? "255px" : "40px",
                  width: drawerOpener ? "86%" : "98%",
                }}
              >
                <DataGrid
                  dataSource={clientListData}
                  keyExpr="id"
                  key="id"
                  height="90%"
                  width="100%"
                  showRowLines={true}
                  showColumnLines={true}
                  hoverStateEnabled
                  showBorders={true}
                  allowColumnResizing={true}
                  allowColumnReordering={true}
                  columnMinWidth={50}
                  loadPanel={{ enabled: true }}
                  onCellPrepared={handleCellPrepared}
                  filterSyncEnabled={true}
                  remoteOperations={true}
                  ref={dataGridRef}
                  columnResizingMode="widget"
                  onExporting={onExporting}
                  noDataText="No record found"
                >
                  <Selection
                    mode="multiple"
                    showCheckBoxesMode="always"
                    selectAllMode="page"
                    showSelectionControls={true}
                    selectByRowClick={false}
                  />
                  {/* <Selection mode="multiple" selectByRowClick={false} /> */}
                  <GroupPanel visible={true} />
                  <Grouping autoExpandAll={true} />
                  <Toolbar>
                    <Item location="after">
                      <Button2
                        hint="Refresh"
                        icon="refresh"
                        onClick={refresh}
                      />
                    </Item>
                    <Item>
                      <img
                        title="Clear Filter"
                        className="clean"
                        src={ClearFiltericon}
                        onClick={() => {
                          const isFilterApplied =
                            dataGridRef.current.instance.getCombinedFilter();
                          if (isFilterApplied != undefined) {
                          }
                          clearFilters();
                        }}
                      ></img>
                    </Item>
                    <Item name="columnChooserButton" />
                    <Item name="exportButton" />
                    <Item>
                      <img
                        title="Clear Customization"
                        className="clean"
                        src={clean}
                        onClick={() => {
                          setClearCustomizationPopUp(true);
                        }}
                      ></img>
                    </Item>
                  </Toolbar>
                  <Scrolling columnRenderingMode="virtual" />
                  <ColumnChooser enabled={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <ColumnFixing enabled={true} />
                  <FilterRow visible={true} />
                  <Column
                    allowSorting={false}
                    width="50px"
                    allowFiltering={false}
                    fixed={true}
                    alignment="left"
                    caption="S.No"
                    dataField="id"
                    allowReordering={false}
                  />
                  <Column
                    caption="Account Name"
                    dataField="companyName"
                    allowSorting={true}
                    fixed={true}
                    allowFiltering={true}
                    width={"500px"}
                    editorOptions={{
                      showClearButton: true,
                    }}
                    cellRender={(cellData) => {
                      return (
                        <>
                          <ClientForm
                            cellData={cellData}
                            view="grid"
                            setUpdateList={setUpdateList}
                            refresh={refresh}
                            selectedTab={selectedTab}
                          />
                          <ElementConfig cellData={cellData} />
                        </>
                      );
                    }}
                  />
                  <Column
                    dataField="clientName"
                    allowSorting={true}
                    width={"350px"}
                    caption="Client Name"
                    editorOptions={{
                      showClearButton: true,
                    }}
                    cellRender={(cellData) => {
                      return (
                        <>
                          <span onContextMenu={handleContextMenu}>
                            <Link
                              to="/Admin/ElementConfiguration"
                              className="hoverOption"
                            >
                              <span
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                                onClick={(e) => {
                                  handleElementConfig(e, cellData.data);
                                }}
                                title={cellData.data.clientName}
                              >
                                {cellData.data.clientName}
                              </span>
                            </Link>
                          </span>
                        </>
                      );
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="300px"
                    caption="ATS Name"
                    dataField="atsName"
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />

                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="300px"
                    caption="Group By"
                    dataField="groupName"
                    // cellRender={setStatus}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    caption="Company Name/ID"
                    dataField="agencyCompanyId"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="loginUrl"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="jobsUrl"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="jdUrl"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    // type="password"
                    dataField="password"
                    visible={false}
                    cellRender={cellRender}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="contactEmail"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="authenticationType"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    caption="Auto Duplicate Check Process"
                    dataField="isAutoProcessingEnabled"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="username"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="isScreenshotEnabled"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="isCvSent"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    caption="Created By"
                    dataField="createdBy"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    caption="Modified By"
                    dataField="updatedBy"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    caption="Modified At"
                    dataField="updatedAt"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    caption="Created At"
                    dataField="createdAt"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <StateStoring
                    enabled={true}
                    type="custom"
                    customLoad={loadState}
                    customSave={saveState}
                  />
                  <Paging defaultPageSize={50} enabled={true} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showInfo={true}
                    visible={true}
                  />
                  <RemoteOperations groupPaging={true} />
                </DataGrid>
              </div>
            </>
          </TabPanel>
          <TabPanel value="2">
            <>
              <ClientForm
                selectedTab={selectedTab}
                isSidebarOpen={isSidebarOpen}
                cellData={cellData}
                drawerOpener={drawerOpener}
                view="top"
                setUpdateList={setUpdateList}
                _refresh={_refresh}
              />
              <div
                className="main-div"
                style={{
                  height: "80vh",
                  marginTop: "1%",
                  marginLeft: drawerOpener ? "255px" : "40px",
                  width: drawerOpener ? "86%" : "98%",
                }}
              >
                <DataGrid
                  dataSource={clientListData}
                  keyExpr="id"
                  key="id"
                  height="90%"
                  width="100%"
                  showRowLines={true}
                  showColumnLines={true}
                  hoverStateEnabled
                  showBorders={true}
                  allowColumnResizing={true}
                  allowColumnReordering={true}
                  columnMinWidth={50}
                  loadPanel={{ enabled: true }}
                  onCellPrepared={handleCellPrepared}
                  filterSyncEnabled={true}
                  remoteOperations={true}
                  onExporting={onExporting}
                  columnResizingMode="widget"
                  ref={_dataGridRef}
                  noDataText="No record found"
                >
                  <Selection
                    mode="multiple"
                    showCheckBoxesMode="always"
                    selectAllMode="page"
                    // showSelectionControls={true}
                    selectByRowClick={false}
                  />
                  {/* <Selection mode="multiple" selectByRowClick={false}/> */}
                  <Scrolling columnRenderingMode="virtual" />
                  <ColumnChooser enabled={true} />
                  <Export enabled={true} allowExportSelectedData={false} />
                  <ColumnFixing enabled={true} />
                  <Toolbar>
                    <Item location="after">
                      <Button2
                        hint="Refresh"
                        icon="refresh"
                        onClick={_refresh}
                      />
                    </Item>
                    <Item>
                      <img
                        title="Clear Filter"
                        className="clean"
                        src={ClearFiltericon}
                        onClick={() => {
                          const isFilterApplied =
                            _dataGridRef.current.instance.getCombinedFilter();
                          if (isFilterApplied != undefined) {
                          }
                          _clearFilters();
                        }}
                      ></img>
                    </Item>
                    <Item name="columnChooserButton" />
                    <Item name="exportButton" />
                    <Item>
                      <img
                        title="Clear Customization"
                        className="clean"
                        src={clean}
                        onClick={() => {
                          setClearCustomizationPopUp(true);
                        }}
                      ></img>
                    </Item>
                  </Toolbar>
                  <StateStoring
                    enabled={true}
                    type="custom"
                    customLoad={loadState}
                    customSave={saveState}
                  />
                  <FilterRow visible={true} />
                  <Column
                    allowSorting={false}
                    allowFiltering={false}
                    fixed={true}
                    width="50px"
                    alignment="left"
                    caption="S.No"
                    dataField="id"
                    allowReordering={false}
                  />
                  <Column
                    caption="Account Name"
                    dataField="companyName"
                    allowSorting={true}
                    fixed={true}
                    width={"500px"}
                    editorOptions={{
                      showClearButton: true,
                    }}
                    cellRender={(cellData) => {
                      return (
                        <>
                          <ClientForm
                            cellData={cellData}
                            view="grid"
                            _refresh={_refresh}
                            selectedTab={selectedTab}
                          />
                          <ClientPortalRowAudit cellData={cellData} />
                        </>
                      );
                    }}
                  />
                  <Column
                    dataField="clientName"
                    allowSorting={true}
                    width={"350px"}
                    caption="Client Name"
                    editorOptions={{
                      showClearButton: true,
                    }}
                    cellRender={(cellData) => {
                      return (
                        <>
                          <Link
                            to="/Admin/ElementConfiguration"
                            className="hoverOption"
                          >
                            <span
                              style={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                              onClick={(e) => {
                                handleElementConfig(e, cellData.data);
                              }}
                              title={cellData.data.clientName}
                            >
                              {cellData.data.clientName}
                            </span>
                          </Link>
                        </>
                      );
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="300px"
                    caption="ATS Name"
                    dataField="atsName"
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    caption="Group"
                    dataField="groupName"
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />

                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    caption="Company Name/ID"
                    dataField="agencyCompanyId"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="loginUrl"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="jobsUrl"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="jdUrl"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="password"
                    cellRender={cellRender}
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="contactEmail"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="authenticationType"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    caption="Auto Duplicate Check Process"
                    dataField="isAutoProcessingEnabled"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="username"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="isScreenshotEnabled"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    dataField="isCvSent"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    caption="Created By"
                    dataField="createdBy"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    caption="Modified By"
                    dataField="updatedBy"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    caption="Modified At"
                    dataField="updatedAt"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Column
                    allowReordering={true}
                    allowSorting={true}
                    width="250px"
                    caption="Created At"
                    dataField="createdAt"
                    visible={false}
                    editorOptions={{
                      showClearButton: true,
                    }}
                  />
                  <Paging defaultPageSize={50} enabled={true} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showInfo={true}
                    visible={true}
                  />
                  <RemoteOperations groupPaging={true} />
                </DataGrid>
              </div>
            </>
          </TabPanel>
        </div>
      </TabContext>
    </>
  );
}
export default ClientPortalList;
