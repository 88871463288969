import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
// import "../ClientPortalList.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState, useCallback, useRef } from "react";
import clean from "../../assets/clean.png";
import ClearFiltericon from "../../assets/ClearFiltericon.png";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
// import "./AuthenticationLog.css";
import { Tabs, Tab, Paper } from "@mui/material";
import { Button as Button2 } from "devextreme-react/button";
import DataGrid from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import {
  Selection,
  // Box,
  Column,
  FilterRow,
  Paging,
  // Scrolling,
  RowDragging,
  Pager,
  ColumnChooser,
  Sorting,
  Lookup,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Editing,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { Modal } from "@mui/base";
import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { get_user_authenticationLogs } from "../../api/Activity";
import { useAuthContext } from "../../Context/AuthProvider";
import {
  getChsCustomization,
  postChsCustomization,
} from "../../api/Customization";
import Alerts from "../../Alert/Alert";
import { getUserRole } from "../../api/UserRole";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function formatDateToCustomFormat(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}
const formatDateTo24Hour = (date) => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

///Custom Store Start

const customStore = (
  userInfo,
  setStatus,
  closeAlert,
  userRole,
  initialFilter = null
) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const take = options.take || 50;
      const skip = options.skip;
      const sort = options.sort || [
        {
          selector: "updatedAt",
          desc: true,
        },
      ];

      try {
        const res = await get_user_authenticationLogs(
          userInfo,
          filter,
          userRole,
          take,
          skip,
          sort
        );

        let dataRes = [];
        if (!res.data.data || res.data.data.response.length === 0) {
          return {
            data: [],
            totalCount: 0,
          };
        }
        if (res.data.data) {
          if (res.data.data.response) {
            // totalCount = res.data.data.response.length;
            dataRes = res.data.data.response.map((item, index) => {
              //LoginTime
              const dateString = item.loginTime;
              const inputDateCreated = new Date(dateString);

              // Format the date in the desired format without any time zone adjustments
              const formattedCreated = `${inputDateCreated
                .getUTCDate()
                .toString()
                .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                month: "short",
              }).format(
                inputDateCreated
              )}-${inputDateCreated.getUTCFullYear()} ${inputDateCreated
                .getUTCHours()
                .toString()
                .padStart(2, "0")}:${inputDateCreated
                .getUTCMinutes()
                .toString()
                .padStart(2, "0")}:${inputDateCreated
                .getUTCSeconds()
                .toString()
                .padStart(2, "0")}`;

              //Log out Time
              let formattedDateUpdat = "";
              if (item.logoutTime) {
                const dateStringUpdated = item.logoutTime;
                const inputDate = new Date(dateStringUpdated);
                formattedDateUpdat = `${inputDate
                  .getUTCDate()
                  .toString()
                  .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                  month: "short",
                }).format(inputDate)}-${inputDate.getUTCFullYear()} ${inputDate
                  .getUTCHours()
                  .toString()
                  .padStart(2, "0")}:${inputDate
                  .getUTCMinutes()
                  .toString()
                  .padStart(2, "0")}:${inputDate
                  .getUTCSeconds()
                  .toString()
                  .padStart(2, "0")}`;
              } else {
                formattedDateUpdat = "";
              }
              const startIndex = (options.skip || 0) + 1;
              return {
                id: index + startIndex,
                LogId: item.logId,
                clientUserId: item.clientUserId,
                username: item.username,
                ipAddress: item.ipAddress,
                LoginTime: formattedCreated,
                LogoutTime: formattedDateUpdat,
                status: item.status,
                clientName:item.clientName,
              };
            });
          }
        }
        // some minor issue here.
        return {
          data: dataRes,
          totalCount: res.data.data.count,
        };
      } catch (error) {
        // setStatus({
        //   display: true,
        //   text: `Please contact to administrator`,
        //   statusType: "warning",
        // });
        closeAlert();
        return {
          data: [],
          totalCount: 0,
        };
      }
    },
  });
const onExporting = (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Main sheet");
  // Obtain selected rows data
  const selectedRows = e.component.getSelectedRowsData();
  // Export only selected rows
  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
    customizeExportData: (columns, rows) => {
      return {
        columns,
        rows: selectedRows,
      };
    },
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "AuthenticationLog.xlsx"
      );
    });
  });
};
///Custom Store End

function AuthenticationLog({ drawerOpener }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [groupByDropdown, setGroupByDropdown] = useState([]);
  const [authenticationData, setAuthenticationData] = useState([]);
  const [clientListData, setClientListData] = useState([]);
  const [clearCustomizationPopUp, setClearCustomizationPopUp] = useState(false);
  const [open, setOpen] = useState(false);
  const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";

  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  const dataGridRef = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { accessToken, userInfo, userRole } = useAuthContext();
  const allowedPageSizes = [50, 100, 250];

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const rowStyles = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    // gap: "20%"
  };
  const drawerWidth = 600;

  function closeAlert() {
    setTimeout(() => {
      setStatus({
        display: false,
        text: "",
        statusType: "",
      });
    }, 3000);
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCellPrepared = (e) => {
    if (e.cellElement.ariaLabel === "Column Log Id") {
      e.cellElement.style.backgroundColor = "#008ce3";
    }
  };

  useEffect(() => {
    if (userInfo && getUserRole) {
      setClientListData(customStore(userInfo, setStatus, closeAlert, userRole));
    }
  }, [userInfo, userRole]);

  const saveState = useCallback(
    async (state) => {
      if (userInfo.userName) {
        const res = await postChsCustomization(
          userInfo.userName,
          "ATSLoginLogs",
          state
        );
      }
    },
    [userInfo]
  );

  const loadState = useCallback(async () => {
    if (userInfo.userName) {
      const res = await getChsCustomization(userInfo.userName, "ATSLoginLogs");
      return res.data.data;
    }
  }, [userInfo]);

  const clearFilters = (e) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.clearFilter();
    }
  };

  const refresh = () => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh();
    }
  };

  const customChanges = {
    icon: "undo",
    text: "Clear",
    type: "default",
    onClick: () => clearCustomizationDataGrid(),
  };
  const cancelCustomchanges = {
    text: "Close",
    icon: "remove",
    type: "danger",
    onClick: () => {
      setClearCustomizationPopUp(false);
    },
  };

  const clearCustomizationDataGrid = () => {
    const dataGridInstance = dataGridRef.current.instance;
    const columns = dataGridInstance.getVisibleColumns();
    columns.forEach((column) => {
      column.visible = true;
      column.showInColumnChooser = true;
    });
    dataGridInstance.option("columns", columns);
    dataGridInstance.clearFilter();
    dataGridInstance.clearSelection();
    setClearCustomizationPopUp(false);
  };

  return (
    <>
      {status.display && <Alerts status={status} sx={{ margin: "10px" }} />}
      <Popup
        visible={clearCustomizationPopUp}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        container="body"
        width={500}
        height={200}
        title="Alert"
      >
        <Position my="center" of={"main_dataGridClass"} collision="fit" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={customChanges}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelCustomchanges}
        />
        <p>Your custom changes made by you on grid will be removed..</p>
      </Popup>
      <Box className={drawerOpener ? "add-tabs" : "close-tabs"}>
        <Paper square>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered={false}
          >
            <Tab
              sx={{
                fontSize: "small",
                fontWeight: "700",
                marginLeft: drawerOpener ? "245px" : "55px",
                textTransform: "none",
              }}
              label="Authentication Log"
            />
            {/* <Tab sx={{ fontSize: "small", fontWeight: "600" }} label="Back Office" /> */}
          </Tabs>
        </Paper>

        {/* Content for each tab */}
        {selectedTab === 0 ? (
          <>
            <div
              className="main-div"
              style={{
                height: "90vh",
                // width: "100%",
                marginTop: "1%",

                marginLeft: drawerOpener ? "255px" : "65px",
                width: drawerOpener ? "86%" : "96%",
              }}
            >
              <DataGrid
                dataSource={clientListData}
                keyExpr="id"
                columnResizingMode="widget"
                key="index"
                // onSelectionChanged={onSelectionChanged}

                // selectedRowKeys={viewArchiveList()}
                // ref={dataGridRef}
                height="90%"
                width="100%"
                // showBorders='2'
                // defaultValue="#f05b41"
                showRowLines={true}
                showColumnLines={true}
                // ref={dataGridRef}
                hoverStateEnabled
                showBorders={true}
                // rowAlternationEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                // loadPanel={{ enabled: true }}
                // filterSyncEnabled={true}
                columnMinWidth={50}
                loadPanel={{ enabled: true }}
                onCellPrepared={handleCellPrepared}
                onExporting={onExporting}
                remoteOperations={true}
                ref={dataGridRef}
              >
                <Scrolling columnRenderingMode="virtual" />
                {/* <Paging defaultPageSize={20} enabled={false} />
                 */}

                <Toolbar>
                  <Item location="after">
                    <Button2 hint="Refresh" icon="refresh" onClick={refresh} />
                  </Item>
                  <Item>
                    <DevExtremToolbarClearfilter
                      clearFilters={clearFilters}
                      dataGridRef={dataGridRef}
                    />
                  </Item>
                  <Item name="columnChooserButton" />
                  <Item name="exportButton" />
                  <Item>
                    <img
                      title="Clear Customization"
                      className="clean"
                      src={clean}
                      onClick={() => {
                        setClearCustomizationPopUp(true);
                      }}
                    ></img>
                  </Item>
                </Toolbar>

                <ColumnChooser enabled={true} />
                <Export enabled={true} allowExportSelectedData={false} />
                <ColumnFixing enabled={true} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={allowedPageSizes}
                  showInfo={true}
                  visible={true}
                />
                {/* <Scrolling useNative={true} /> */}
                <Paging defaultPageSize={50} enabled={true} />
                {/* <LoadPanel visible={true} /> */}
                <FilterRow visible={true} />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={loadState}
                  customSave={saveState}
                />
                <Column
                  allowSorting={false}
                  width="50px"
                  allowFiltering={false}
                  alignment="left"
                  caption="S.No"
                  dataField="id"
                  allowReordering={false}
                />
                {/* <Column
                  allowReordering={true}
                  width="300px"
                  caption="Log Id"
                  dataField="LogId"
                  alignment="left"
                  editorOptions={{
                    showClearButton: true,
                  }}
                /> */}
                <Column
                  allowReordering={true}
                  width="300px"
                  caption="User ID"
                  dataField="clientUserId"
                  // cellRender={setStatus}
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                {/* <Column
                  allowReordering={true}
                  width="400px"
                  caption="User ID"
                  dataField="UserID"
                  editorOptions={{
                    showClearButton: true,
                  }}
                /> */}
                <Column
                  allowReordering={true}
                  width="400px"
                  caption="User Name"
                  dataField="username"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  allowReordering={true}
                  width="250px"
                  caption="Client Name"
                  dataField="clientName"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  width="210px"
                  caption="IP Address"
                  dataField="ipAddress"
                />
                <Column
                  allowReordering={true}
                  width="400px"
                  caption="Login Time"
                  dataField="LoginTime"
                  editorOptions={{
                    showClearButton: true,
                  }}
                  dataType="date"
                  format={filterDateFormat}
                />
                <Column
                  allowReordering={true}
                  width="400px"
                  caption="Logout Time"
                  dataField="LogoutTime"
                  dataType="date"
                  format={filterDateFormat}
                  editorOptions={{
                    showClearButton: true,
                  }}
                />

                <Column
                  allowReordering={true}
                  width="250px"
                  caption="Status"
                  dataField="status"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
              </DataGrid>
            </div>
          </>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
export default AuthenticationLog;

function DevExtremToolbarClearfilter({ clearFilters, dataGridRef }) {
  return (
    <>
      <img
        title="Clear Filter"
        className="clean"
        src={ClearFiltericon}
        onClick={() => {
          const isFilterApplied =
            dataGridRef.current.instance.getCombinedFilter();
          if (isFilterApplied != undefined) {
            clearFilters();
          }
        }}
      ></img>
    </>
  );
}
