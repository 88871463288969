import {Autocomplete, Box, DialogTitle,Drawer,Paper, TextField} from "@mui/material";
import "./ElementConfig.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button as Button2 } from "devextreme-react/button";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import clean from "../../assets/clean.png";
import ClearFiltericon from "../../assets/ClearFiltericon.png";
import DataGrid, {
  Column,
  FilterRow,
  Paging,
  // Scrolling,
  RowDragging,
  Pager,
  ColumnChooser,
  Sorting,
  Lookup,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Editing,
  Scrolling,
  StateStoring,
  Selection,
} from "devextreme-react/data-grid";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { getElementConfig, postElemnetData } from "../../api/clients";
import { useAuthContext } from "../../Context/AuthProvider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button as DevExtButton, CheckBox } from "devextreme-react";
import Button from "@mui/material/Button";
import Alerts from "../../Alert/Alert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Vector from "../../assets/Vector.jpg";
import ManuElementConfig from "../ClientPortalList/ManuElementConfig";
import CustomStore from "devextreme/data/custom_store";
import { Stack } from "@mui/material";

import {
  getChsCustomization,
  postChsCustomization,
} from "../../api/Customization";
import ClientPortalRowAudit from "./ClientPortalRowAudit";

const Item1 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function formatDateToCustomFormat(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

const formatDateTo24Hour = (date) => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

const allowedPageSizes = [50, 100, 250];

const customStore = (setStatus, clientId, initialFilter = null) =>
  new CustomStore({
    key: "sNo",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const take = options.take || 10;
      const skip = options.skip || null;
      let tempArr = [];
      if (filter !== null) {
        if (filter[0] === "dbField") {
          let array = ["buttonIntent", filter[1], filter[2]];
          tempArr = [filter, "OR", array];
        } else {
          tempArr = filter;
        }
      }
      let res = [];
      try {
        res = await getElementConfig(tempArr, clientId, take, skip);
        if (!res.data.data || res.data.data.response.length === 0) {
          return {
            data: [],
            totalCount: 0,
          };
        }
        let dataRes = [];
        if (res) {
          if (res.data.data != null) {
            const startIndex = (options.skip || 0) + 1;
            dataRes = res.data.data.response.map((dataItem, index) => {
              ///for created At Date
              const dateString = dataItem.createdAt;
              const inputDateCreated = new Date(dateString);

              // Format the date in the desired format without any time zone adjustments
              const formattedCreated = `${inputDateCreated
                .getUTCDate()
                .toString()
                .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                  month: "short",
                }).format(
                  inputDateCreated
                )}-${inputDateCreated.getUTCFullYear()} ${inputDateCreated
                  .getUTCHours()
                  .toString()
                  .padStart(2, "0")}:${inputDateCreated
                    .getUTCMinutes()
                    .toString()
                    .padStart(2, "0")}:${inputDateCreated
                      .getUTCSeconds()
                      .toString()
                      .padStart(2, "0")}`;
              //for update date
              const dateStringUpdated = dataItem.updatedAt; // Replace this with item.updatedAt in your code

              // Parse the input date string
              const inputDate = new Date(dateStringUpdated);

              // Format the date in the desired format without any time zone adjustments
              const formattedDateUpdat = `${inputDate
                .getUTCDate()
                .toString()
                .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                  month: "short",
                }).format(inputDate)}-${inputDate.getUTCFullYear()} ${inputDate
                  .getUTCHours()
                  .toString()
                  .padStart(2, "0")}:${inputDate
                    .getUTCMinutes()
                    .toString()
                    .padStart(2, "0")}:${inputDate
                      .getUTCSeconds()
                      .toString()
                      .padStart(2, "0")}`;

              return {
                loadMode: "raw",
                key: "sNo",
                sNo: startIndex + index,
                id: dataItem.id,
                dbField: dataItem.dbField
                  ? dataItem.dbField
                  : dataItem.buttonIntent,
                inputElementType: dataItem.inputElementType
                  ? dataItem.inputElementType
                  : "",
                Attribute: dataItem.attribute ? dataItem.attribute : "",
                AttributeValue: dataItem.attributeValue,
                CreatedBy: dataItem.createdBy,
                CreatedAt: formattedCreated,
                UpdatedBy: dataItem.updatedBy,
                UpdatedAt: formattedDateUpdat,
                clientId: clientId,
              };
            });
          }
          return {
            data: dataRes,
            totalCount: res.data.data.count,
          };
        }
      } catch (error) {
        setStatus({
          display: true,
          text: `Something went wrong`,
          statusType: "warning",
        });
        return {
          data: [],
          totalCount: 0,
        };
      }
    },
  });

const DataBaseField = ["User Name", "Password", "Agency ID", "Company ID"];

const dbFieldData = {
  "User Name": "username",
  Password: "password",
  "Agency ID": "agencyId",
  "Company ID": "companyId",
  "OTP Field": "otpField",
  "OTP Email": "graphEmailAddress",
};
const InputElementType = ["Button", "Text", "CheckBox"];
const AttributeData = ["ID", "Class", "Name"];

const InputElementTypes = ["Button", "Text", "Checkbox"];
const databaseFields = [
  "User Name",
  "Password",
  "Agency ID",
  "Company ID",
  "OTP Field",
  "OTP Email",
];
const buttonIntent = ["Login", "Next", "Submit", "OTP", "otpContinue"];
const attributes = ["ID", "Class", "Name"];
const formInputCategoryLabelsText = {
  Button: "Button Intent",
  Text: "Database Field",
  Checkbox: "Database Field",
};
function ElementConfig({ cellData, drawerOpener }) {
  const [RedirectUrl, setRedirectUrl] = useState('');
  const [value, setValue] = React.useState("Details");
  const [elementAud, setElementAud] = useState(false);
  const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const { accessToken, userInfo } = useAuthContext();
  const [formsData, setFormsData] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [clearCustomizationPopUp, setClearCustomizationPopUp] = useState(false);
  const [formInputCategoryLabels, setFormInputCategoryLabels] = useState(
    formInputCategoryLabelsText.Text
  );

  const [InputElementTypesDynamicData, setInputElementTypesDynamicData] = useState([]);

  const elementConfigRef = useRef(null);
  const [clientListData, setClientListData] = useState([]);
  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (location.state !== null) {
      const clientId = location.state.clientId;
      setClientListData(customStore(setStatus, clientId));
    }
  }, [location]);

  // new................
  useEffect(() => {
    if (location.state !== null) {
      let url;
      const questionMarkIndex = location.state.loginUrl.indexOf("?");
      if (questionMarkIndex !== -1) {
        url = location.state.loginUrl + "&clientId=" + location.state.clientId;
      } else {
        url = location.state.loginUrl + "?clientId=" + location.state.clientId;
      }
      setRedirectUrl(url);
      // console.log(location.state.loginUrl, url, 'kkkkkkkkkkkkkkkkkkkk');
    }
  }, [location]);
  // new...................

  const navigate = useNavigate();
  useEffect(() => {
    if (selectedOption === "Edit") {
      toggleSidebar();
    }
  }, [selectedOption]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setFormsData({});
  };

  function handleBack() {
    navigate(-1);
  }

  async function handleFormSubmit(e) {
    e.preventDefault();
    let _formData = {
      ...formsData,
      dbField: dbFieldData[formsData.dbField],
    };
    if ((accessToken !== "null", userInfo)) {
      try {
        const apiResponse = await postElemnetData(
          accessToken,
          userInfo.userName,
          _formData,
          location.state.clientId,
          location.state.atsId
        );
        if (
          apiResponse.data.data.message ===
          "Element Configuration Created Successfully!!"
        ) {
          setStatus({
            display: true,
            text: "Data added successfully",
            statusType: "success",
          });
          setIsSidebarOpen(false);
          refresh();
        } else if (
          apiResponse.data.data.message === "Database Field Already Exist!!"
        ) {
          setStatus({
            display: true,
            text: "Database Field Already Exist",
            statusType: "warning",
          });
        }
      } catch (error) {
        setStatus({
          display: true,
          text: `Something went wrong`,
          statusType: "error",
        });
      }
    }
  }
  function refresh() {
    if (elementConfigRef.current) {
      elementConfigRef.current.instance.refresh();
    }
  }

  const clearFilters = (e) => {
    if (elementConfigRef.current) {
      elementConfigRef.current.instance.clearFilter();
    }
  };

  const clearCustomizationDataGrid = () => {
    const dataGridInstance = elementConfigRef.current.instance;
    const columns = dataGridInstance.getVisibleColumns();
    columns.forEach((column) => {
      column.visible = true;
      column.showInColumnChooser = true;
    });
    dataGridInstance.option("columns", columns);
    dataGridInstance.clearFilter();
    dataGridInstance.clearSelection();
    setClearCustomizationPopUp(false);
  };

  const customChanges = {
    icon: "undo",
    text: "Clear",
    type: "default",
    onClick: () => {
      clearCustomizationDataGrid()
    }
  };

  const cancelCustomchanges = {
    text: "Close",
    icon: "remove",
    type: "danger",
    onClick: () => {
      setClearCustomizationPopUp(false);
    },
  };

  const handleCellPrepared = (e) => {
    if (e.cellElement.ariaLabel === "Column Database Field/Button Intent") {
      e.cellElement.style.backgroundColor = "#008ce3";
    }
  };

  useEffect(() => {
    if (formsData.inputElementType === "Button") {
      setFormInputCategoryLabels(
        formInputCategoryLabelsText[formsData.inputElementType]
      );
      setInputElementTypesDynamicData(buttonIntent);
      setFormsData({ ...formsData, dbField: null });
    } else if (formsData.inputElementType === "Text") {
      setFormInputCategoryLabels(
        formInputCategoryLabelsText[formsData.inputElementType]
      );
      setInputElementTypesDynamicData(databaseFields);
      setFormsData({ ...formsData, buttonIntent: null });
    } else if (formsData.inputElementType === "CheckBox") {
      setFormInputCategoryLabels("Database Field");
      setInputElementTypesDynamicData(databaseFields);
      setFormsData({ ...formsData, buttonIntent: null });
    }
    setFormsData((prevState) => ({ ...prevState }));
  }, [formsData.inputElementType]);

  const saveState = useCallback(
    async (state) => {
      // console.log(state, 'iiiiiiiiiiiiiiiiiiiiii');
      if (userInfo.userName) {
        const res = await postChsCustomization(
          userInfo.userName,
          "ElementConfiguration",
          state
        );
      }
    },
    [userInfo]
  );

  const loadState = useCallback(async () => {
    if (userInfo.userName) {
      const res = await getChsCustomization(
        userInfo.userName,
        "ElementConfiguration"
      );
      return res.data.data;
    }
  }, [userInfo]);

  const handleAuditLog = () => {
    setElementAud(true);
  };
  return (
    <>
      <Popup
        visible={clearCustomizationPopUp}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        container="body"
        width={500}
        height={200}
        title="Alert"
      >
        <Position my="center" of={"main_dataGridClass"} collision="fit" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={customChanges}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelCustomchanges}
        />
        <p>Your custom changes made by you on grid will be removed..</p>
      </Popup>
      {status.display && <Alerts status={status} sx={{ margin: "10px" }} />}
      {elementAud && (
        <ClientPortalRowAudit
          clientId={location.state.clientId}
          openAudit={elementAud}
          setOpenAudit={setElementAud}
        />
      )}
      {location.state !== null && (
        <div
          style={{
            height: "80vh",
            // width: "100%",
            marginTop: "51px",
            marginLeft: drawerOpener ? "245px" : "51px",
            width: drawerOpener ? "87%" : "97%",
          }}
        >
          <Box className="element_header">
            <div className="header_content">
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "50px",
                }}
              >
                <Box>
                  <IconButton onClick={handleBack}>
                    <img src={Vector} />
                  </IconButton>
                  <span className="navigation_style_blue">
                    {" "}
                    &nbsp; &nbsp;Home &nbsp;{" "}
                  </span>{" "}
                  <span className="navigation_style">
                    {" "}
                    &nbsp; &gt; Admin &nbsp; &gt; Client Portal
                  </span>
                </Box>

                <div>
                  <Button
                    size="large"
                    variant="contained"
                    sx={{ textTransform: "none" }}
                    onClick={handleAuditLog}
                  >
                    Audit Logs
                  </Button>
                </div>
              </Box>
            </div>
          </Box>

          <Box className="element_header_details">
            <div>
              <h3 className="client_Name">{location.state.companyName}</h3>
            </div>
            <div>
              <div className="header_style">
                <div className="bold_text">
                  Group : {location.state.groupName}
                </div>
                <div className="bold_text">ATS : {location.state.atsName}</div>
                <div className="bold_text">
                  Client Name : {location.state.clientName}
                </div>
              </div>
            </div>
          </Box>
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "#fff",
              marginTop: "4px",
            }}
          >
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  boxShadow: " 0px 4px 4px 0px rgba(2, 2, 2, 0.25)",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Details"
                    value="Details"
                    sx={{ textTransform: "none" }}
                  />
                  <Tab
                    label="Element Configuration"
                    value="Element Configuration"
                    sx={{ textTransform: "none" }}
                  />
                </TabList>
              </Box>
              <TabPanel value="Details">
                <Box sx={{ width: "100%", height: "70%" }}>
                  <Grid container>
                    <Grid item lg={6} xs={6} sm={8}>
                      <Box sx={{ height: "100%", width: "100%" }}>
                        <div className="details-container">
                          <div className="details-content-row-items">
                            <div className="row" style={{ margin: "18px 0" }}>
                              <span
                                className="col-sm-4"
                                style={{ fontWeight: 600, fontSize: "18px" }}
                              >
                                Login URL:
                              </span>
                              <a
                                className="col-sm-8"
                                style={{
                                  fontSize: "15px",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  textDecoration: "underline",
                                }}
                                target="_blank"
                                href={
                                    RedirectUrl
                                      ? RedirectUrl
                                      : ""
                                  }
                                  title={
                                    location.state.loginUrl
                                      ? `Go to ${location.state.loginUrl}`
                                      : "Invalid URL"
                                  }
                              >
                                {location.state.loginUrl
                                  ? location.state.loginUrl
                                  : "N/A"}
                              </a>
                            </div>
                            <div className="row" style={{ margin: "18px 0" }}>
                              <span
                                className="col-sm-4"
                                style={{ fontWeight: 600, fontSize: "18px" }}
                              >
                                User Name:
                              </span>
                              <span
                                className="col-sm-8"
                                style={{ fontSize: "15px" }}
                              >
                                {" "}
                                {location.state.username
                                  ? location.state.username
                                  : "N/A"}
                              </span>
                            </div>
                            <div className="row" style={{ margin: "18px 0" }}>
                              <span
                                className="col-sm-4"
                                style={{ fontWeight: 600, fontSize: "18px" }}
                              >
                                Agency Company ID:
                              </span>
                              <span
                                className="col-sm-8"
                                style={{ fontSize: "15px" }}
                              >
                                {location.state.agencyCompanyId
                                  ? location.state.agencyCompanyId
                                  : "N/A"}
                              </span>
                            </div>
                            <div className="row" style={{ margin: "18px 0" }}>
                              <span
                                className="col-sm-4"
                                style={{ fontWeight: 600, fontSize: "18px" }}
                              >
                                Jobs URL:
                              </span>
                              <a
                                className="col-sm-8"
                                style={{ fontSize: "15px" }}
                                href={
                                  location.state.jobsUrl
                                    ? location.state.jobsUrl
                                    : ""
                                }
                              >
                                {location.state.jobsUrl
                                  ? location.state.jobsUrl
                                  : "N/A"}
                              </a>
                            </div>
                            <div className="row" style={{ margin: "18px 0" }}>
                              <span
                                className="col-sm-4"
                                style={{ fontWeight: 600, fontSize: "18px" }}
                              >
                                Screenshot:
                              </span>
                              <span
                                className="col-sm-8"
                                style={{ fontSize: "15px" }}
                              >
                                {location.state.isScreenshotEnabled !==
                                  undefined
                                  ? location.state.isScreenshotEnabled
                                    ? "Yes"
                                    : "No"
                                  : "N/A"}
                              </span>
                            </div>
                            <div className="row" style={{ margin: "18px 0" }}>
                              <span
                                className="col-sm-4"
                                style={{ fontWeight: 600, fontSize: "18px" }}
                              >
                                CV Sent:
                              </span>
                              <span
                                className="col-sm-8"
                                style={{ fontSize: "15px" }}
                              >
                                {location.state.isCvSent !== undefined
                                  ? location.state.isCvSent
                                    ? "Yes"
                                    : "No"
                                  : "N/A"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item lg={6} xs={6} sm={8}>
                      <div className="details-container">
                        <div className="details-content-row-items">
                          <div className="row" style={{ margin: "18px 0" }}>
                            <span
                              className="col-sm-4"
                              style={{ fontWeight: 600, fontSize: "18px" }}
                            >
                              Authentication Type:
                            </span>
                            <span
                              className="col-sm-8"
                              style={{ fontSize: "15px" }}
                            >
                              {location.state.authenticationType
                                ? location.state.authenticationType
                                : "N/A"}
                            </span>
                          </div>
                          <div className="row" style={{ margin: "18px 0" }}>
                            <span
                              className="col-sm-4"
                              style={{ fontWeight: 600, fontSize: "18px" }}
                            >
                              Agency ID:
                            </span>
                            <span
                              className="col-sm-8"
                              style={{ fontSize: "15px" }}
                            >
                              {" "}
                              {location.state.agencyId
                                ? location.state.agencyId
                                : "N/A"}
                            </span>
                          </div>
                          <div className="row" style={{ margin: "18px 0" }}>
                            <span
                              className="col-sm-4"
                              style={{ fontWeight: 600, fontSize: "18px" }}
                            >
                              Password
                            </span>
                            <span
                              className="col-sm-8"
                              style={{ fontSize: "15px" }}
                            >
                              {location.state.password
                                ? location.state.password
                                : "N/A"}
                            </span>
                          </div>
                          <div className="row" style={{ margin: "18px 0" }}>
                            <span
                              className="col-sm-4"
                              style={{ fontWeight: 600, fontSize: "18px" }}
                            >
                              Auto Duplicate Check Process:
                            </span>
                            <span
                              className="col-sm-8"
                              style={{ fontSize: "15px" }}
                            >
                              {location.state.isAutoProcessingEnabled !==
                                undefined
                                ? location.state.isAutoProcessingEnabled
                                  ? "Yes"
                                  : "No"
                                : "N/A"}
                            </span>
                          </div>
                          <div className="row" style={{ margin: "18px 0" }}>
                            <span
                              className="col-sm-4"
                              style={{ fontWeight: 600, fontSize: "18px" }}
                            >
                              JD URL:
                            </span>
                            <a
                              className="col-sm-8"
                              style={{ fontSize: "15px" }}
                              href={
                                location.state.jdUrl ? location.state.jdUrl : ""
                              }
                            >
                              {location.state.jdUrl
                                ? location.state.jdUrl
                                : "N/A"}
                            </a>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel value="Element Configuration">
                <div
                  className="element-div"
                  style={{
                    height: "60vh",
                    // marginTop: "1%",
                    // marginLeft: drawerOpener ? "255px" : "65px",
                    // width: drawerOpener ? "86%" : "95%",
                  }}
                >
                  <DataGrid
                    dataSource={clientListData}
                    keyExpr="sNo"
                    key="sNo"
                    height="90%"
                    width="100%"
                    // showBorders='2'
                    // defaultValue="#f05b41"
                    showRowLines={true}
                    showColumnLines={true}
                    hoverStateEnabled
                    showBorders={true}
                    // rowAlternationEnabled={true}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    // loadPanel={{ enabled: true }}
                    // filterSyncEnabled={true}
                    columnMinWidth={50}
                    loadPanel={{ enabled: true }}
                    filterSyncEnabled={true}
                    remoteOperations={true}
                    ref={elementConfigRef}
                    columnResizingMode="widget"
                    onCellPrepared={handleCellPrepared}
                    noDataText="No record found"
                  >
                    <StateStoring
                      enabled={true}
                      type="custom"
                      customLoad={loadState}
                      customSave={saveState}
                    />
                    <Paging defaultPageSize={10} enabled={true} />
                    <Pager
                      showPageSizeSelector={true}
                      allowedPageSizes={allowedPageSizes}
                      showInfo={true}
                      visible={true}
                    />
                    <Toolbar>
                      <Item location="before">
                        <DevExtButton
                          text="Add New"
                          width="136"
                          type="default"
                          stylingMode="contained"
                          onClick={toggleSidebar}
                        >
                          Add New
                        </DevExtButton>
                      </Item>
                      <Item location="after">
                        <Button2
                          hint="Refresh"
                          icon="refresh"
                          onClick={refresh}
                        />
                      </Item>
                      <Item>
                        <DevExtremToolbarClearfilter
                          clearFilters={clearFilters}
                          elementConfigRef={elementConfigRef}
                        />
                      </Item>
                      <Item name="columnChooserButton" />
                      <Item name="exportButton" />
                      <Item>
                        <img
                          title="Clear Customization"
                          className="clean"
                          src={clean}
                          onClick={() => {
                            setClearCustomizationPopUp(true);
                          }}
                        ></img>
                      </Item>
                    </Toolbar>
                    <ColumnChooser enabled={true} />
                    <Export enabled={true} allowExportSelectedData={false} />
                    <FilterRow visible={true} />
                    <Column
                      allowSorting={false}
                      width="50px"
                      allowFiltering={false}
                      alignment="left"
                      caption="S.No"
                      dataField="sNo"
                      allowReordering={false}
                    />
                    <Column
                      caption="Database Field/Button Intent"
                      dataField="dbField"
                      allowSorting={false}
                      width={"400px"}
                      cellRender={(cellData) => {
                        return (
                          <>
                            <ManuElementConfig
                              updatedBy={cellData.data.updatedBy}
                              clientId={cellData.data.clientId}
                              idValue={cellData.data.id}
                              cellData={cellData}
                              refresh={refresh}
                            />
                          </>
                        );
                      }}
                      editorOptions={{
                        showClearButton: true,
                      }}
                    />
                    <Column
                      dataField="inputElementType"
                      allowSorting={false}
                      width={"350px"}
                      caption="Input Element Type "
                      editorOptions={{
                        showClearButton: true,
                      }}
                    />
                    <Column
                      allowReordering={true}
                      width="300px"
                      caption="Attribute"
                      dataField="Attribute"
                      editorOptions={{
                        showClearButton: true,
                      }}
                    />
                    <Column
                      allowReordering={true}
                      width="300px"
                      caption="Attribute Value"
                      dataField="AttributeValue"
                      // cellRender={setStatus}
                      editorOptions={{
                        showClearButton: true,
                      }}
                    />
                    <Column
                      allowReordering={true}
                      width="250px"
                      caption="Created At "
                      dataField="CreatedAt"
                      editorOptions={{
                        showClearButton: true,
                      }}
                      dataType="date"
                      format={filterDateFormat}
                    />
                    <Column
                      allowReordering={true}
                      width="250px"
                      caption="Created By"
                      dataField="CreatedBy"
                      editorOptions={{
                        showClearButton: true,
                      }}
                    // format={filterDateFormat}
                    />
                    <Column
                      allowReordering={true}
                      width="250px"
                      caption="Updated At"
                      dataField="UpdatedAt"
                      editorOptions={{
                        showClearButton: true,
                      }}
                      dataType="date"
                      format={filterDateFormat}
                    // format={filterDateFormat}
                    />
                  </DataGrid>
                </div>
              </TabPanel>
            </TabContext>

            <Drawer
              anchor="right"
              open={isSidebarOpen}
              onClose={toggleSidebar}
              PaperProps={{ style: { width: 600 } }}
            >
              <DialogTitle
                style={{
                  background: "rgb(0 140 227)",
                  color: "white",
                  fontSize: "20px",
                }}
                id="responsive-dialog-title"
              >
                Add
              </DialogTitle>
              <IconButton
                size="large"
                aria-label="close"
                onClick={() => setIsSidebarOpen(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "white",
                }}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
              <form onSubmit={handleFormSubmit}>
                <Box>
                  <Grid
                    container
                    spacing={2}
                    sx={{ width: "95%", margin: "15px 0px 15px 0px" }}
                  >
                    <Grid
                      item
                      md={6}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "25px",
                      }}
                    >
                      {/* Group By field */}
                      <Box sx={{ flexDirection: "column", width: "100%" }}>
                        <span className="fieldName">
                          Input Element Type<span className="asterisk">*</span>
                        </span>
                        <Autocomplete
                          sx={{ margin: "5px", width: "95%" }}
                          required
                          id="outlined-required"
                          label="Input Element Type"
                          name="inputElementType"
                          options={InputElementType}
                          // getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label="Input Element Type"
                              variant="outlined"
                            />
                          )}
                          onChange={(e, value) => {
                            setFormsData({
                              ...formsData,
                              inputElementType: value,
                            });
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "25px",
                      }}
                    >
                      {/* Group By field */}
                      <Box sx={{ flexDirection: "column", width: "100%" }}>
                        {formInputCategoryLabels}
                        <span className="fieldName">
                          <span className="asterisk">*</span>
                        </span>
                        <Autocomplete
                          sx={{ margin: "5px", width: "95%" }}
                          required
                          id="outlined-required"
                          label="Database Field"
                          name="databaseField"
                          options={InputElementTypesDynamicData}
                          // getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label="Database Field"
                              variant="outlined"
                            />
                          )}
                          value={
                            formsData.dbField !== undefined &&
                              formsData.dbField !== null
                              ? formsData.dbField
                              : formsData.buttonIntent !== undefined &&
                                formsData.buttonIntent !== null
                                ? formsData.buttonIntent
                                : ""
                          }
                          onChange={(e, value) => {
                            if (formInputCategoryLabels === "Button Intent") {
                              setFormsData({
                                ...formsData,
                                buttonIntent: value,
                              });
                            } else {
                              setFormsData({ ...formsData, dbField: value });
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "25px",
                      }}
                    >
                      {/* Group By field */}
                      <Box sx={{ flexDirection: "column", width: "100%" }}>
                        <span className="fieldName">
                          Atrribute<span className="asterisk">*</span>
                        </span>
                        <Autocomplete
                          sx={{ margin: "5px", width: "95%" }}
                          required
                          id="outlined-required"
                          label="Attribute"
                          name="attribute"
                          options={AttributeData}
                          // getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label="Atrribute"
                              variant="outlined"
                            />
                          )}
                          onChange={(e, value) => {
                            setFormsData({ ...formsData, attribute: value });
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "25px",
                      }}
                    >
                      <Box sx={{ flexDirection: "column", width: "100%" }}>
                        <span className="fieldName">
                          Attribute Value<span className="asterisk">*</span>
                        </span>
                        <TextField
                          sx={{ margin: "5px", width: "95%" }}
                          required
                          id="outlined-required"
                          // label="Attribute Value"
                          name="attributeValue"
                          // value={formData.clientname}
                          onChange={(e) => {
                            setFormsData({
                              ...formsData,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button width="100" variant="contained" type="submit">
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </Drawer>
          </div>
        </div>
      )}
    </>
  );
}
export default ElementConfig;

function DevExtremToolbarClearfilter({ clearFilters, elementConfigRef }) {
  const [filterAlert, setfilterAlert] = useState(false);

  return (
    <>
      <img
        title="Clear Filter"
        className="clean"
        src={ClearFiltericon}
        onClick={() => {
          const isFilterApplied =
            elementConfigRef.current.instance.getCombinedFilter();
          if (isFilterApplied != undefined) {
            clearFilters();
            // setfilterAlert(true);
            // setTimeout(() => {
            //   setfilterAlert(false);
            // }, 3000);
          }
        }}
      ></img>

      {/* < SnackBar filterAlert={filterAlert} /> */}
      {/* <SnackBar /> */}
    </>
  );
}
