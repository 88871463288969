import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import "./Sidebar.css";
import "./Header.js";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import AdminIcon from "./assets/AdminIcon.png";
import AdminWhiteIcon from "./assets/AdminWhiteIcon.png";
import myClientIWhiteIcon from "./assets/myClientIWhiteIcon.png";
import myClientIcon from "./assets/myClientIcon.png";
import dashboardBlack from "./assets/dashboardBlack.png";
import activityBlack from "./assets/activityBlack.png";
import acitivityWhite from "./assets/acitivityWhite.png";

import { useAuthContext } from "./Context/AuthProvider.jsx";
const headerBG = {
  background: "white",
  // background: 'linear-gradient(16deg, rgba(2,0,36,1) 0%, rgba(39,39,130,1) 0%, rgba(96,175,191,1) 64%)'
};
const useStyles = styled((theme) => ({
  root: {
    display: "flex",
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },

  drawer: {
    width: 250,

    flexShrink: 0,
  },

  drawerPaper: {
    width: 250,
  },

  content: {
    flexGrow: 1,

    padding: theme.spacing(3),
  },
}));

function Sidebar({ drawerOpener }) {
  const [initialRender, setInitialRender] = useState("Home");
  const [initialRenderDropdown, setInitialRenderDropdown] = useState("");
  const { userRole } = useAuthContext();
  function handleSideColor(e) {
    setInitialRender(e);
  }

  function handleDropdownSideColor(e) {
    setInitialRenderDropdown(e);
  }

  useEffect(() => {
    const pathMappings = {
      "/": { initialRender: "Home" },
      "/DataGridList": { initialRender: "My Client" },
      "/ClientPortalList": {
        initialRender: "Admin",
        initialRenderDropdown: "Client Portal",
      },
      "/AuthenticationLog": {
        initialRender: "Activity",
        initialRenderDropdown: "Authentication Logs",
      },
      "/AccessControl": {
        initialRender: "Admin",
        initialRenderDropdown: "Acces Control",
      },
      "/AtsConfiguration": {
        initialRender: "Admin",
        initialRenderDropdown: "ATS Configration",
      },
      "/customizableFields": {
        initialRender: "Admin",
        initialRenderDropdown: "Customizable fields",
      },
      "/Tooltip": { initialRender: "Admin", initialRenderDropdown: "Tooltips" },
      "/UserActivity": {
        initialRender: "Activity",
        initialRenderDropdown: "User Activity",
      },
      "/Admin/ElementConfiguration": {
        initialRender: "Admin",
        initialRenderDropdown: "Client Portal",
      },
    };

    const { pathname } = window.location;
    const pathMapping = pathMappings[pathname];

    if (pathMapping) {
      const { initialRender, initialRenderDropdown } = pathMapping;
      setInitialRender(initialRender);
      if (initialRenderDropdown) {
        setInitialRenderDropdown(initialRenderDropdown);
      }
    }
  }, []);
  useEffect(() => {
    const pathMappings = {
      "/": { initialRender: "Home" },
      "/DataGridList": { initialRender: "My Client" },
      "/ClientPortalList": {
        initialRender: "Admin",
        initialRenderDropdown: "Client Portal",
      },
      "/AuthenticationLog": {
        initialRender: "Activity",
        initialRenderDropdown: "Authentication Logs",
      },
      "/AccessControl": {
        initialRender: "Admin",
        initialRenderDropdown: "Acces Control",
      },
      "/AtsConfiguration": {
        initialRender: "Admin",
        initialRenderDropdown: "ATS Configration",
      },
      "/customizableFields": {
        initialRender: "Admin",
        initialRenderDropdown: "Customizable fields",
      },
      "/Tooltip": { initialRender: "Admin", initialRenderDropdown: "Tooltips" },
      "/UserActivity": {
        initialRender: "Activity",
        initialRenderDropdown: "User Activity",
      },
      "/Admin/ElementConfiguration": {
        initialRender: "Admin",
        initialRenderDropdown: "Client Portal",
      },
    };
    const { pathname } = window.location;
    if (pathMappings[pathname]){
      if (pathMappings[pathname].initialRenderDropdown == undefined) {
        setInitialRenderDropdown("");
      }
    }
    if (pathMappings[pathname]) {
      const { initialRender, initialRenderDropdown } = pathMappings[pathname];
      setInitialRender(initialRender);
      if (initialRenderDropdown) {
        setInitialRenderDropdown(initialRenderDropdown);
      }
    }
  }, [initialRenderDropdown, initialRender]);

  return (
    <>
      <aside
        className="left-sidebar"
        style={{ width: drawerOpener ? "240px" : "50px" }}
      >
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              <li
                style={{ marginLeft: "-2px" }}
                className={initialRender === "Home" ? "active" : ""}
                onClick={() => handleSideColor("Home")}
              >
                <Link to="/" className="parentElement">
                  <i title="Home">
                    <img src={dashboardBlack} />
                  </i>
                  <span
                    className="hide-menu"
                    style={{
                      display: drawerOpener ? "inline-flex" : "none",
                      marginLeft: "20px",
                    }}
                  >
                    Dashboard
                  </span>
                  <div className="dropdownElements">
                    <div
                      style={{
                        width: "190px",
                        height: "58px",
                        backgroundColor: "#0099f9",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "5px",
                      }}
                    >
                      <span>Dashboard</span>
                    </div>
                    <ul className="">
                      <li></li>
                    </ul>
                  </div>
                </Link>
              </li>
              <li
                style={{ marginLeft: "-2px" }}
                className={initialRender === "My Client" ? "active" : ""}
                onClick={() => handleSideColor("My Client")}
              >
                <Link to="/DataGridList" id="LinkTo" className="parentElement">
                  {initialRender === "active" ? (
                    <i>
                      <img src={myClientIWhiteIcon} />
                    </i>
                  ) : (
                    <img src={myClientIcon} />
                  )}

                  <span
                    className="hide-menu"
                    style={{
                      display: drawerOpener ? "inline-flex" : "none",
                      marginLeft: "20px",
                    }}
                  >
                    My Client
                  </span>
                  <div className="dropdownElements">
                    <div
                      style={{
                        width: "190px",
                        height: "50px",
                        backgroundColor: "#0099f9",
                        display: "flex",
                        // justifyContent:'center',
                        alignItems: "center",
                        paddingLeft: "5px",
                      }}
                    >
                      <span> My Client</span>
                    </div>
                  </div>
                </Link>
              </li>
              {(userRole === "Global Administrator" ||
                userRole === "ClientPortal Administrator 90") && (
                <li
                  style={{ marginLeft: "-2px" }}
                  className={initialRender === "Admin" ? "active" : ""}
                  onClick={() => handleSideColor("Admin")}
                >
                  <Link to="/ClientPortalList" className="parentElement">
                    {initialRender === "Admin" ? (
                      <img src={AdminWhiteIcon} />
                    ) : (
                      <img src={AdminIcon} />
                    )}

                    <span
                      className="hide-menu"
                      style={{
                        display: drawerOpener ? "inline-flex" : "none",
                        marginLeft: "28px",
                      }}
                    >
                      Admin
                    </span>
                    <div className="dropdownElements">
                      <div
                        style={{
                          width: "190px",
                          height: "50px",
                          backgroundColor: "#0099f9",
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "5px",
                        }}
                      >
                        <span>Admin</span>
                      </div>
                      <>
                        <ul className="child-list-item">
                          <li
                            onClick={() =>
                              handleDropdownSideColor("Client Portal")
                            }
                          >
                            <Link
                              to="/ClientPortalList"
                              className="hoverOption"
                            >
                              <span
                                className={
                                  initialRenderDropdown === "Client Portal"
                                    ? "child-active"
                                    : ""
                                }
                              >
                                Client ATS
                              </span>
                            </Link>
                          </li>
                          <li
                            onClick={() =>
                              handleDropdownSideColor("ATS Configration")
                            }
                          >
                            <Link
                              to="/AtsConfiguration"
                              className="hoverOption"
                            >
                              <span
                                className={
                                  initialRenderDropdown === "ATS Configration"
                                    ? "child-active"
                                    : ""
                                }
                              >
                                ATS Auto Login Tag
                              </span>
                            </Link>
                          </li>
                          <li
                            onClick={() =>
                              handleDropdownSideColor("Acces Control")
                            }
                          >
                            <Link className="hoverOption" to="/AccessControl">
                              <span
                                className={
                                  initialRenderDropdown === "Acces Control"
                                    ? "child-active"
                                    : ""
                                }
                              >
                                Access Control
                              </span>
                            </Link>
                          </li>

                          <li
                            onClick={() =>
                              handleDropdownSideColor("Customizable fields")
                            }
                          >
                            <Link
                              to="/customizableFields"
                              className="hoverOption"
                            >
                              <span
                                className={
                                  initialRenderDropdown ===
                                  "Customizable fields"
                                    ? "child-active"
                                    : ""
                                }
                              >
                                {" "}
                                Customizable fields
                              </span>
                            </Link>
                          </li>
                          <li
                            onClick={() => handleDropdownSideColor("Tooltips")}
                          >
                            <Link to="/Tooltip" className="hoverOption">
                              <span
                                className={
                                  initialRenderDropdown === "Tooltips"
                                    ? "child-active"
                                    : ""
                                }
                              >
                                Tooltips
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </>
                    </div>
                  </Link>
                </li>
              )}

              <li
                style={{ marginLeft: "-2px" }}
                className={initialRender === "Activity" ? "active" : ""}
                onClick={() => handleSideColor("Activity")}
              >
                <Link to="/AuthenticationLog" className="parentElement">
                  {initialRender === "Activity" ? (
                    <img
                      style={{ height: "20px", width: "20px" }}
                      src={acitivityWhite}
                    />
                  ) : (
                    <img
                      style={{ height: "20px", width: "20px" }}
                      src={activityBlack}
                    />
                  )}

                  <span
                    className="hide-menu"
                    style={{
                      display: drawerOpener ? "inline-flex" : "none",
                      marginLeft: "20px",
                    }}
                  >
                    Activity
                  </span>
                  <div className="dropdownElements">
                    <div
                      style={{
                        width: "190px",
                        height: "50px",
                        backgroundColor: "#0099f9",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "5px",
                      }}
                    >
                      <span>Activity</span>
                    </div>
                    <>
                      <ul className="child-list-item">
                        <li
                          onClick={() =>
                            handleDropdownSideColor("Authentication Logs")
                          }
                        >
                          <Link to="/AuthenticationLog" className="hoverOption">
                            <span
                              className={
                                initialRenderDropdown === "Authentication Logs"
                                  ? "child-active"
                                  : ""
                              }
                            >
                              ATS Login Logs
                            </span>
                          </Link>
                        </li>
                        <li
                          onClick={() =>
                            handleDropdownSideColor("User Activity")
                          }
                        >
                          <Link className="hoverOption" to="/UserActivity">
                            <span
                              className={
                                initialRenderDropdown === "User Activity"
                                  ? "child-active"
                                  : ""
                              }
                            >
                              {" "}
                              User Activity
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </>
                  </div>
                </Link>
                <ul aria-expanded="false" className="collapse"></ul>
              </li>
            </ul>
          </nav>
        </div>
        <div className="sidebar-footer">
          <a
            href="#"
            className="link"
            data-toggle="tooltip"
            title=""
            data-original-title="Settings"
          >
            <i className="ti-settings"></i>
          </a>
          <a
            href="#"
            className="link"
            data-toggle="tooltip"
            title=""
            data-original-title="Email"
          >
            <i className="mdi mdi-gmail"></i>
          </a>
          <a
            href="#"
            className="link"
            data-toggle="tooltip"
            title=""
            data-original-title="Logout"
          >
            <i className="mdi mdi-power"></i>
          </a>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
