import axios from "axios";
import * as serverContstant from "../constant/serverConstant";
import { AxiosInstance } from "../constant/httpsHandler";
import AppConstants from "../constant";

export const getChsCustomization = async (userName, module) => {
  return await AxiosInstance.get(
    `${AppConstants.serverConstants.API_ENDPOINTS.GET_CUSTOMIZATION}?module=${module}&userName=${userName}&source=chs`
  );
};

export const postChsCustomization = async (userName, module, gridData) => {
  const body = {
    customization: gridData,
    userName: userName,
    module: module,
  };
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.POST_CUSTOMIZATION}?source=chs`,
    body
  );
};
