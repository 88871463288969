import { BrowserRouter, Routes, Route } from "react-router-dom";

import Box from "@mui/material/Box";

import DataGridList from "../DataGridList";
import TabsList from "../Tabs/TabsList";

import AtsConfiguration from "../Admin/ATSConfiguration/AtsConfiguration";
import HtmlControlMappingDataGrid from "../Admin/HtmlControlMapping/HtmlControlMappingDataGrid";
import CustomizableFields from "../Admin/CustomizableFields/CustomizableFields";
import AccessControl from "../Admin/Acces Control/AccessControl";
import AuthenticationLog from "../Component/Activity/AuthenticationLog";
import UserActivity from "../Component/Activity/UserActivity";
import Tooltip from "../Admin/Tooltip/Tooltip";
import ClientPortalList from "../Admin/ClientPortalList/ClientPortalList";
import ElementConfig from "../Admin/ClientPortalList/ElementConfig";
import ProtectedRoute from "./PrivateRoute";
import NoAccess from "./NoAccess";
import NotFound from "./NotFound";
export default function Drawer({ drawerOpener }) {
  return (
    <>
      <Routes>
        <Route path="/" element={<TabsList drawerOpener={drawerOpener} />} />
        {/* <Route path='../DataGridList' element={<DataGridList />} /> */}
        <Route
          path="/DataGridList"
          element={<DataGridList drawerOpener={drawerOpener} />}
        />

        <Route
          path="/ClientPortalList"
          element={
            <ProtectedRoute>
              <ClientPortalList drawerOpener={drawerOpener} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AtsConfiguration"
          element={
            <ProtectedRoute>
              <AtsConfiguration drawerOpener={drawerOpener} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/HtmlControlMapping"
          element={
            <ProtectedRoute>
              <HtmlControlMappingDataGrid drawerOpener={drawerOpener} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CustomizableFields"
          element={
            <ProtectedRoute>
              <CustomizableFields drawerOpener={drawerOpener} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AccessControl"
          element={
            <ProtectedRoute>
              <AccessControl drawerOpener={drawerOpener} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AuthenticationLog"
          element={<AuthenticationLog drawerOpener={drawerOpener} />}
        />
        <Route
          path="/UserActivity"
          element={<UserActivity drawerOpener={drawerOpener} />}
        />
        <Route
          path="/Tooltip"
          element={
            <ProtectedRoute>
              <Tooltip drawerOpener={drawerOpener} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Admin/ElementConfiguration"
          element={
            <ProtectedRoute>
              <ElementConfig drawerOpener={drawerOpener} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/NoAccess"
          element={<NoAccess drawerOpener={drawerOpener} />}
        />
        <Route path="*" element={<NotFound drawerOpener={drawerOpener} />} />
      </Routes>
    </>
  );
}
