import React, {
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import {
  SIGNIN_DEV,
  SIGNIN_DEV_RESPONSE_CODE,
  CLIENT_ID,
  REDIRECT_URI,
  APP_DOMAIN,
} from "../constant/serverConstant";
import {
  GetToken,
  GetNewRefreshTokenCupPost,
  GetNewRefreshTokenCup,
  GetNewAccessToken,
} from "../api/Token";
import { getUserDetails } from "../api/UserDetails";
import { AxiosInstance, AxiosInstanceCDS } from "../constant/httpsHandler";
import { getUserRole } from "../api/UserRole";

function updateAxiosConfigHeader(instance, accessToken) {
  instance.defaults.headers.Authorization = `Bearer ${accessToken}`;
}

function getAccessTokenFromCookies() {
  const cookies = document.cookie.split(";");
  const index = cookies.findIndex((cookie) => cookie.includes("_at"));
  if (index != -1) {
    const strCount = "_at=".length + 1;
    const value = cookies[index].slice(strCount);
    return value;
  } else {
    return "token-cookie-not-found";
  }
}

function _getAuthCookies(name) {
  const cookies = document.cookie.split(";");
  const index = cookies.findIndex((cookie) => cookie.includes(name));
  if (index != -1) {
    let strCount = 0;
    if (name === "_at") {
      strCount = name.length + 2;


    } else if (name === "unicode") {
      strCount = name.length + 1;
    }

    const value = cookies[index].split("=")[1];
    return value;
  } else {
    if (name === "_at") {
      return "token-cookie-not-found";
    } else if (name === "unicode") {
      return "unicode-cookie-not-found";
    }
  }
}

function removeCookie(name, value, domain, path) {
  document.cookie = `${name}=${value}; domain=${domain}; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
}

/*
 * check cookie status ----------------------------------------------------------------Start
 */
let checkCookieTimer = "";
let checkCookie = (function () {
  return function () {
    const cookieTokenArr = document.cookie.split(";");

    //console.log(cookieTokenArr, 'cookieTokenArr');
    const channelCookiePromise = new Promise((resolve, reject) => {
      let check_at = "null";
      let counter = 0;
      for (let i = 0; i < cookieTokenArr.length; i++) {
        if (cookieTokenArr[i].includes("_at")) {
          check_at = cookieTokenArr[i];
          // console.log(cookieTokenArr[i], 'cookieTokenArr');
        }
        counter++;
      }

      // console.log('fldkhldhfl', counter, cookieTokenArr.length);
      if (counter === cookieTokenArr.length) {
        if (check_at !== "null") {
          resolve({ _at: "token_exists" });
        } else {
          reject({ _at: "token_removed" });
        }
      }
    });

    channelCookiePromise
      .then((res) => {
        // console.log(res, 'channelCookieArray_res')
      })
      .catch((err) => {
        // console.log(err, 'channelCookieArray_err');
        if (err["_at"] === "token_removed") {
          // document.cookie = `name=unicode; expires=${new Date().toGMTString()}`;
          removeCookie("unicode", "", APP_DOMAIN, "/");
          redirectToSigninDevLogout();
        }
      });
    // console.log(document.cookie, 'document.cookie');

    // if (currentCookie != lastCookie) {

    //     // something useful like parse cookie, run a callback fn, etc.

    //     lastCookie = currentCookie; // store latest cookie

    // }
  };
})();

/*
 * check cookie status ----------------------------------------------------------------End
 */

function redirectToSigninDev() {
  window.location.href = `${SIGNIN_DEV}/login?response_type=${SIGNIN_DEV_RESPONSE_CODE}&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}/`;
}

function redirectToSigninDevLogout() {
  window.location.href = `${SIGNIN_DEV}/logout?response_type=${SIGNIN_DEV_RESPONSE_CODE}&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}/`;
}

const AuthContext = createContext({ accessToken: "" });

export function AuthProvider({ children }) {
  const [accessToken, setAccessToken] = useState("null");
  const [userInfo, setUserInfo] = useState({});
  const [userRole, setUserRole] = useState("");
  const refreshTokenRef = useRef("null");
  const tokenExpirationTimeRef = useRef(0);
  const tokenApiTypeRef = useRef("");
  const unicodeRef = useRef("null");

  useEffect(() => {
    initialPageLoad();
    // 05-02-2024
    checkExpiryPeriodically();
    // ------------
  }, []);

  useEffect(() => {
    //AxiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    updateAxiosConfigHeader(AxiosInstanceCDS, accessToken);
    updateAxiosConfigHeader(AxiosInstance, accessToken);
  }, [accessToken]);

  useEffect(() => {
    checkAccessTokenExpiry(accessToken);
    userDetails();
  }, [accessToken]);

  useEffect(() => {
    if (accessToken !== "null" && userInfo) {
      getUserRoleByName(userInfo.userName);
    }
  }, [accessToken, userInfo]);


  function checkExpiryPeriodically() {
    setInterval(() => {
      checkAccessTokenExpiry(_getAuthCookies("_at"));
    }, 3000);
  }


  async function getUserRoleByName(userName) {
    if (accessToken && userName) {
      try {
        const res = await getUserRole(userName);
        setUserRole(res.data.data.Response);
      } catch (error) {

      }
    }
  }

  useEffect(() => {
    setAuthCookie(accessToken, unicodeRef.current);
  }, [accessToken, unicodeRef.current]);

  async function userDetails() {
    if (accessToken !== "null") {
      try {
        const res = await getUserDetails(accessToken);
        let userName = res.data.email.substring(0, res.data.email.indexOf("@"));
        setUserInfo({
          ...res.data,
          userName,
          //userName: 'anil.choudhary', email: 'anil.choudhary@hitechpeopleinc.com'
        });
      } catch (error) {
    
      }
    }
  }
  function initialPageLoad() {
    const url = new URLSearchParams(window.location.search);
    if (url.has("code")) {
      unicodeRef.current = url.get("code");
      removeUnicodeFromUrl();

      getTokenHandler(unicodeRef.current);
    } else if (_getAuthCookies("_at") === "token-cookie-not-found") {
      redirectToSigninDev();
    } else {
      unicodeRef.current = _getAuthCookies("unicode");
      setTokenInState(_getAuthCookies("_at"));
    }

    // const token = getAccessTokenFromCookies();
    // const token = _getAuthCookies('_at');

    // if (unicodeRef.current !== "unicode-cookie-not-found") {
    //     getNewAccessToken()

    // }
    // else if (token === 'token-cookie-not-found') {
    //     redirectToSigninDev(token);
    // }

    // setTokenInState(token);
  }

  async function getTokenHandler(code) {
    try {
      const response = await GetToken(code);

      if (response) {
        if (response.status === 200) {
          if (response.data) {
            const { access_token, refresh_token, expires_in } = response.data;
            setTokenInState(access_token);
            refreshTokenRef.current = refresh_token;
            postUnicodeRefreshToken(refresh_token);
          }
        }
      }

   
    } catch (error) {
  
    }
  }

  function setTokenInState(token) {
    if (token !== "null" && token !== "token-cookie-not-found") {
      updateAxiosConfigHeader(AxiosInstanceCDS, token);
      updateAxiosConfigHeader(AxiosInstance, token);
      setAccessToken(token);
    }
  }

  function checkAccessTokenExpiry(accessToken) {
    if (accessToken !== "null") {
      const expiration = accessToken.split(".")[1];
      const expirationTime = JSON.parse(window.atob(expiration)).exp;
      const expirationTimeInMiliSeconds = expirationTime * 1000;
      const currentTimeInMiliSeconds = new Date().getTime();

      let timeLeftInMiliSeconds =
        expirationTimeInMiliSeconds - currentTimeInMiliSeconds;
      if (timeLeftInMiliSeconds < 0) {
        timeLeftInMiliSeconds = 0;
      }

      tokenExpirationTimeRef.current = timeLeftInMiliSeconds;
      triggerOnTokenExpiry();
    }
  }

  function triggerOnTokenExpiry() {
    const time = tokenExpirationTimeRef.current;
    setTimeout(() => {
      getNewAccessToken();
    }, time);
  }

  async function getNewAccessToken() {
    handleCupApis();
    // setTokenInState(token);
  }

  async function handleCupApis() {
    try {
      const responseGet = await GetNewRefreshTokenCup(unicodeRef.current);

      if (responseGet.status === 200) {
        if (responseGet.data.length > 0) {
          if (responseGet.data[0]["_source"]) {
            if (responseGet.data[0]["_source"].token) {
              try {
                const response = await GetNewAccessToken(
                  responseGet.data[0]["_source"].token
                );

                if (response) {
                  if (response.status === 200) {
                    if (response.data) {
                      const { access_token } = response.data;
                      setTokenInState(access_token);
                    }
                  }
                }
              } catch (error) {
                
              }
            }
          }
        }
      }
    } catch (error) {
    
    }
  }

  async function postUnicodeRefreshToken() {
    try {
      const responsePost = await GetNewRefreshTokenCupPost(
        unicodeRef.current,
        refreshTokenRef.current
      );
      if (responsePost.status === 200) {
        if (responsePost.data === "success") {
        }
      }
    } catch (error) {
   
    }
  }

  function setCookie(name, value, domain, path) {
    document.cookie = `${name}=${value}; domain=${domain}; path=${path}`;
  }

  function removeAllCookies() {
    document.cookie.split(";").forEach(function (c) {
      document.cookie =
        c.trim().split("=")[0] +
        "=;" +
        "expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    });
  }

  function setAuthCookie(token, unicode) {
    // removeAllCookies();
    window.clearInterval(checkCookieTimer);
    if (token !== "null" && unicode != "null") {
      setCookie("_at", token, APP_DOMAIN, "/");
      setCookie("unicode", unicode, APP_DOMAIN, "/");

      checkCookieTimer = window.setInterval(checkCookie, 2000); // run every 100 ms
    }
  }

  function removeUnicodeFromUrl() {
    window.history.pushState(
      window.history.state,
      document.title,
      window.location.origin
    );
  }



  const AuthContextProps = {
    accessToken,
    userInfo,
    userRole
  };



  return (
    <AuthContext.Provider value={AuthContextProps}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
