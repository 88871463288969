export const API_PATH = process.env.REACT_APP_CHS_PATH;
export const SIGNIN_DEV = process.env.REACT_APP_AWS_SIGNIN_DEV;
export const REFRESH_TOKEN_CUP = process.env.REACT_APP_REFRESH_TOKEN_CUP;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const REFRESH_TOKEN_API_KEY = process.env.REACT_APP_REFRESH_TOKEN_KEY;
export const SIGNIN_DEV_RESPONSE_CODE =
  process.env.REACT_APP_SIGNIN_DEV_RESPONSE_CODE;
export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
export const API_CDS = process.env.REACT_APP_CDS_PATH;
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
export const CRM_REDIRECT_URL = process.env.REACT_APP_REDIRECT_URI_CRM;

export const API_ENDPOINTS = {
  LOGO: "logo",
  GROUP_BY: "searchingChGroups",
  CATEGORY_BY: "gettingCategories",
  SIGNIN_DEV: "oauth2/token/",
  ACOUNT_NAME: "dynamicAll",
  ADDGROUP_BY: "insertedChGroups",
  ADD_MANIFEST: "createdManifestIdentity",
  GET_MANIFEST: "findsManifestInformations",
  GET_PERMISSION: "foundPermissionsData",
  ADD_PERMISSION: "savedPermission",
  ADD_CLIENT: "addedNewClients",
  ADD_CREATECATEGORIES: "createCategories",
  GET_ADMIN_CLIENT_DATA: "getAdminAtsClient",
  // GET_AUDIT_LOGS_DETAILS: "getAuditLogsDetails",
  GET_AUDIT_LOGS_DETAILS: "getDynamicAudit",
  FIND_CLIENT_ACCESS_DATA: "findClientsAccessData",
  GET_USER_CLIENT_DATA: "getUserClientData",
  AUTH_USER_LOGS: "getDynamicAuthenticationLogs",
  POSTED_CLIENT_ACCESS: "postedClientsAccess",
  DELETE_DATA_CANDIDATE: "canceledClient",
  GET_USER_DETAILS: "/oauth2",
  GET_USER_ACTIVITY: "getDynamicUserActivityLogs",
  GET_ELEMENT_CONFIGURATION: "getDynamicElementConfig",
  RECEIVE_TOOLTIP_DATA: "receiveToolTipData",
  POST_ELEMENT_DATA: "insertedElementConfiguration",
  UPDATE_CLIENT_DATA: "updateClient",
  UPDATE_ELEMENT_CONFIG_DATA: "updateElementConfigData",
  GET_CLIENT: "showDashBoardClients",
  GET_CLIENT_PORTAL_LIST: "myclienttDynamicwithout",
  GET_DYNAMIC_USER_ACTIVITY: "getDynamicUserActivityLogs",
  GET_ATS_AUTOLOGIN_TAG: "getATSLoginTags",
  GET_ADMIN_CLIENT_NAME: "getAccessControlClients",
  GET_ADMIN_USER_DETAILS: "getAccessControlUsers",
  UPDATE_ATS_TAG: "updateAtsTag",
  GET_CLIENT_NAME: "getDynamicAllClients",
  GET_ACTIVE_USER: "getDynamicAllActiveUsers",
  POST_ACTIVE_USER: "postedClientsAccess",
  GET_ACTIVE_USER_BY_ID: "getAccessUsers",
  INSERT_ATS_TAGS: "insertAtsTags",
  GET_CLIENT_BY_USERNAME: "getDynamicAccessClients",
  GET_ACCESS_CONTROL_AUDIT: "getAuditWithoutId",
  GET_CUSTOMIZATION: "getChsCustomization",
  POST_CUSTOMIZATION: "clientPortalCustomization",
  GET_TOOLTIP: "getToolTips",
  GET_FUNCTION_NAME: "getTables",
  GET_FIELD_NAME: "getTableColumns",
  CREATE_TOOLTIP: "createToolTips",
  UPDATE_TOOLTIP: "updateToolTip",
  USER_ROLE: "getuserdatatest",
  USER_HEADER_DETAILS: "userdailyheader",
  GET_TOOLTIP_VALUE: "getToolTips",
};
