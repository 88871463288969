import DataGrid, {
  Column,
  FilterRow,
  Paging,
  // Scrolling,
  RowDragging,
  Pager,
  ColumnChooser,
  Sorting,
  Lookup,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Editing,
  Scrolling,
  StateStoring,
  RemoteOperations,
  Selection,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { getAuditLogsDetails } from "../../api/ToolTipApi/TooltipAPI";
import { useAuthContext } from "../../Context/AuthProvider";
import { useCallback, useEffect, useRef, useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import { ToolTipAudit } from "../../api/Audit Logs/AuditLogs";

import { Button as Button2 } from "devextreme-react/button";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import clean from "../../assets/clean.png";
import ClearFiltericon from "../../assets/ClearFiltericon.png";
import {
  getChsCustomization,
  postChsCustomization,
} from "../../api/Customization";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
const allowedPageSizes = [50, 100, 250];
const customStore = (cellData, initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const take = options.take || 50;
      const skip = options.skip;
      const sort = options.sort || [
        {
          selector: "lastUpdatedDate",
          desc: true,
        },
      ];
      let res;
      try {
        res = await ToolTipAudit(filter, skip, take, sort);
        let dataRes = [];
        let totalCount = 0;
        // Check if res.data is not null before accessing its properties
        if (res.data && res.data.data) {
          if (res.data.data.response && res.data.data.response.length) {
            totalCount = res.data.data.count;
            const dataRes = res.data.data.response.map((item, index) => {
              const dateString = item.lastUpdatedDate;
              const inputDateCreated = new Date(dateString);

              // Format the date in the desired format without any time zone adjustments
              const formattedCreated = `${inputDateCreated
                .getUTCDate()
                .toString()
                .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                  month: "short",
                }).format(
                  inputDateCreated
                )}-${inputDateCreated.getUTCFullYear()} ${inputDateCreated
                  .getUTCHours()
                  .toString()
                  .padStart(2, "0")}:${inputDateCreated
                    .getUTCMinutes()
                    .toString()
                    .padStart(2, "0")}:${inputDateCreated
                      .getUTCSeconds()
                      .toString()
                      .padStart(2, "0")}`;

              return {
                id: index + 1,
                changedBy: item.changedBy,
                fieldName: item.fieldName,
                lastUpdatedDate: formattedCreated,
                newValue: item.newValue,
                oldValue: item.oldValue,
              };
            });

            return {
              data: dataRes,
              totalCount,
            };
          }
        }
      } catch (error) {
        // Handle other errors here
        // setStatus({
        //     display: true,
        //     text: `${error.code} , Please contact the administrator`,
        //     statusType: "warning",
        // })
      }

      // If the response is null or doesn't contain the expected properties
      return {
        data: [
          {
            id: null,
            data: "no data",
          },
        ],
        totalCount: 0,
      };
    },
  });

function TooltipAuditLogs({ handleClose, open }) {
  const [AuditLogsData, setAuditLogsData] = useState([]);
  const [clearCustomizationPopUp, setClearCustomizationPopUp] = useState(false);
  const { userInfo } = useAuthContext();
  const elementConfigRef = useRef(null);
  const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";

  useEffect(() => {
    setAuditLogsData(customStore());
  }, []);

  function refresh() {
    if (elementConfigRef.current) {
      elementConfigRef.current.instance.refresh();
    }
  }
  const clearFilters = (e) => {
    if (elementConfigRef.current) {
      elementConfigRef.current.instance.clearFilter();
    }
  };

  const customChanges = {
    icon: "undo",
    text: "Clear",
    type: "default",
    // onClick: () => setResetCall('active'),
  };
  const cancelCustomchanges = {
    text: "Close",
    icon: "remove",
    type: "danger",
    onClick: () => {
      setClearCustomizationPopUp(false);
    },
  };

  const saveState = useCallback(
    async (state) => {
      if (userInfo.userName) {
        const res = await postChsCustomization(
          userInfo.userName,
          "AccessControlClientList",
          state
        );
      }
    },
    [userInfo]
  );

  const loadState = useCallback(async () => {
    if (userInfo.userName) {
      const res = await getChsCustomization(
        userInfo.userName,
        "AccessControlClientList"
      );
      return res.data.data;
    }
  }, [userInfo]);

  return (
    <>
      <Popup
        visible={clearCustomizationPopUp}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        container="body"
        width={500}
        height={200}
        title="Alert"
      >
        <Position my="center" of={"main_dataGridClass"} collision="fit" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={customChanges}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelCustomchanges}
        />
        <p>Your custom changes made by you on grid will be removed..</p>
      </Popup>
      <div
        className="audit-div"
        style={
          {
            // height: "100%",
            // // width: "100%",
            // marginTop: "1%",
            // width: "80px"
          }
        }
      >
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="dialog-box"
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "80%", // Set your desired width here
            },
          }}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, background: "#3aa1f0", color: "white" }}
            id="customized-dialog-title"
            className="dialog-text"
          >
            Audit Logs
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <Box
            className="main-div"
            style={{
              height: "80vh",
              width: "100%",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "20px",
            }}
          >
            <DataGrid
              dataSource={AuditLogsData}
              keyExpr="id"
              key="index"
              height="90%"
              width="100%"
              showRowLines={true}
              showColumnLines={true}
              hoverStateEnabled
              showBorders={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              columnMinWidth={50}
              loadPanel={{ enabled: true }}
              remoteOperations={true}
              columnResizingMode="widget"
              ref={elementConfigRef}
            >
              <Scrolling mode="standard" />
              <ColumnFixing enabled={true} />
              {/* <LoadPanel visible={loaderCircular} /> */}
              <FilterRow visible={true} />
              <Scrolling columnRenderingMode="virtual" />
              <ColumnFixing enabled={true} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
                showInfo={true}
                visible={true}
              />
              <Paging defaultPageSize={50} enabled={true} />
              <FilterRow visible={true} />
              <Column
                allowSorting={false}
                width="50px"
                allowFiltering={false}
                alignment="left"
                caption="S.No"
                dataField="id"
                allowReordering={false}
              />
              <Column
                allowReordering={true}
                width="200px"
                caption="Field Name"
                dataField="fieldName"
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column
                allowReordering={true}
                width="200px"
                caption="Old Value"
                dataField="oldValue"
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column width="210px" caption="New Value" dataField="newValue" />
              <Column
                allowReordering={true}
                width="200px"
                caption="Changed By"
                dataField="changedBy"
                // cellRender={setStatus}
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column
                allowReordering={true}
                width="200px"
                caption="Last Updated Date "
                dataField="lastUpdatedDate"
                // cellRender={setStatus}
                editorOptions={{
                  showClearButton: true,
                }}
                dataType="date"
                format={filterDateFormat}
              />
            </DataGrid>
          </Box>
        </BootstrapDialog>
      </div>
    </>
  );
}
export default TooltipAuditLogs;

function DevExtremToolbarClearfilter({ clearFilters, elementConfigRef }) {
  return (
    <>
      <img
        title="Clear Filter"
        className="clean"
        src={ClearFiltericon}
        onClick={() => {
          const isFilterApplied =
            elementConfigRef.current.instance.getCombinedFilter();
          if (isFilterApplied != undefined) {
            clearFilters();
          }
        }}
      ></img>
    </>
  );
}
