import Box from "@mui/material/Box";
import { useEffect, useRef, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DataGrid, {
  Column,
  FilterRow,
  Paging,
  Pager,
  ColumnChooser,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import CustomStore from "devextreme/data/custom_store";
import { getAccessControlAuditLogs } from "../../api/clients";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const allowedPageSizes = [50, 100, 250];

const customStore = (initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const take = options.take || 50;
      const skip = options.skip;
      const sort = options.sort || [
        {
          selector: "lastUpdatedDate",
          desc: true,
        },
      ]
          try {
        const res = await getAccessControlAuditLogs(filter, take, skip, sort);
        let dataRes = [];
        if (res.data.data) {
          dataRes = res.data.data.response.map((item, index) => {
            // console.log(item, 'llllllllkkkkkkkkkkkkkk');
            if (item.fieldName === 'isAccessGranted') {
              if (item.newValue == 1) {
                item.newValue = 'Yes'
              }else if (item.newValue == 0) {
                item.newValue = 'No'
              }
               if (item.oldValue == 1) {
                item.oldValue = 'Yes'
              }else if (item.oldValue == 0) {
                item.oldValue = 'No'
              }
            }
            const inputDateString = item.lastUpdatedDate;
            // Parse the input date string
            const inputDate = new Date(inputDateString);
            const formattedDate = `${inputDate
              .getUTCDate()
              .toString()
              .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
              month: "short",
            }).format(inputDate)}-${inputDate.getUTCFullYear()} ${inputDate
              .getUTCHours()
              .toString()
              .padStart(2, "0")}:${inputDate
              .getUTCMinutes()
              .toString()
              .padStart(2, "0")}:${inputDate
              .getUTCSeconds()
              .toString()
              .padStart(2, "0")}`;
            return {
              loadMode: "raw",
              key: "id",
              id: index + 1,
              newValue: item.newValue,
              oldValue: item.oldValue,
              changedBy: item.changedBy,
              fieldName: item.fieldName,
              lastUpdatedDate: formattedDate,
            };
          });
        } else {
          return {
            data: [],
            totalCount: 0,
          };
        }
        return {
          data: dataRes,
          totalCount: res.data.data.count,
        };
      } catch (error) {
            }
    },
  });

function AuditLogs({ auditLogs, setAuditLogs }) {
  const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";

  const auditLogsRef = useRef("");
  useEffect(() => {
    auditLogsRef.current = customStore();
  }, [auditLogsRef]);

  return (
    <>
      <div className="audit-div">
        <BootstrapDialog
          onClose={() => setAuditLogs(false)}
          aria-labelledby="customized-dialog-title"
          open={auditLogs}
          className="dialog-box"
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "80%", // Set your desired width here
            },
          }}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, background: "#008ce3", color: "white" }}
            id="customized-dialog-title"
            className="dialog-text"
          >
            Audit Logs
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setAuditLogs(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <Box
            className="main-div"
            style={{
              height: "80vh",
              width: "100%",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "20px",
            }}
          >
            <DataGrid
              dataSource={auditLogsRef.current}
              keyExpr="id"
              key="index"
              height="95%"
              width="100%"
              showRowLines={true}
              showColumnLines={true}
              hoverStateEnabled
              showBorders={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              columnMinWidth={50}
              remoteOperations={true}
              columnResizingMode="widget"
              loadPanel={{ enabled: true }}
              allowSorting={true}
            >
              <StateStoring
                enabled={true}
                type="custom"
                // customLoad={loadState}
                // customSave={saveState}
              />
              <Scrolling mode="standard" />
              {/* <Toolbar>
                <Item name="columnChooserButton" />
                <Item name="exportButton" />
              </Toolbar> */}
              {/* <ColumnChooser enabled={true} />
              <Export enabled={true} allowExportSelectedData={false} /> */}
              <ColumnFixing enabled={true} />
              <FilterRow visible={true} />
              <Scrolling columnRenderingMode="virtual" />
              {/* <ColumnChooser enabled={true} />
              <Export enabled={true} allowExportSelectedData={false} /> */}
              <ColumnFixing enabled={true} />
              <Pager
                showPageSizeSelector={true}
                  allowedPageSizes={allowedPageSizes}
                showInfo={true}
                visible={true}
              />
              <Paging defaultPageSize={50} enabled={true} />
              <FilterRow visible={true} />
              <Column
                allowSorting={false}
                width="50px"
                allowFiltering={false}
                alignment="left"
                caption="S.No"
                dataField="id"
                allowReordering={false}
              />
              <Column
                allowReordering={true}
                width="200px"
                caption="Field Name"
                dataField="fieldName"
                cellRender={(cellData) => (
                  <span>{cellData.data.fieldName.charAt(0).toUpperCase() + cellData.data.fieldName.slice(1).toLowerCase()}</span>
                )}
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column
                width="200px"
                caption="Old Value"
                dataField="oldValue"
               
              />
              <Column width="210px" caption="New Value" dataField="newValue" />
              <Column
                allowReordering={true}
                width="200px"
                caption="Changed By"
                dataField="changedBy"
                // cellRender={setStatus}
                editorOptions={{
                  showClearButton: true,
                }}
              />
              <Column
                allowReordering={true}
                width="200px"
                caption="Last Updated Date"
                dataField="lastUpdatedDate"
                dataType="date"
                format={filterDateFormat}

                // cellRender={setStatus}
                editorOptions={{
                  showClearButton: true,
                }}
              />
            </DataGrid>
          </Box>
        </BootstrapDialog>
      </div>
    </>
  );
}
export default AuditLogs;
