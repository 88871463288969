import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
// import "../ClientPortalList.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
// import "./AuthenticationLog.css";
import { Tabs, Tab, Paper } from "@mui/material";
import { Button as Button2 } from "devextreme-react/button";
import DataGrid from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import {
  Selection,
  // Box,
  Column,
  FilterRow,
  Paging,
  // Scrolling,
  RowDragging,
  Pager,
  ColumnChooser,
  Sorting,
  Lookup,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Editing,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { Modal } from "@mui/base";
import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { get_user_activityLogs } from "../../api/Activity";
import { useAuthContext } from "../../Context/AuthProvider";
import {
  getChsCustomization,
  postChsCustomization,
} from "../../api/Customization";
import clean from "../../assets/clean.png";
import ClearFiltericon from "../../assets/ClearFiltericon.png";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";

import CustomStore from "devextreme/data/custom_store";
import Alerts from "../../Alert/Alert";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
const customStore = (userInfo, setStatus, userRole, initialFilter = null) =>
  new CustomStore({
    key: "id",
    load: async function (options) {
      const filter = options.filter || initialFilter;
      const take = options.take || 50;
      const skip = options.skip;
      const sort = options.sort || [
        {
          selector: "updatedAt",
          desc: true,
        },
      ];

      let res;
      try {
        res = await get_user_activityLogs(
          userInfo,
          filter,
          userRole,
          take,
          skip,
          sort
        );
        let dataRes = [];
        if (!res.data.data || res.data.data.response.length === 0) {
          return {
            data: [],
            totalCount: 0,
          };
        }
        if (res.data.data) {
          if (res.data.data.response) {
            dataRes = res.data.data.response.map((item, index) => {
              let formattedDateUpdat = "";
              if (item.eventTimeStamp) {
                const dateStringUpdated = item.eventTimeStamp;
                const inputDate = new Date(dateStringUpdated);
                formattedDateUpdat = `${inputDate
                  .getUTCDate()
                  .toString()
                  .padStart(2, "0")}-${new Intl.DateTimeFormat("en-US", {
                  month: "short",
                }).format(inputDate)}-${inputDate.getUTCFullYear()} ${inputDate
                  .getUTCHours()
                  .toString()
                  .padStart(2, "0")}:${inputDate
                  .getUTCMinutes()
                  .toString()
                  .padStart(2, "0")}:${inputDate
                  .getUTCSeconds()
                  .toString()
                  .padStart(2, "0")}`;
              } else {
                formattedDateUpdat = "";
              }

              const startIndex = (options.skip || 0) + 1;
              return {
                id: index + startIndex,
                LogId: item.logId,
                clientUserId: item.clientUserId,
                username: item.username,
                eventType: item.eventType,
                formattedDateUpdat,
                eventTimeStamp: formattedDateUpdat,
                eventDescription: item.eventDescription,
                EventData: "",
                ipAddress: item.ipAddress,
                clientName: item.clientName,
                UserAgent: "",
              };
            });
          }
        }
        return {
          data: dataRes,
          totalCount: res.data.data.count,
        };
      } catch (error) {
        setStatus({
          display: true,
          text: `Something went wrong`,
          statusType: "warning",
        });
        return {
          data: [],
          totalCount: 0,
        };
      }
    },
  });
const onExporting = (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Main sheet");
  // Obtain selected rows data
  const selectedRows = e.component.getSelectedRowsData();
  // Export only selected rows
  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
    customizeExportData: (columns, rows) => {
      return {
        columns,
        rows: selectedRows,
      };
    },
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "UserActivity.xlsx"
      );
    });
  });
};
function UserActivity({ drawerOpener }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [open, setOpen] = useState(false);
  const dataGridRef = useRef(null);

  const [status, setStatus] = useState({
    display: false,
    text: "",
    statusType: "",
  });
  const [clientListData, setClientListData] = useState([]);
  const [clearCustomizationPopUp, setClearCustomizationPopUp] = useState(false);
  const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";
  const { accessToken, userInfo, userRole } = useAuthContext();

  const allowedPageSizes = [50, 100, 250];

  useEffect(() => {
    if (userRole) {
      setClientListData(customStore(userInfo, setStatus, userRole));
    }
  }, [selectedTab, userInfo, userRole]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCellPrepared = (e) => {
    if (e.cellElement.ariaLabel === "Column Log Id") {
      e.cellElement.style.backgroundColor = "#008ce3";
    }
  };
  const saveState = useCallback(
    async (state) => {
      if (userInfo.userName) {
        const res = await postChsCustomization(
          userInfo.userName,
          "UserActivity",
          state
        );
      }
    },
    [userInfo]
  );

  const loadState = useCallback(async () => {
    if (userInfo.userName) {
      const res = await getChsCustomization(userInfo.userName, "UserActivity");
      return res.data.data;
    }
  }, [userInfo, userRole]);

  const clearFilters = (e) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.clearFilter();
    }
  };

  const refresh = () => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh();
    }
  };

  const customChanges = {
    icon: "undo",
    text: "Clear",
    type: "default",
    onClick: () => clearCustomizationDataGrid(),
  };
  const cancelCustomchanges = {
    text: "Close",
    icon: "remove",
    type: "danger",
    onClick: () => {
      setClearCustomizationPopUp(false);
    },
  };

  const clearCustomizationDataGrid = () => {
    const dataGridInstance = dataGridRef.current.instance;
    const columns = dataGridInstance.getVisibleColumns();
    columns.forEach((column) => {
      column.visible = true;
      column.showInColumnChooser = true;
    });
    dataGridInstance.option("columns", columns);
    dataGridInstance.clearFilter();
    dataGridInstance.clearSelection();
    setClearCustomizationPopUp(false);
  };

  return (
    <>
      {status.display && <Alerts status={status} sx={{ margin: "10px" }} />}

      <Popup
        visible={clearCustomizationPopUp}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        container="body"
        width={500}
        height={200}
        title="Alert"
      >
        <Position my="center" of={"main_dataGridClass"} collision="fit" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={customChanges}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelCustomchanges}
        />
        <p>Your custom changes made by you on grid will be removed..</p>
      </Popup>
      <Box className={drawerOpener ? "add-tabs" : "close-tabs"}>
        <Paper square>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered={false}
          >
            <Tab
              sx={{
                fontSize: "small",
                fontWeight: "700",
                marginLeft: drawerOpener ? "245px" : "55px",
                textTransform: "none",
              }}
              label="User Activity"
            />
          </Tabs>
        </Paper>

        {selectedTab === 0 ? (
          <>
            <div
              className="main-div"
              style={{
                height: "90vh",
                // width: "100%",
                marginTop: "1%",
                marginLeft: drawerOpener ? "255px" : "65px",
                width: drawerOpener ? "86%" : "96%",
              }}
            >
              <DataGrid
                dataSource={clientListData}
                keyExpr="id"
                key="index"
                columnResizingMode="widget"
                ref={dataGridRef}
                height="90%"
                width="100%"
                showRowLines={true}
                showColumnLines={true}
                hoverStateEnabled
                showBorders={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnMinWidth={50}
                loadPanel={{ enabled: true }}
                onCellPrepared={handleCellPrepared}
                filterSyncEnabled={true}
                onExporting={onExporting}
                remoteOperations={true}
              >
                <Scrolling columnRenderingMode="virtual" />
                <Toolbar>
                  <Item location="after">
                    <Button2 hint="Refresh" icon="refresh" onClick={refresh} />
                  </Item>
                  <Item>
                    <DevExtremToolbarClearfilter
                      clearFilters={clearFilters}
                      dataGridRef={dataGridRef}
                    />
                  </Item>
                  <Item name="columnChooserButton" />
                  <Item name="exportButton" />
                  <Item>
                    <img
                      title="Clear Customization"
                      className="clean"
                      src={clean}
                      onClick={() => {
                        setClearCustomizationPopUp(true);
                      }}
                    ></img>
                  </Item>
                </Toolbar>
                <ColumnChooser enabled={true} />
                <Export enabled={true} allowExportSelectedData={false} />
                <ColumnFixing enabled={true} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={allowedPageSizes}
                  showInfo={true}
                  visible={true}
                />
                <Paging defaultPageSize={50} enabled={true} />
                <FilterRow visible={true} />
                <StateStoring
                  enabled={true}
                  type="custom"
                  customLoad={loadState}
                  customSave={saveState}
                />
                <Column
                  allowSorting={false}
                  width="50px"
                  allowFiltering={false}
                  alignment="left"
                  caption="S.No"
                  dataField="id"
                  allowReordering={false}
                />
                <Column
                  allowReordering={true}
                  width="400px"
                  caption="User ID"
                  dataField="clientUserId"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  allowReordering={true}
                  width="400px"
                  caption="User Name"
                  dataField="username"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  allowReordering={true}
                  width="250px"
                  caption="Client Name"
                  dataField="clientName"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  width="210px"
                  caption="Event Type"
                  dataField="eventType"
                />
                <Column
                  allowReordering={true}
                  width="400px"
                  caption="Event Time Stamp"
                  dataField="eventTimeStamp"
                  dataType="date"
                  format={filterDateFormat}
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  allowReordering={true}
                  width="400px"
                  caption="Event Description"
                  dataField="eventDescription"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  allowReordering={true}
                  width="300px"
                  caption="Event Data"
                  dataField="EventData"
                  visible={false}
                  // cellRender={setStatus}
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  allowReordering={true}
                  width="250px"
                  caption="IP Address"
                  dataField="ipAddress"
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
                <Column
                  allowReordering={true}
                  width="250px"
                  caption="User Agent"
                  dataField="UserAgent"
                  visible={false}
                  editorOptions={{
                    showClearButton: true,
                  }}
                />
              </DataGrid>
            </div>
          </>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
export default UserActivity;

function DevExtremToolbarClearfilter({ clearFilters, dataGridRef }) {
  return (
    <>
      <img
        title="Clear Filter"
        className="clean"
        src={ClearFiltericon}
        onClick={() => {
          const isFilterApplied =
            dataGridRef.current.instance.getCombinedFilter();
          if (isFilterApplied != undefined) {
            clearFilters();
          }
        }}
      ></img>
    </>
  );
}
