import axios from "axios";
import * as serverContstant from "../../constant/serverConstant";

export async function GetAccessControlDetails(accessToken) {
  return axios.get(
    serverContstant.API_PATH +
      "/" +
      serverContstant.API_ENDPOINTS.ACCESS_CONTROL_DETAILS +
      "?pageNo=1&pageSize=50&source=chs",
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}
export async function findClientsAccessData(accessToken) {
  return axios.get(
    serverContstant.API_PATH +
      "/" +
      serverContstant.API_ENDPOINTS.FIND_CLIENT_ACCESS_DATA +
      "?pageNo=1&pageSize=1000&source=chs",
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}
export async function getUserClientData(
  accessToken,
  userType,
  userNameData,
  postClient = ""
) {
  let body = {};
  if (userType === "Client Name") {
    body = { clientName: userNameData };
  } else {
    body = { userName: userNameData };
  }
  body = JSON.stringify(body);
  return axios.post(
    serverContstant.API_PATH +
      "/" +
      serverContstant.API_ENDPOINTS.GET_USER_CLIENT_DATA +
      "?pageNo=1&pageSize=1000&source=chs",
    body,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
}

export async function postedClientsAccess(accessToken, addDataToAccessControl) {
  return axios.post(
    serverContstant.API_PATH +
      "/" +
      serverContstant.API_ENDPOINTS.POSTED_CLIENT_ACCESS +
      "?source=ClientHub",
    addDataToAccessControl,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
}

export async function DeleteAccessControlData(accessToken, bodyPaylod) {
  // return axios.post(
  //   serverContstant.API_PATH +
  //     "/" +
  //     serverContstant.API_ENDPOINTS.POSTED_CLIENT_ACCESS +
  //     "?source=ClientHub",
  //     bodyPaylod,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //       "Content-Type": "application/json",
  //     },
  //   }
  // );
}
